import React from 'react';

import { Conversation, Message } from './style';
import { Button } from 'common/style';
import { CollapseContent } from 'common/Collapse';
import TextareaField from 'common/Field/TextAreaField';

const ClaimConversation: React.FC = () => {
    return (
        <CollapseContent>
            <Conversation>
                <Message>
                    <span className="convo-sender">[Jan 12 3:40] Danny Ellwood</span>
                    <br />
                    <span>This is a sample message. Convo # 1</span>
                </Message>
                <br />
                <Message>
                    <span className="convo-sender">[Jan 17 3:40] Danny Ellwood</span>
                    <br />
                    <span>Forwarded to approver. For Approval.</span>
                </Message>
            </Conversation>

            <div><strong>Enter a comment</strong></div>
            <TextareaField name="claimNote" label="Note" />
            <Button>Send</Button>
        </CollapseContent>
    )
}

export default ClaimConversation;