import React from 'react';

import { CollapseContent } from 'common/Collapse';
import { Attachments } from './style';

const ClaimAttachment: React.FC = () => {
    return (
        <CollapseContent>
            <Attachments>
                <div />
                <div />
                <div />
                <div />
            </Attachments>
        </CollapseContent>
    )
}

export default ClaimAttachment;