import styled from 'styled-components';
import { Table, Modal } from 'antd';

export const Tabs = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 10px 0 0;
    padding: 0;
    li {
        margin-right: 16px;
        display:inline;
        cursor: pointer;
        span {
            min-width: 120px;
            font-size: 14px;
            line-height: 16px;
            display: block;
            border: 1px solid #dcdcdc;
            color: #a0a0a0;
            padding: 9.5px 24px;
            border-radius: 4px;
            text-align: center;
            margin-bottom: 24px;
            &.active {
                border: 2px solid #32C4CB;
                color: #32C4CB;
                padding: 8.5px 23px;
                font-weight: bold;
                :hover {
                    background-color: #EDF8F9;
                }
            }
            :hover {
                background-color: #efefef;
            }
        }
    }
`;

export const TableContainer = styled(Table)`
    border: 1px solid rgba(32, 26, 61, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    .ant-pagination {
        margin-right: 20px;
    }

    .zmdi {
        cursor: pointer;
        color: #00CCDB;
        font-weight: 500;
        font-size: 21px;
        line-height: 23px;
        padding: 0 10px;

        :hover {
            color: #00CCDB;
        }
    }
`;

export const ModalContainer = styled(Modal)`
    .ant-modal-close {
        margin-top: 15px;
    }
    .ant-modal-header {
        padding: 32px 25px 24px;
        .ant-modal-title {
            font-size: 24px;
            line-height: 27px;
            color: #201A3D;
        }
    }
    .ant-btn {
        border-radius: 4px;
        border: 1px solid #DCDCDC;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 16px;
        color: #A0A0A0;
        &.ant-btn-primary {
            color: white;
            background: #24C4CC;
            border: 1px solid #00CCDB;
            :hover {
                color: white;
            }
        }
        :hover {
            color: #A0A0A0; 
        }
    }
`;

export const ChartTitle = styled.h1`
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #201A3D;
    span {
        font-weight: normal;
    }
`;

export const ChartBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-flow: row wrap;
    align-content: flex-end;
    margin-top: 30px;
    .chart-days {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-left: 1px solid #DCDCDC;
        border-bottom: 1px solid #DCDCDC;
        min-height: 195px;
        &.start {
            border-left: 2px solid #202020;
        }
        .risk-dates{
            margin-top: 10px;
            height: 8px;
            &.active {
                background: #00CCDB;
            }
            &.start-date {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &.end-date {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
`;

export const ChartLabels = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-flow: row wrap;
    align-content: flex-end;
    div {
        font-size: 10px;
        line-height: 11px;
    }
`;