import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import Moment from 'moment';
import { Tooltip, Popconfirm, message } from 'antd';

import { IPolicy, IBasicRisk, removeRisk, getPolicy } from 'store/actions/PolicyAction';
import { TableContainer } from 'Pages/Policy/style';
import RiskModal from 'Pages/Policy/components/Modals/RiskModal';
import { apiRequest, useGlobalState } from 'store';
import Chart from 'Pages/Policy/components/Chart';

interface IProps {
    data?: IPolicy;
}

interface ICoverage {
    RiskId: string;
    StartDate?: string;
    EndDate?: string;
}

const Coverage: React.FC<IProps> = ({ data }) => {
    const [tableData, setTableData] = useState<Array<ICoverage>>([]);
    const [editModal, setEditModal] = useState(false);
    const [riskData, setRiskData] = useState<IBasicRisk>()
    const [totalAmount, setTotalAmount] = useState(0)
    const [riskDates, setRiskDates] = useState<Array<Array<Moment.Moment>>>([]);
    const [{ fetching }] = useGlobalState('PolicyReducer');

    useEffect(() => {
        if (data && data.Risks) {
            let amount = 0;
            let dates: Array<Array<Moment.Moment>> = [];

            setTableData(map(data.Risks, (risk: IBasicRisk) => {
                amount += risk.RiskBody?.TotalPremium || 0;
                dates.push([
                    Moment(risk.RiskBody?.Risk_Comm).startOf('day'),
                    Moment(risk.RiskBody?.Risk_End).startOf('day')
                ])
                return {
                    RiskId: risk.RiskId,
                    StartDate: Moment(risk.RiskBody?.Risk_Comm).format('D-MMM-YYYY'),
                    EndDate: Moment(risk.RiskBody?.Risk_End).format('D-MMM-YYYY')
                }
            }));

            setRiskDates(dates);
            setTotalAmount(amount);
        }
    }, [data])

    const columns = [
        {
            title: 'Risk ID',
            dataIndex: 'RiskId',
            key: 'RiskId',
        },
        {
            title: 'Start Date',
            dataIndex: 'StartDate',
            key: 'StartDate',
        },
        {
            title: 'End Date',
            dataIndex: 'EndDate',
            key: 'EndDate',
        },
        {
            title: 'Actions',
            dataIndex: 'RiskId',
            key: 'Actions',
            render: (id: string) => (
                <>
                    <Tooltip title="Edit">
                        <i className="zmdi zmdi-edit" onClick={() => handleEdit(id)} />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm title={`Are you sure delete Risk: ${id}?`}
                            onConfirm={() => handleDeleteRisk(id)}>
                            {fetching ? <i className="zmdi zmdi-refresh zmdi-hc-spin" /> : <i className="zmdi zmdi-delete" />}
                        </Popconfirm>
                    </Tooltip>
                </>
            )
        }
    ];

    const handleDeleteRisk = async (id: string) => {
        if (data) {
            try {
                await apiRequest(removeRisk(data?.ContID, id));
                await apiRequest(getPolicy(data?.ContID));
                message.success(`Successfully deleted Risk ID: ${id}`);
            } catch (e) {
                message.error('An error has occured');
            }
        }
    }

    const handleEdit = (id: string) => {
        setEditModal(true);
        data?.Risks && setRiskData(data?.Risks[Number(id)]);
    }

    const handleCancelEditModal = () => {
        setEditModal(false);
    }

    return (
        <>
            <TableContainer columns={columns} rowKey="RiskId" dataSource={tableData} />
            <RiskModal
                risk={riskData}
                isVisible={editModal}
                onOK={handleCancelEditModal}
                onCancel={handleCancelEditModal}
            />
            <Chart riskDates={riskDates} totalAmount={totalAmount}/>
        </>
    )
}

export default Coverage;