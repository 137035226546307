import React from 'react';

interface IProps {
    
}

const CollapseFooter: React.FC<IProps> = (props) => {
    return (
        <div className="collapse-footer">
           {props.children}
        </div>
    )
}

export default CollapseFooter;