import React from 'react';
import { Tooltip } from 'antd';

import { Tag } from 'layout/PrivateLayout/styles';
import { TableContainer } from 'Pages/Policy/style';
import { IClaims } from 'Pages/Claims/components/ClaimPage';
import ClaimListModal from 'Pages/Claims/components/ClaimListModal';

interface IProps extends Required<IClaims> { }

const ClaimList: React.FC<IProps> = ({
    policy,
    claims,
    claimBenefit,
    claimRef,
    claimModal,
    fetching,
    handleViewModal,
    viewClaimList,
    rejectAll,
    approveAll,
    approveClaimItem,
    rejectClaimItem
}) => {
    const columns = [
        {
            title: 'Claim Reference',
            dataIndex: 'ClaimRef',
            key: 'ClaimRef',
        },
        {
            title: 'Claim Date',
            dataIndex: 'ClaimDate',
            key: 'ClaimDate',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (status: string) => <Tag className={status.toLowerCase()}>{status}</Tag>
        },
        {
            title: 'Actions',
            dataIndex: 'ClaimRef',
            key: 'Actions',
            render: (ref: string) => (
                <>
                    <Tooltip title="View">
                        <i className="zmdi zmdi-edit" onClick={() => viewClaimList(ref)} />
                    </Tooltip>
                </>
            )
        }
    ];

    return (
        <>
            <TableContainer
                columns={columns}
                rowKey="ClaimRef"
                pagination={false}
                loading={fetching}
                dataSource={claims} />
            <ClaimListModal
                policyId={policy?.ContID}
                fetching={fetching}
                riskId={claimBenefit[claimRef]?.riskId}
                claim={claimBenefit[claimRef]?.claim}
                benefits={claimBenefit[claimRef]?.benefits}
                isVisible={claimModal}
                onOK={handleViewModal}
                onCancel={handleViewModal}
                rejectAll={rejectAll}
                approveAll={approveAll}
                approveClaimItem={approveClaimItem}
                rejectClaimItem={rejectClaimItem}
            />
        </>
    )
}

export default ClaimList;