import React, { ReactNode, useEffect, useState } from 'react';
import { navigate } from 'raviger';
import './BenefitDetails.styles.scss';

import { useGlobalState, apiRequest } from 'store';
import { setBenefitFormData, setSparkToken } from 'store/actions/MotorRiskAction';
import { getToken, currencyFormat } from '../MotorController';
import { ReactComponent as BackArrow } from 'assets/images/backArrow.svg';
import { benefitFormProps, eventProps, benefitsSparkProps, benefitOptionProps } from '../interfaces';
import endPoint from 'config/urls';



const BenefitDetails = () => {
    const [{ BenefitFormData, SparkData }] = useGlobalState('MotorRiskReducer');

    const [benefitData, setBenefitData] = useState<benefitFormProps>(BenefitFormData);
    const [benefitsList, setBenefitsList] = useState<benefitsSparkProps[]>([]);
    const [benefitOptions, setBenefitOptions] = useState<benefitOptionProps[]>([]);



    useEffect(() => {
        let AuthorizationToken = getToken(SparkData);
        if (!AuthorizationToken) {
            fetchTokenData().then((response) => {
                getBenefitData(response);
            });
        } else {
            getBenefitData(AuthorizationToken);
            // if (VehicleFormData.Make && VehicleFormData.Model) {
            //     onMakeChange(VehicleFormData.Make);
            // }
        }
    }, [SparkData]);

    const fetchTokenData = async () => {
        const url = `${endPoint.keycloakURL}`;

        const tokenData = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': "application/x-www-form-urlencoded",
            }
        }).then((response) => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    apiRequest(setSparkToken({
                        access_token: data.access_token,
                        expires_in: data.expires_in,
                        timestamp: new Date()
                    }));
                    return data.access_token;
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return tokenData;
    }

    const getBenefitData = async (AuthorizationToken: string) => {

        const url = `${endPoint.motorExcelEngine}`;

        let date = new Date();
        const TransDate = date.toISOString();

        const pfPayload = {
            "Inputs": {},
            "TransactionDate": TransDate,
            "EngineType": "Type3",
            "Solves": [],
            "RequestedServiceCategory": ""
        }
        return await fetch(url, {
            method: "POST",
            body: JSON.stringify(pfPayload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthorizationToken}`
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    const OptionsArr = data.data.Outputs;
                    setBenefitsList(OptionsArr["NewBusinessUI"]);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleUpdate = (e: eventProps, index: number) => {
        const { value, name } = e.target;

        const newFormData = { ...benefitData };
        
        if (name === 'isChecked') {
            const newBenefitOptions = [...benefitOptions];
            let isChecked ;

            // @ts-ignore
            newBenefitOptions[name] = e.target.checked;
            // @ts-ignore
            isChecked = e.target.checked;
            // console.log('handleChange', isChecked);
            // console.log('new data', newBenefitOptions);

            if (isChecked) {
                const selectedBenefit = benefitsList[index];
                // console.log("this is benef list", selectedBenefit);
                selectedBenefit.SumInsured = benefitsList[index].LimitDefault;
                newBenefitOptions.push({
                    "Option": selectedBenefit.Option,
                    "Included": "Y",
                    "SumInsured": Number(selectedBenefit.SumInsured)
                });
            } else {
                newBenefitOptions.splice(index, 1)
            }
            newFormData.OptionalBenefits = newBenefitOptions;

            setBenefitOptions(newBenefitOptions);
        } else {
            // @ts-ignore
            newFormData[name] = value;
            const newBenefitOption = [...benefitOptions]
            newBenefitOption[index].SumInsured = Number(value);
            setBenefitOptions(newBenefitOption);
           
        }
        
        // newFormData.OptionalBenefits = newBenefitOptions;
        setBenefitData(newFormData);
        console.log("this is form data", newFormData);
        apiRequest(setBenefitFormData(newFormData)); // set driver form data in reducer
    }

    const goToUnderwriting = () => {
        console.log("this is benefit data", BenefitFormData);
        navigate('/UnderwritingDetails');
    }
    const goToPlanDetails = () => {
        navigate('/PlanDetails');
    }

    const showSumInsuredAmount: ReactNode = (amountData: string, amountLimit: number,index:number) => {
        const AmountList = amountData.split(';');
        if (AmountList.length > 1) {
            return (
                <select className="form-control" name='SumInsured' id='SumInsured' onChange={e => { handleUpdate(e, index) }}>
                    {
                        AmountList.map(amount => (
                            <option value={amount} selected={(Number(amount) === amountLimit) ? true : false}>
                                {amount}
                            </option>
                        ))
                    }
                </select>
            )
        } else {

        }
    }

    return (
        <div id="benefit-details-container">
            <div className='container'>
                <div className='d-flex justify-contents-start'>
                    <BackArrow className='center' onClick={goToPlanDetails} />
                    <h3 className='m-4'>Customize your Benefits</h3>
                </div>
                <div className='container'>
                    <div className='benefit-container'>
                        <div className='color-style'>
                            Benefit Details
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container'>
                            {/* <div className="row col-12">
                                <div className='col-10'>
                                    <label>Sum insured</label>
                                    <input type="range" className="form-control-range" id="formControlRange" />
                                </div>
                                <div className="col-2">
                                    <input className='form-control mt-3' type="text"/>
                                </div>
                            </div> */}

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Select</th>
                                        <th scope="col">Benefit Description</th>
                                        <th scope="col">Benefit Code</th>
                                        <th scope="col">Sum Insured</th>
                                        <th scope="col">Premium Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        benefitsList.map((benefit, index) => (
                                            <tr>
                                                <td scope="col">
                                                    <input type="checkbox" name="isChecked" id="isChecked" onChange={e => handleUpdate(e, index)} />
                                                </td>
                                                <td scope="col">
                                                    <p>{benefit.Desc}</p>
                                                </td>
                                                <td scope="col">
                                                    <p>{benefit.Option}</p>
                                                </td>
                                                <td scope="col">
                                                    {showSumInsuredAmount(benefit.LimitInterval, benefit.LimitDefault,index)}
                                                </td>
                                                <td scope="col">
                                                    {currencyFormat(benefit.Premium)}
                                                </td>

                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            <div className='form-group mt-4'>
                                <button type="button" className="btn next-style" onClick={goToUnderwriting}>Next: Underwriting</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BenefitDetails;