import { combineReducers } from 'redux';
import get from 'lodash/get';

import ClaimReducer, { ClaimInitialState } from 'store/reducers/ClaimReducer';
import PolicyReducer, { PolicyInitialState } from 'store/reducers/PolicyReducer';
import TransactionReducer, { TransactionInitialState } from 'store/reducers/TransactionReducer';
import MotorRiskReducer, { MotorRiskInitialState } from 'store/reducers/MotorRiskReducer';
import { USER_LOGOUT } from 'store/actions/UserAction';
import {
  TYPE_FETCHED,
  TYPE_FETCHING,
  TYPE_ERROR
} from 'store/actions/ActionTypes';

export interface IFetch {
  next?: string;
  previous?: string;
  error: any;
  fetched: boolean;
  fetching: boolean;
}

export interface IAction {
  type: string;
  payload?: any;
}

export const InitializeState = (
  state: any,
  action: IAction,
  defaultState = {}
) => {
  if (
    typeof action.type !== 'undefined' &&
    action.type.includes(USER_LOGOUT.concat(TYPE_FETCHED))
  ) {
    return defaultState;
  }

  if (action && action.type) {
    // Fetching data
    if (action.type.endsWith(TYPE_FETCHING)) {
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    }

    // Fetching done
    if (action.type.endsWith(TYPE_FETCHED)) {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null
      };
    }

    // Error fetching
    // @TODO: Need to implement this
    if (action.type.endsWith(TYPE_ERROR)) {
      const defaultError = 'An error has occured, please contact system admin';
      const error = get(action, 'payload.data.error.message', defaultError);

      return {
        ...state,
        fetching: false,
        fetched: false,
        error: error === '' ? defaultError : error
      };
    }
  }

  // reset state
  return {
    ...state,
    fetched: false,
    fetching: false,
    error: null
  };
};

export const globalStates = {
  ClaimReducer: ClaimInitialState,
  PolicyReducer: PolicyInitialState,
  TransactionReducer: TransactionInitialState,
  MotorRiskReducer: MotorRiskInitialState
};

export default combineReducers({
  ClaimReducer,
  PolicyReducer,
  TransactionReducer,
  MotorRiskReducer
});
