import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

import { IFieldProps } from 'common/Field';
import { DateRange } from 'common/style';

const DateRangeField: React.FC<IFieldProps> = (props) => {
    const [fieldValue, setFieldValue] = useState(props.value);
    const [fieldValue2, setFieldValue2] = useState(props.value2);
    const { register, setValue } = useFormContext();

    let values: RangePickerValue = [undefined, undefined];
    if (fieldValue && fieldValue2) {
        values = [Moment(fieldValue), Moment(fieldValue2)];
    }

    useEffect(() => {
        setFieldValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setFieldValue2(props.value2);
    }, [props.value2]);

    return (
        <>
            <input
                type="text"
                name={props.name}
                style={{ display: 'none' }}
                defaultValue={props.value}
                ref={register({ required: props.required ? true : false })}
            />
            <input
                type="text"
                name={props.name2}
                style={{ display: 'none' }}
                defaultValue={props.value2}
                ref={register({ required: props.required ? true : false })}
            />
            <DateRange
                onChange={dates => {
                    const [startDate, endDate] = dates;
                    setValue(props.name, startDate?.format());
                    setFieldValue(startDate?.format())
                    setFieldValue2(endDate?.format())
                    props.name2 && setValue(props.name2, endDate?.format())
                }}
                value={values}
                format="DD/MM/YYYY" />
        </>
    );
}

export default DateRangeField;