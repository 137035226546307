import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import { createFormProps1, createFormProps2, eventProps, monthListProp } from '../interfaces';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateNewPeriod.styles.scss';
import { DatePicker } from 'antd';
import moment from 'moment';
import Breadcrumbs from 'Pages/Components/Breadcrumbs/Breadcrumbs.componet';
import { CreateNewPeriods, MonthList } from '../AccountingPeriodController';




const CreateNewPeriod = () => {
    let periodData: createFormProps1 = {
        FinYear: moment(),
        FinYear1: (new Date()).getFullYear(),
        FinPeriod: [moment(), moment()],
        FinPeriod1: "",
        FromDate:"",
        ToDate:"",
    }

    let periodDataRow: createFormProps2[] = [];
    for (let i = 0; i <= 18; i++) {
        periodDataRow.push({
            Period: i + 1,
            Data: "",
            FromDate: "",
            ToDate: "",
            Status: ""
        });
    }
    const [isEditFlow, setIsEditFlow] = useState<boolean>(false);
    const [periodFormValue, setPeriodFormValue] = useState<createFormProps1>(periodData);
    const [periodFormRow, setPeriodFormRow] = useState<createFormProps2[]>(periodDataRow);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showMonthsWarning, setShowMonthsWarning] = useState<boolean>(false);
    const [showWrongFinanceYear, setShowWrongFinanceYear] = useState<boolean>(false);
    const [basicSectionActive, setBasicSectionActive] = useState<boolean>(true);
    const [periodSectionActive, setPeriodSectionActive] = useState<boolean>(false);
    const [noOfMonths, setNoOfMonths] = useState<number>(0);
    const [periodDates, setPeriodDates] = useState<[string, string]>(["", ""]);
    const [successPeriodCnt, setSuccessPeriodCnt] = useState<number>(0);
    const [failedPeriodCnt, setFailedPeriodCnt] = useState<number>(0);
    const [periodYear, setPeriodYear] = useState<string>();
    const [fromMonthList, setFromMonthList] = useState<monthListProp[]>(MonthList);
    const [toMonthList, setToMonthList] = useState<monthListProp[]>(MonthList);

    const { RangePicker } = DatePicker;
    /**
        * 
        * @param periodYear: number - Year to fetch data while update
        * @returns void;
        */
    useEffect(() => {
        //@ts-ignore
        const urlPath = window.location.pathname.split('/');
        if (urlPath.length > 2) {
            const Year = urlPath[2];
            setIsEditFlow(true);
            setPeriodYear(Year);
            getPeriodData(Year);
            onYearChange(moment(`${Year}-01-01`), Year);
            const BreadCrumbLength = CreateNewPeriods.length;
            CreateNewPeriods[BreadCrumbLength - 1].title = 'Update Period';
        }
    }, [])

    const getPeriodData = async (periodYear: string) => {
        if (!periodYear) {
            throw new Error('Accounting Period data not found');
        }
        // setIsLoading(true);
        try {
            await fetch(`${endPoint.AccountingPeriod}/${periodYear}/-1`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    // setIsLoading(false);
                    if (statusCode === 200) {
                        // setExistingPeriodData(data);
                        const existingData: createFormProps2[] = [];
                        // const existingFinData 
                        data.map((item: any, index: number) => {
                            let periodData = JSON.parse(item.Data);
                            if (index === 0) {
                                const FinPeriodArr = periodData.FinPeriod.split(" ");
                                console.log("This is fin period",FinPeriodArr);
                                onRangeChange(
                                    [moment(`${FinPeriodArr[0]}-01`), moment(`${FinPeriodArr[1]}-01`)],
                                    FinPeriodArr,
                                    periodYear
                                )
                                onSetMonth(FinPeriodArr[0],FinPeriodArr[1]);

                            }
                            let fromDate = item.FromDate;
                            let toDate = item.ToDate;
                            let dataObj = {
                                Data: periodData.Description,
                                Period: item.Period,
                                FromDate: fromDate.split('T')[0],
                                ToDate: toDate.split('T')[0],
                                Status: item.Status
                            }
                            existingData.push(dataObj);
                            return true;
                        });
                        setPeriodFormRow(existingData);
                    }
                });
        } catch (exception) {
            // setIsLoading(false);
            console.log(exception);
        }
    }
    const goToAllPeriod = () => {
        navigate('/AllPeriod');
    };

    const goToPeriodSection = () => {
        setPeriodSectionActive(!periodSectionActive);
        // console.log('months', noOfMonths)
        setBasicSectionActive(false);
        if (periodDates[1]) {
            const endYear = periodDates[1].split("-")[0];
            if (endYear !== `${periodFormValue.FinYear1}`) {
                setShowWrongFinanceYear(true);
                return;
            }
        }
        if (noOfMonths !== 12) {
            setShowMonthsWarning(true);
            return;
        }

    }

    const onSetMonth = (date1 :string,date2:string) =>{
        const MonthVals = { ...periodFormValue };
        MonthVals.FromDate = date1.split("-")[1];
        MonthVals.ToDate = date2.split("-")[1];
        setPeriodFormValue(MonthVals);

    }

    const onYearChange = (date: moment.Moment | null, dateString: string) => {
        // console.log(date, dateString);
        const newYear = { ...periodFormValue };
        // @ts-ignore
        newYear.FinYear = date;
        newYear.FinYear1 = Number(dateString);
        setPeriodFormValue(newYear);
    }


    const onRangeChange = (dates: [moment.Moment | null, moment.Moment | null], dateStrings: [string, string], finYear: string = "", toMonth: string = "") => {
        const newPeriod = { ...periodFormValue };
        // console.log(dates, dateStrings);
        // @ts-ignore
        let finanYear = newPeriod.FinYear1;
        if (finYear) {
            newPeriod.FinYear = moment(`${finYear}-01-01`);
            newPeriod.FinYear1 = Number(finYear);
        }
        // @ts-ignore
        newPeriod.FinPeriod = dates;
        newPeriod.FinPeriod1 = Number(dateStrings);

        setPeriodDates(dateStrings);
        const startYearMonthArr = dateStrings[0].split("-");
        const endYearMonthArr = dateStrings[1].split("-");
        // console.log('these are dates ', dateStrings);
        let requiredMonths = 0;
        let tempFormRow1 = [];

        if (startYearMonthArr[0] == endYearMonthArr[0]) {

            requiredMonths = Number(endYearMonthArr[1]) - Number(startYearMonthArr[1]) + 1;
            setPeriodFormValue(newPeriod);

            const tempFormRow = [...periodDataRow];

            for (let a = 1; a <= requiredMonths; a++) {
                let monthVal1 = Number(startYearMonthArr[1]) - 1 + a;
                let fromYear = startYearMonthArr[0];
                if (monthVal1 > 12) {
                    monthVal1 = Number(monthVal1) - 12;
                    fromYear = endYearMonthArr[0];
                }
                const monthVal2 = `${monthVal1 > 9 ? monthVal1 : "0" + monthVal1}`;
                tempFormRow[a - 1].FromDate = `${fromYear}-${monthVal2}-01`;
                var lastDayOfMonth = new Date(Number(fromYear), Number(monthVal2), 0);
                const endMonth = lastDayOfMonth.getMonth() + 1;
                tempFormRow[a - 1].ToDate = `${lastDayOfMonth.getFullYear()}-${(endMonth < 10) ? '0' + endMonth : endMonth}-${lastDayOfMonth.getDate()}`;
                tempFormRow1.push(tempFormRow[a - 1]);
            }
        } else {
            requiredMonths = (12 - Number(startYearMonthArr[1]) + 1) + Number(endYearMonthArr[1]);
            setPeriodFormValue(newPeriod);
            const tempFormRow = [...periodDataRow];

            for (let a = 1; a <= requiredMonths; a++) {
                let monthVal1 = Number(startYearMonthArr[1]) - 1 + a;
                let fromYear = startYearMonthArr[0];
                if (monthVal1 > 12) {
                    monthVal1 = Number(monthVal1) - 12;
                    fromYear = endYearMonthArr[0];
                }

                const monthVal2 = `${monthVal1 > 9 ? monthVal1 : "0" + monthVal1}`;
                tempFormRow[a - 1].FromDate = `${fromYear}-${monthVal2}-01`;
                const lastDayOfMonth = new Date(Number(fromYear), Number(monthVal2), 0); //new Date(Number(fromYear), Number(monthVal2) - 1, 28);
                const endMonth = lastDayOfMonth.getMonth() + 1;
                tempFormRow[a - 1].ToDate = `${lastDayOfMonth.getFullYear()}-${(endMonth < 10) ? '0' + endMonth : endMonth}-${lastDayOfMonth.getDate()}`;
                tempFormRow1.push(tempFormRow[a - 1]);
            }
        }
        setPeriodFormRow(tempFormRow1);
        setNoOfMonths(requiredMonths);
    }



    const handleUpdate = (e: eventProps, index = -1) => {
        if (index < 0) {
            return;
        }
        const { value, name } = e.target;
        // const finYearValue={...periodData}
        const headerFormValue = [...periodFormRow];
        // @ts-ignore
        headerFormValue[index][name] = value;
        setPeriodFormRow(headerFormValue);
        
    }

    const setToDateMonths = (e: eventProps) => {
        const { value, name } = e.target;
        const tempMonthList = [...toMonthList];
        const t1 = tempMonthList.map(m => {
            if (Number(m.value) < Number(value)) {
                m.disabled = true;
            } else {
                m.disabled = false;
            }
            return m;
        });
        setToMonthList(t1);
        const tempFormValue = {...periodFormValue};
        tempFormValue[name] = value;
        setPeriodFormValue(tempFormValue);
    }

    const onMonthsSelect = (e: eventProps) =>{
        const { value, name } = e.target;
        const tempMonthValues = {...periodFormValue};
        tempMonthValues[name] = value;
        setPeriodFormValue(tempMonthValues);

        let monthVal1 = tempMonthValues.FromDate;
        let monthVal2 = tempMonthValues.ToDate;
        let yearMonth1 = `${periodYear}-${monthVal1}`;
        let yearMonth2=`${periodYear}-${monthVal2}`;
        

        let fromMonth = moment(`${periodYear}-${monthVal1}-01`)
        let ToMonth = moment(`${periodYear}-${monthVal2}-01`)
        onRangeChange([fromMonth,ToMonth],[yearMonth1,yearMonth2],periodYear);
    }

    const ModifyData = async (periodYear: string) => {
        if (!periodYear) {
            throw new Error('Accounting Period data not found');
        }
        // setIsLoading(true);
        try {
            await fetch(`${endPoint.AccountingPeriod}/${periodYear}/-1`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    // setIsLoading(false);
                    if (statusCode === 200) {
                        data.map((item: any, index: number) => {
                            item.Status = "Close";

                            try {
                                fetch(`${endPoint.AccountingPeriod}/${periodYear}/-1`, {
                                    method: 'PUT',
                                    body: item,
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                    }
                                })
                                    .then((response: any) => {
                                        const statusCode = response.status;
                                        const data = response.json();
                                        return Promise.all([statusCode, data]);
                                    })
                                    .then(([statusCode, data]) => {
                                        // setIsLoading(false);
                                        if (statusCode === 200) {
                                            SubmitPeriod("success");
                                        }
                                    });
                            } catch (exception) {
                                // setIsLoading(false);
                                console.log(exception);
                            }

                        });
                    }
                });
        } catch (exception) {
            // setIsLoading(false);
            console.log(exception);
        }


    }



    const SubmitPeriod = (action: string) => {
        if (action === 'warning') {
            setShowMonthsWarning(false);
        }
        if (action === 'success') {
            let successCnt = successPeriodCnt;

            let failedCnt = failedPeriodCnt;
            const loopCnt = periodFormRow.length; //periodFormRow.length;
            for (let i = 0; i < loopCnt; i++) {
                const index = i + 1;
                const fromDate = new Date(periodFormRow[i].FromDate);
                const toDate = new Date(periodFormRow[i].ToDate);
                const ObjData = {
                    "Description": periodFormRow[i].Data,
                    "FinPeriod": periodDates[0] + " " + periodDates[1],
                }
                const FinData = JSON.stringify(ObjData)
                // apply if condition if all values of row are not empty
                let accountingPeriod;
                if (periodFormRow[i].Period && periodFormRow[i].FromDate && periodFormRow[i].ToDate) {
                    accountingPeriod = {
                        "FinYear": periodFormValue.FinYear1,
                        "Period": index,
                        "Data": FinData,
                        "FromDate": fromDate.toISOString(),
                        "ToDate": toDate.toISOString(),
                        "Status": periodFormRow[i].Status
                    };

                    // whole api logic

                    const url = `${endPoint.AccountingPeriod}`;

                    fetch(url, {
                        method: ("POST"),
                        body: JSON.stringify(accountingPeriod),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        }
                    })
                        .then((response) => {
                            const statusCode = response.status;
                            const data = response.json();
                            return Promise.all([statusCode, data]);
                        })
                        .then(([statusCode, data]) => {
                            // console.log(data);
                            if (statusCode === 200) {
                                successCnt += 1;
                            } else {
                                failedCnt += 1;
                                console.log(`Error:222 ${statusCode} - ${data.error}`)
                            }
                            if (i == loopCnt - 1) {
                                setSuccessPeriodCnt(successCnt);
                                setFailedPeriodCnt(failedCnt);
                                setShowSuccessModal(true);
                            }
                        })
                        .catch((error) => {
                            failedCnt += 1;
                            console.log("error: 231", error);
                            if (i == loopCnt - 1) {
                                setSuccessPeriodCnt(successCnt);
                                setFailedPeriodCnt(failedCnt);
                                setShowSuccessModal(true);
                            }
                        });
                    console.log(accountingPeriod);
                }
            }

        }

    }

    const closeModal = (action: string) => {
        if (action === 'success') {
            setShowSuccessModal(false);
            setPeriodFormRow(periodDataRow);
            setPeriodFormValue(periodData);
            setSuccessPeriodCnt(0);
            setFailedPeriodCnt(0);
            navigate('/AllPeriod');

        }
        if (action === 'warning') {
            setShowMonthsWarning(false);
            setPeriodSectionActive(false);
            setBasicSectionActive(true);
        }
        if (action === 'error') {
            setShowWrongFinanceYear(false);
            setPeriodSectionActive(false);
            setBasicSectionActive(true);
        }
    }
    // console.log('this is year', periodFormValue);
    // console.log("This is array", periodFormRow);
    // console.log("This is existing data", periodFormValue.FinPeriod);

    return (
        <div id="create-new-period-container">
            <ReceiptHeader />

            <div className="m-ap-wrap">
                <div
                    className="mb-3">
                    <Breadcrumbs paths={CreateNewPeriods} />

                </div>

                <div className="m-ap-title">{isEditFlow ? 'Modify' : 'Create New'} Accounting Period</div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${basicSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head " onClick={() => { setBasicSectionActive(!basicSectionActive); setPeriodSectionActive(false); }}>
                        <div className="m-ap-sub-title">Basic Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        basicSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="pr-3 pl-4 pb-4">
                                    <div className="row">
                                        <div className="mr-3 ml-3">
                                            <label className="m-ap-label">Financial Year</label>
                                            <DatePicker id="FinYear" name="FinYear" value={periodFormValue.FinYear} onChange={onYearChange} picker="year" />
                                        </div>
                                        {isEditFlow ? (
                                            <>
                                                <div className="col-3">
                                                    <label className="m-ap-label">From Month</label>
                                                    <select className="form-control" id="FromDate" name='FromDate' value={periodFormValue.FromDate} onChange={(e)=>setToDateMonths(e)}>
                                                        {
                                                            fromMonthList.map((option, index) => (
                                                                <option key={index} value={option.value}>{option.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                                <div className="col-3">
                                                    <label className="m-ap-label">To Month</label>
                                                    <select className="form-control" id="ToDate" name="ToDate" value={periodFormValue.ToDate} onChange={(e)=>onMonthsSelect(e)}>
                                                        {
                                                            toMonthList.map((option, index) => (
                                                                <option key={index} value={option.value} disabled={option.disabled}>{option.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="mr-3 ml-3">
                                                <label className="m-ap-label">Financial Period</label>
                                                <RangePicker name="FinPeriod" value={periodFormValue.FinPeriod} onChange={onRangeChange} picker="month" />
                                            </div>
                                        )}



                                    </div>
                                </div>
                                <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                                    <div className="m-ap-btn m-ap-small-btn m-ap-blue"
                                        onClick={goToPeriodSection}>
                                        Next
                                    </div>
                                </div>
                            </div>

                        )}
                </div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${periodSectionActive ? 'm-ap-active' : ''}`} >
                    <div className="m-ap-ac-head" onClick={() => { setPeriodSectionActive(!periodSectionActive); setBasicSectionActive(!basicSectionActive) }}>
                        <div className="m-ap-sub-title">Period Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        periodSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-row-table">
                                    <div className="m-ap-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="m-ap-period">Period </th>
                                                    <th className="m-ap-description">Description </th>
                                                    <th className="m-ap-from-date">From Date </th>
                                                    <th className="m-ap-to-date">To Date </th>
                                                    <th className="m-ap-status">Status </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    periodFormRow.map((row, index) => (

                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div className="m-ap-col-12 m-ap-input-f">
                                                                    <textarea placeholder="Description" id="Data" name="Data" value={row.Data} onChange={(e) => handleUpdate(e, index)} >
                                                                    </textarea>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <input type="date" disabled placeholder="From Date" id="FromDate" name="FromDate" value={row.FromDate} onChange={(e) => handleUpdate(e, index)} />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <input type="date" disabled placeholder="To date" id="ToDate" name="ToDate" value={row.ToDate} onChange={(e) => handleUpdate(e, index)} />
                                                                </div>
                                                            </td>
                                                            <td scope="col" >
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <select id="Status" name="Status" value={row.Status} onChange={(e) => handleUpdate(e, index)}>
                                                                        <option value="">---Select---</option>
                                                                        <option value="Open">Open</option>
                                                                        <option value="Close">Close</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                        </tr>


                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>


                <div className="m-ap-bottom">
                    <button id="create-submit" onClick={!isEditFlow ? () => SubmitPeriod('success') : () => ModifyData(periodYear)} >{isEditFlow ? 'Update Changes' : 'Create'}</button>
                    <button id="cancel" onClick={goToAllPeriod} >Cancel</button>
                </div>

                {
                    showSuccessModal && (
                        <div className="modal1" id="myModal">
                            <div className="modal-content1">
                                <div className="col-md-12 mb-2 download-receipt-title1">
                                    <span>
                                        Account Period Created
                                    </span>
                                </div>
                                <div className="col-md-12 mb-2 receipt-success">
                                    <span>
                                        Account period has been succesfully created for
                                        <b> financial year {periodFormValue.FinYear1}.</b>
                                    </span>
                                    <br />
                                    <span>
                                        Number of period successfully created - {successPeriodCnt}<br />
                                        Number of period failed - {failedPeriodCnt}
                                    </span>
                                </div>
                                <div className="col-md-12 mb-2 download-receipt-container">
                                    <button id="download-receipt-close" onClick={() => closeModal('success')}>Close</button>
                                </div>
                                <br /><br />
                            </div>
                        </div>
                    )
                }

                {
                    showMonthsWarning && (
                        <div className="modal1" id="myModal">
                            <div className="modal-content1">
                                <div className="col-md-12 mb-2 download-receipt-title1">
                                    <span>
                                        You would like to Proceed?
                                    </span>
                                </div>
                                <div className="col-md-12 mb-2 receipt-success">
                                    <span>
                                        You are creating less or more than 12 months accounting period, click Yes to continue.
                                    </span>
                                    <br />
                                </div>
                                <div className="col-md-12 mb-2 download-receipt-container">
                                    <button id="warning-continue" onClick={() => SubmitPeriod('warning')}>Yes, Countinue</button>
                                    <button id="download-receipt-close" onClick={() => closeModal('warning')}>No</button>
                                </div>
                                <br /><br />
                            </div>
                        </div>
                    )
                }

                {
                    showWrongFinanceYear && (
                        <div className="modal1" id="myModal">
                            <div className="popup-box popup-msg">
                                <div className="row success-msg success-msg-desktop">
                                    <div className="background-success display-contents">
                                        <span className="glyphicon glyphicon-ok-circle success-icon"><i className="bi bi-check-circle"></i></span>
                                        <span className="message">&nbsp;&nbsp;Wrong Financial year selected </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span id="dismiss" onClick={() => closeModal('error')}><i className="zmdi zmdi-close zmdi-hc-2x"></i> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }


            </div >
        </div>
    )
}

export default CreateNewPeriod;