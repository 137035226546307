import React from 'react';
import './Loader.styles.scss';

const Loader = () => {
    return (
        <div id="LoaderModal">
            <div className="modal">
                <div className="loader-content">
                    <svg width="100" height="100" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#24c4cc" aria-label="audio-loading">
                        <g fill="none" fillRule="evenodd" strokeWidth="2">
                            <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                            </animate>
                                <animate attributeName="strokeOpacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                </animate>
                            </circle>
                            <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                            </animate>
                                <animate attributeName="strokeOpacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                </animate>
                            </circle>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Loader;