const todayDate = (returnType) => {
    const today = new Date();
    if (returnType === 'ISO') {
        return today.toISOString();
    }
    return today.toISOString().split("T")[0];
}

export const getReceiptData = (receiptData, OtherData, receiptId) => {
    const ReceiptDetails = [];
    for (let i in receiptData) {
        ReceiptDetails.push({
            "SeqNo": parseInt(i) + 1,
            "DepositeBy": receiptData[i].DepositeBy,
            "DepositeByNo": receiptData[i].DepositeByNo,
            "DepositeDate": (receiptData[i].DepositeDate) ? receiptData[i].DepositeDate.replace(/\//g, '-') : '',
            "DepositeType": receiptData[i].DepositeType,
            "DepositeAmount": Number(receiptData[i].DepositeAmount),
            "AccountCurrency": "SGD",
            "CurrencyRate": 1,
            "DepositeRefNo": receiptData[i].DepositeRefNo,
            "DepositeRefType": receiptData[i].DepositeRefType,
            "BankCode": receiptData[i].BankCode,
            "BankName": receiptData[i].BankName,
            "BankFee": Number(receiptData[i].BankFee),
            "BankBranchCode": receiptData[i].BankBranchCode,
            "BankBranchName": receiptData[i].BankBranchName,
            "BalanceAmount": 0,
            "Data": null,
            "Status": receiptData[i].Status
        });
    }
    return {
        "ReceiptNumber": OtherData.ReceiptNumber || "",
        "Currency": "INR",
        "PayeeType": OtherData.PayeeType,
        "PayeeCode": OtherData.PayeeCode,
        "ReceiptDate": OtherData.ReceiptDate,
        "PayeeName": OtherData.PayeeName,
        "ReceiptStatus": "Review",
        "TransactionRef": "T3455",
        "Remarks": OtherData.Remarks,
        "TranDate": "2021-02-20",
        "ReceiptDetail": ReceiptDetails
    }
}

export const getInitialData = () => {
    return {
        "Inputs": {
            "Lists.AccountCode": 20240444,
            "Lists.BankCode": 4,
            "Lists.DepositedAmount": 100000,
            "Lists.DepositeNumber": 23243,
            "Lists.Language": "",
            "Lists.PayeeID": 56576767,
            "Lists.PayeeName": "ABCDEF",
            "Lists.PayeeType": "",
            "Lists.PaymentMethod": "",
            "Lists.PolicyNo": "",
            "Lists.ReceiptDate": "2021-03-05",
            "Lists.RefrenceNo": 222220000,
            "Lists.RefrenceType": "Policy",
            "Lists.Remarks": "Remarks For Customer"
        },
        "TransactionDate": todayDate('ISO'),
        "RequestedServiceCategory": "Lists"
    }
}

export const getApplyPremiumData = (policyList, receiptList) => {
    const pArr = [];
    policyList.map(pol => {
        pArr.push({
            "ContId": pol,
            "BillPremium": 1100,
            "AppliedPremium": 1100
        });
        return pArr;
    });

    const rArr = [];
    receiptList.map((r, index) => {
        rArr.push({
            "ReceiptNumber": r.ReceiptNumber,
            "SeqNo": index + 1,
            "BalanceAmount": r.Amount
        });
        return rArr;
    });

    return {
        "AppliedDate": todayDate(),
        "AppliedAmount": 3600,
        "PolicyDetails": pArr,
        "ReceiptDetails": rArr
    }
}

// == For Claim ==
// Payment Order - ItemNo2
// Claim - Description
// Policy - PolicyNo
// Befefit - InsuredName
// Claim Date - ItemDate
// Claim Approval Date - ItemDate2
// Claim Amount - ItemAmount
// Tax - TaxEximpt
// VAT - VAT
// WTH - WTH

// == for Refund ==
// Refund Type - ItemType
// Endorsement - ItemNo2
// Policy/PO - PolicyNo
// Date - ItemDate
// Amount - ItemAmount
// Description - Description
// Tax - TaxEximpt
// VAT - VAT
// WTH - WTH



export const getVoucherCreateData = (payeeDetails, lineItemDetails, payout, payeeAcc) => {
    const items = [];
    lineItemDetails.forEach((item, index) => {
        items.push({
            "ItemNo": `0000${index + 1}`,
            "ItemNo2": item.ItemNo2,
            "ItemAmount": Number(item.ItemAmount),
            "ItemAmount2": Number(item.ItemAmount2),
            "InsuredName": item.InsuredName,
            "PolicyNo": item.PolicyNo,
            "TaxEximpt": item.TaxEximpt,
            "VAT": 0,
            "WTH": 0,
            "Description": item.Description,
            "ItemDate": item.ItemDate,
            "ItemDate2":item.ItemDate2,
            "ItemType": "",
        });
    });
    return {
        "PVNumber": "",
        "PayeeType": payeeDetails.PayeeType,
        "PayeeCode": payeeDetails.PayeeCode,
        "PayeeName": payeeDetails.PayeeName,
        "PaymentItem": payeeDetails.PaymentItem,
        "ItemRef": payeeDetails.ItemRef,
        "Currency": "THB",
        "CostProfitCenter": payeeDetails.PayeeCode,
        "CreateDate": payeeDetails.PaymentDate,
        "PVAmount": 10000,
        "PaymentDate": payeeDetails.DueDate,
        "Remarks": payeeDetails.PayeeCode,
        "PaymentStatus": "Pending",
        "UserName": "Test User",
        "PayeeContactDets": {
            "Email": "arvind.kushwaha@coherent.com.hk",
            "Phone": "7276900528",
            "FbId": "",
            "Whatsapp": "",
            "PreferredContChannel": "Email",
            "Add": {
                "Add1": "1 Hougang St.",
                "Add2": "blok 548",
                "Add3": "District 3",
                "Add4": "",
                "City": "Hougang",
                "Country": "Singapore",
                "Pcode": "530548"
            }
        },
        "PayoutDetails": {
            "Type": payout.PayoutType,
            "Method": "",
            "BankName": `${payout.BranchCode} - ${payout.BranchName}`,
            "AccountNumber": `${payout.AccountCode} - ${payout.AccountNumber}`,
            "BankCode":`${payout.BankCode} - ${payout.BankName}`,
            "RCCode": payout.RCCode,
            "Status": "In Clearing",
            "StatusDate": todayDate(),
            "OldPVNumber": ""

        },

        // == Pay Cheque Details ==
        // Amount - PayAmount
        // Type of Pay Cheque - CheckType
        // Cheque Number - AccountNumber
        // Cheque Number 2 - BankName

        // Payee Name - PayeeName
        // Name on Cheque - AccountHolderName
        // Old Document Number - PayeeOldDocNumber

        // Cheque Date - PayDate
        // Pickup Cheque at Branch - IBAN / Branch
        // Remark - PayRemarks

        "PayeeAccountDetails": {
            "PaymentRefNumber": payeeAcc.PaymentRefNumber,
            "AccountHolderName": payeeAcc.AccountHolderName,
            "AccountNumber": payeeAcc.AccountNumber,
            "BankName": payeeAcc.BankName,
            "PayeeName": payeeAcc.PayeeName,
            "Branch": payeeAcc.Branch,
            "IBAN": payeeAcc.IBAN,
            "CheckType": payeeAcc.ChequeType,
            "PayAmount": parseFloat(payeeAcc.PayAmount),
            "PayeeOldDocNumber": payeeAcc.PayeeOldDocNumber,
            "PayDate": payeeAcc.PayDate,
            "PayRemarks": payeeAcc.PayRemarks
        },

        "ItemDetails": items,
        "Documents": [
            {
                "Url": "www.docstore.com",
                "Descr": "photoid",
                "AttachDate": "2020-10-14T09:40:36.940647435Z",
                "AttachNote": {
                    "Dte": "2020-10-14T09:40:36.940647435Z",
                    "User": "Test User",
                    "Text": "photoid"
                }
            }
        ]
    };
}
