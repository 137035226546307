import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { useFormContext } from 'react-hook-form';

import { IFieldProps } from 'common/Field';
import { StyleDatePicker } from 'common/style';

const DateField: React.FC<IFieldProps> = (props) => {
    const [fieldValue, setFieldValue] = useState(props.value);
    const { register, setValue } = useFormContext();

    useEffect(() => {
        setFieldValue(props.value);
    }, [props.value]);

    return (
        <>
            <input
                type="text"
                name={props.name}
                style={{ display: 'none' }}
                defaultValue={props.value}
                ref={register({ required: props.required ? true : false })}
            />
            <StyleDatePicker
                onChange={date => {
                    setFieldValue(date?.format());
                    setValue(props.name, date?.format());
                }}
                value={fieldValue ? Moment(fieldValue) : undefined}
                format="DD/MM/YYYY" />
        </>
    );
}

export default DateField;