import styled from 'styled-components';
import { Layout, Menu } from 'antd';

const color = {
  Dark: '#EFEFEF'
};

export const MainLayout = styled(Layout)`
  background-color: #FFFFFF;
`;

export const SidebarContainer = styled(Layout.Sider)`
  background-color: #FFFFFF;
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.12);
  z-index: 2;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;

export const HeaderContainer = styled.div`
  min-height: 95px;
  padding: 30px;
  a:hover {
    color: ${color.Dark};
  }
  .header-heading {
    display: flex;
    justify-content: space-between;
    flex: 1 4;
    .header-heading-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      padding-top: 20px;
    }
    .header-heading-extra {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 800px;
      button {
        margin: 4px 0 0 5px;  
      }
      .ant-select {
        margin: 5px 0 0 5px;
        min-width: 600px;
      }
    }
  }
  .header-content {
    font-size: 24px;
    line-height: 27px;
    padding-top: 50px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;  
`;

export const LogoContainer = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #E5E5E5;
`;

export const AccountInfo = styled.div`
  display: flex;
  flex-direction: row;
  .headshot {
    background-color: #dcdcdc;
    background-size: cover;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    margin-right: 17px;
    margin-left: -5px;
  }
`;

export const NavAction = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NaviContainer = styled(Menu)`
  border-right: none;
  flex: 1 1 auto !important;
  .ant-menu-item {
    font-size: 14px;
    line-height: 24px;
    padding: 12px 12px !important;
    margin: 0;
    list-style: none;
    min-height: 48px;
    width: 100%;
    vertical-align: middle;
    color: #000000;
    .zmdi {
      font-size: 14px;
      line-height: 24px;
      padding-right: 18px;
    }
    .zmdi-after {
      float: right;
      padding-right: 3px;
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 16px;
    }
    &::after {
      border-right: none;
    }
    &.ant-menu-item-selected {
      background-color: ${color.Dark};
      color: #000000;
      font-weight: bold;
    }
  }
`;

export const Tag = styled.span`
  background: #C4C4C4;
  border-radius: 8px;
  height: 16px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  padding: 2px 12px;
  &.approved {
    background: #219653;
  }
  &.rejected {
    background: #EB5757;
  }
`;

export const ChildrenSpan = styled.div`
  padding-left: 15%;
  font-size: 12px;
`;