import React from 'react';
import TopupPay from '../Topup-Pay-Form/TopupPay';

const Topup = () => {
    const PayContent = {
        Module: 'Topup',
        PageTitle: 'Make a Payment',
        ButtonTitle: 'Make Payment',
        SuccessPopupTitle: 'Policy Payment',
        SuccessPopupMessage: 'Policy payment has been done successfully!'
    }

    return (
        <TopupPay content={PayContent} />
    )
}

export default Topup;