import React from 'react';
import Routes from 'Routes';
import { GlobalStateProvider } from 'store';

// @ts-ignore
window.GroupPolicy = {};

const App = () => (
  <GlobalStateProvider>
    <div className="app">
      <Routes />
    </div>
  </GlobalStateProvider>
);

export default App;