import React, { useEffect, useState } from 'react';

import ClaimPage from 'Pages/Claims/components/ClaimPage';
import { apiRequest, useGlobalState } from 'store';
import { IClaim, IClaimItems } from 'store/actions/ClaimAction';
import { getPolicy, ILimits } from 'store/actions/PolicyAction';
import { ClaimsService } from 'services';

export interface IClaimList {
    ClaimRef: string;
    ClaimDate: string;
    Status: string;
}

export interface IClaimBenefit {
    riskId: string;
    claim: IClaim;
    benefits: Array<ILimits>;
}

const ClaimsContainer: React.FC = () => {
    const [{ policyId, data, fetching }] = useGlobalState('PolicyReducer');
    const [tableData, setTableData] = useState<Array<IClaimList>>([]);
    const [claimBenefit, setClaimBenefit] = useState<Record<string, IClaimBenefit>>({});
    const [claimModal, setClaimModal] = useState(false);
    const [claimRef, setClaimRef] = useState<string>('');

    useEffect(() => {
        policyId && apiRequest(getPolicy(policyId));
    }, [policyId])

    useEffect(() => {
        if (data && data.Risks) {
            const { claims, clist } = ClaimsService.getClaimsData(data.Risks);
            setClaimBenefit(clist);
            setTableData(claims);
        }
    }, [data])


    const viewClaimList = (ref: string) => {
        setClaimModal(true);
        setClaimRef(ref);
    }

    const handleViewModal = () => {
        setClaimModal(false);
    }

    const approveClaimItem = async (ClaimIndex: number, claim: IClaimItems) => {
        await ClaimsService.approveClaimItem(
            policyId,
            claimRef,
            claimBenefit[claimRef],
            claim,
            ClaimIndex);
    }

    const rejectClaimItem = async (ClaimIndex: number, claim: IClaimItems) => {
        await ClaimsService.rejectClaimItem(
            policyId,
            claimRef,
            claimBenefit[claimRef],
            claim,
            ClaimIndex);
    }

    const approveAll = async () => await ClaimsService.approveAll(policyId, claimRef, claimBenefit[claimRef]);

    const rejectAll = async () => await ClaimsService.rejectAll(policyId, claimRef, claimBenefit[claimRef]);

    return (
        <ClaimPage
            fetching={fetching}
            policy={data}
            claims={tableData}
            claimBenefit={claimBenefit}
            claimRef={claimRef}
            claimModal={claimModal}
            handleViewModal={handleViewModal}
            viewClaimList={viewClaimList}
            rejectAll={rejectAll}
            approveAll={approveAll}
            approveClaimItem={approveClaimItem}
            rejectClaimItem={rejectClaimItem}
        />
    );
}

export default ClaimsContainer;