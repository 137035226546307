import React from "react";
import Moment from "moment";
import { Link } from "raviger";
import { useForm, FormContext } from "react-hook-form";

import { ModalContainer } from "Pages/Policy/style";
import { ButtonContainer, Button } from "common/style";
import { TextareaField } from "common/Field";
import Collapse, { CollapseContent } from "common/Collapse";
import { ITransaction } from "store/actions/TransactionAction";
import TransactionPosting from "Pages/Policy/components/TransactionTab/TransactionModal/TransactionPosting";
import TransactionParameter from "Pages/Policy/components/TransactionTab/TransactionModal/TransactionParameter";
import TransactionNotes from "Pages/Policy/components/TransactionTab/TransactionModal/TransactionNotes";

interface IProps {
  transaction: ITransaction;
  isVisible: boolean;
  fetching: boolean;
  onCancel: () => void;
  onOK: (data: Record<string, string>) => void;
  onUpdateStatus: (status: string) => void;
  onAddNote: (note: string) => void;
}

const TransactionListModal: React.FC<IProps> = ({
  transaction,
  isVisible,
  fetching,
  onOK,
  onCancel,
  onUpdateStatus,
  onAddNote,
}) => {
  const methods = useForm();

  const handleOnOK = () => {
    const formData = methods.getValues();
    onOK(formData);
  }

  const handleUpdateStatus = () => {
    onUpdateStatus("Cancelled");
  }

  const handleRollbackStatus = () => {
    onUpdateStatus("Rollback");
  }

  const handleAddNote = () => {
    const formData = methods.getValues();
    onAddNote(formData.TransactionNote);
  }

  const docKey = () => {
    const docs = JSON.parse(transaction.Documents[0])
    const key = Object.keys(docs.Documents)
    return key[0]
  }

  const docValues = () => {
    const docs = JSON.parse(transaction.Documents[0])
    const val = Object.values(docs.Documents)
    let v:any = val[0]
    return v
  }

  return (
    <ModalContainer
      onCancel={onCancel}
      maskClosable={false}
      width="80%"
      title="View/Adjust Transaction"
      visible={isVisible}
      footer={null}
    >
      <FormContext {...methods}>
        <Collapse title="Transaction" collapse={true}>
          <div style={{ padding: 24 }}>
            <div>
                <strong>Transaction Status: </strong>
                {transaction.Status}
            </div>
            <div>
              <strong>Transaction Reference: </strong>
              {transaction.Tranid}
            </div>
          
            <div>
              <strong>Function: </strong>
              {transaction.Funct}
            </div>
            <div>
              <strong>Transaction Date: </strong>
              {transaction.Funct === "init"
                ? transaction.Funct
                : Moment(transaction.Dte).format("D-MMM-YYYY")}
            </div>
          </div>
          { transaction.Funct === "NewPolicy" || transaction.Funct === "TransactionAdjustment" ?
            <ButtonContainer> </ButtonContainer>: 
            <ButtonContainer>
                <Button
                  loading={fetching}
                  onClick={handleRollbackStatus}
                  className="primary"
                  style={{ marginRight : 10 }}
                  disabled={transaction.Status === "Cancelled"}
                >
                  Rollback Transaction
                </Button>
                <Button
                  loading={fetching}
                  onClick={handleUpdateStatus}
                  className="cancel"
                  style={{ marginRight : 10 }}
                  disabled={transaction.Status === "Cancelled"}
                >
                  Cancel Transaction
                </Button>
              </ButtonContainer>
          }
        </Collapse>

        <Collapse title="Transaction Parameters" collapse={true}>
          <CollapseContent>
            <TransactionParameter
              fetching={fetching}
              func={transaction.Funct}
              parameters={transaction.Args}
            />
          </CollapseContent>
          {!["init"].includes(transaction.Funct) && (
            <ButtonContainer>
              <Button
                loading={fetching}
                onClick={handleOnOK}
                className="primary"
                style={{ marginRight: 10 }}
              >
                Adjust
              </Button>
              <Button
                loading={fetching}
                onClick={onCancel}
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
            </ButtonContainer>
          )}
        </Collapse>

        <Collapse title="Policy">
          <CollapseContent>
            <Button style={{ marginRight: 8 }}>Before</Button>
            <Button style={{ marginLeft: 8 }}>After</Button>
          </CollapseContent>
        </Collapse>

        <Collapse title="Documents">
          <CollapseContent>
            {transaction.Documents == null ?
              <div></div> :
              <div>
              <strong>{docKey()}: </strong>
              <Link
                href={docValues()}
                target="_blank"
              >
                <i className="zmdi zmdi-file-text zmdi-hc-2x" />
              </Link>
            </div>
          }
          </CollapseContent>
        </Collapse>

        <Collapse title="Notes">
          <CollapseContent>
            <TransactionNotes
                fetching={fetching}
                func={transaction.Funct}
                parameters={transaction.Notes}
              />
            <div>
              <strong>Add Note: </strong>
              <TextareaField name="TransactionNote" label="Note" />
            </div>
            <ButtonContainer>
              <Button
                loading={fetching}
                onClick={handleAddNote}
                className="primary"
                style={{ marginLeft: 8 }}
              >
                Add Note
            </Button>
          </ButtonContainer>
          </CollapseContent>
        </Collapse>

        <Collapse title="Transaction Postings">
          <CollapseContent>
            <TransactionPosting
              postings={transaction.Gltran}
              fetching={fetching}
            />
          </CollapseContent>
        </Collapse>
      </FormContext>
    </ModalContainer>
  );
};

export default TransactionListModal;
