import { message } from "antd";
import {
  getTransaction as getTransactionCall,
  adjustTransaction as adjustTransactionCall,
  ITransactionAdjust
} from "store/actions/TransactionAction";
import { apiRequest } from "store";

const getTransaction = async (policyId: string) => {
  try {
    await apiRequest(getTransactionCall(policyId));
  } catch (e) {
    message.error('An error has occured');
  }
};

const adjustTransaction = async (policyId: string, data: ITransactionAdjust) => {
  try {
    await apiRequest(adjustTransactionCall(policyId, data));
    message.success('Successfully adjusted transaction');
  } catch (e) {
    message.error('An error has occured');
  }
}

const TransactionService = {
  getTransaction,
  adjustTransaction
}

export default TransactionService;