import styled, { css } from 'styled-components';

const color = {
  Aqua: '#00CCDB',
  Aqua40: 'rgba(36, 196, 204, 0.4)'
};

export const ColorStyle = css`
  color: ${color.Aqua};
`;

export const Input = styled.input`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 44px;
  padding: 7px 18px;
  margin: 5px 0;
  color: #201A3D;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgba(32, 26, 61, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  outline: 0;

  ::placeholder {
    color: #cccccc;
  }
`;

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Login = styled.section`
  max-width: 520px;
  width: 100%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 32px 25px;
`;

export const Label = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
  display: inline-block;
  color: #201a3d;
`;


export const FormLinks = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  a {
    color: ${color.Aqua};
  }
`;

export const IconStyle = css`
  margin-top: 100px;
  margin-bottom: 70px;
`;

export const LoginButtonStyle = css`
  height: 48px;
  margin-top: 40px;
  margin-bottom: 15px;
  background-color: ${color.Aqua};
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  border: 0;

  &:hover {
    background-color: ${color.Aqua};
  }

  :disabled,
  :disabled:hover {
    background-color: ${color.Aqua40};
    color: #fff;
  }
`;
