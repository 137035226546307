import { BreadcrumbProps } from '../Components/interfaces';
import { accProps, Monthlist, monthListProp, subObj } from './interfaces';

export const CreateNewPeriods: BreadcrumbProps[] = [
    { title: "Policy 360", redirectTo: "" },
    { title: "Finance", redirectTo: "" },
    { title: "Accounting Period", redirectTo: "/AllPeriod" },
    { title: "Create New Account", redirectTo: "/CreateNePeriod" }
];

export const AllAPBreadcrumbItems: BreadcrumbProps[] = [
    { title: "Policy 360", redirectTo: "" },
    { title: "Finance", redirectTo: "" },
    { title: "Accounting Period", redirectTo: "/AllPeriod" },
    { title: "All Accounting Periods", redirectTo: "/AllPeriod" }
];


export const parseYearDate = (date: Date) => {
    const DateToParse = date || new Date();
    console.log("year", DateToParse.getFullYear())
    return `${DateToParse.getFullYear()}`
}

const todayYear = () => {
    let todayYear = (new Date()).getFullYear();
    // console.log("this is year today", todayYear);
    return todayYear;
}

// export const sampleProps: accProps = {
//     FinYear:todayYear(),
//     Period: "",
//     Data: "",
//     FromDate: "",
//     ToDate: "",
//     Status: ""
// }
export const newSubObj : subObj ={
    Period: 0,
    Description:"",
    FromDate: "",
    ToDate: "",
    Status: "",
}
export const newObj: accProps = {
    FinYear: todayYear() ,
    FinPeriod: "",
    Data: [],
    ShowHideChild:false,
}
export const MonthList: monthListProp[] = [
    {
        name: 'January',
        value: '01',
        disabled: false
    },
    {
        name: 'February',
        value: '02',
        disabled: false
    },
    {
        name: 'March',
        value: '03',
        disabled: false
    },
    {
        name: 'April',
        value: '04',
        disabled: false
    },
    {
        name: 'May',
        value: '05',
        disabled: false
    },
    {
        name: 'June',
        value: '06',
        disabled: false
    },
    {
        name: 'July',
        value: '07',
        disabled: false
    },
    {
        name: 'August',
        value: '08',
        disabled: false
    },
    {
        name: 'September',
        value: '09',
        disabled: false
    },
    {
        name: 'October',
        value: '10',
        disabled: false
    },
    {
        name: 'November',
        value: '11',
        disabled: false
    },
    {
        name: 'December',
        value: '12',
        disabled: false
    },
]