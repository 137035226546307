import React, { useState } from 'react';
import { navigate } from 'raviger';
import { useForm } from 'react-hook-form';
import { Button } from 'antd';
import styled from 'styled-components';

import Loader from './Loader';
import PublicLayout from 'layout/PublicLayout';
import 'Pages/Public/Login.css';
import { getInitialData } from 'Pages/Receipt/preparePayload';

import {
  Label,
  Input,
  FormLinks,
  LoginButtonStyle
} from 'Pages/Public/style';

const LoginButton = styled(Button) `
  ${LoginButtonStyle}
`;

const LoginPage = () => {
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange'
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submit = () => {
    getRData();
    navigate('/dashboard');
  };

  const getRData = async () => {
    try {
      const initData = getInitialData();
      await fetch(`https://excel.sonic.dev.coherent.com.hk/api/v1/product/ThaiProtect/engines/Execute/Finance`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'SecretKey': '762f5692-5a8b-4f8e-a09e-a27a277c0c56'
        },
        body: JSON.stringify(initData)
      })
        .then((response: any) => response.json())
        .then((response: any) => {
          const glForPayeeTypes: string[] = [];
          const glForDepositBy: string[] = [];
          const glForDepositType: string[] = [];
          const glForDepositRefTypes: string[] = [];
          const glForReceiptStatusList: string[] = [];
          response.data.Outputs["Lists.GeneralLists"].map((gl: any) => {
            if (gl.PAYEE_TYPE) {
              glForPayeeTypes.push(gl.PAYEE_TYPE);
            };
            if (gl.DEPOSIT_BY) {
              glForDepositBy.push(gl.DEPOSIT_BY);
            };
            if (gl.DEPOSIT_TYPE) {
              glForDepositType.push(gl.DEPOSIT_TYPE);
            };
            if (gl.DEPOSIT_REF_TYPE) {
              glForDepositRefTypes.push(gl.DEPOSIT_REF_TYPE);
            };
            if (gl.STATUS) {
              glForReceiptStatusList.push(gl.STATUS);
            };
            return glForPayeeTypes;
          });
          const glForBankCode: object[] = [];
          const glForBankInfoCode: object[] = [];
          response.data.Outputs["Lists.BankBranch"].map((glb: any) => {
            // if (glb.BANK_CODE && !glForBankCode.includes(glb.BANK_CODE)) {
            //   glForBankCode.push(glb.BANK_CODE);
            // }
            if (glb.BANK_CODE && glb.BRANCH_CODE && glb.BRANCH_NAME_T) {
              glForBankInfoCode.push({
                BankCode: glb.BANK_CODE,
                BranchCode: glb.BRANCH_CODE,
                BranchName: glb.BRANCH_NAME_T
              });
            }
            return glForBankInfoCode;
          });
          response.data.Outputs["Lists.BankCodes"].map((glb: any) => {
            if (glb.BANK_CODE && glb.INST_NM_E && glb.INST_NM_T) {
              glForBankCode.push({
                BankCode: glb.BANK_CODE,
                BankName_E: glb.INST_NM_E,
                BankName_T:  glb.INST_NM_T
              });
            }
            return glForBankCode;
          });
          const GlData = {
            glForPayeeTypes,
            glForDepositBy,
            glForDepositType,
            glForDepositRefTypes,
            glForReceiptStatusList,
            glForBankCode,
            glForBankInfoCode
          };
          localStorage.setItem('GlData', JSON.stringify(GlData));
        });
    } catch (exception) {
      console.log(exception);
      setIsLoading(false);
    }
  }

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    if (name === 'username') {
      let loggedInUser = value;
      if (value.includes('.')) {
        loggedInUser = value.substr(0, value.indexOf('.'));
      } else if (value.includes('@')) {
        loggedInUser = value.substr(0, value.indexOf('@'));
      }
      // @ts-ignore
      window.loggedIn = loggedInUser;
    }
  }

  const loginForm = (
    <>
      <Label>Email / Username</Label>
      <Input
        name="username"
        ref={register({ required: true })}
        placeholder="Enter your email"
        onChange={(e) => handleChange(e)}
      />
      <Label>Password</Label>
      <Input
        type="password"
        name="password"
        placeholder="Enter your password"
        ref={register({ required: true })}
        onChange={(e) => handleChange(e)}
      />
      <FormLinks>
        <a href="google.com">Forgot Password?</a>
      </FormLinks>
      <LoginButton
        block
        name="button"
        type="primary"
        htmlType="submit"
        disabled={!formState.isValid || formState.isSubmitting}
      >
        Log In
      </LoginButton>
    </>
  );

  return (
    <div id="login-container">
      <PublicLayout>
        {
          isLoading && (
            <Loader />
          )
        }

        <form onSubmit={handleSubmit(submit)}>
          {loginForm}
        </form>
      </PublicLayout>
      {
        isLoading && (
          <div id="myModal" className="finance-modal">
            <div className="loader-content">
              <svg width="100" height="100" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#24c4cc" aria-label="audio-loading">
                <g fill="none" fillRule="evenodd" strokeWidth="2">
                  <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                  </animate>
                    <animate attributeName="strokeOpacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                    </animate>
                  </circle>
                  <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                  </animate>
                    <animate attributeName="strokeOpacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                    </animate>
                  </circle>
                </g>
              </svg>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default LoginPage;
