import React from "react";
import Moment from "moment";
import { Tooltip } from "antd";

import { TableContainer } from "Pages/Policy/style";
import { ITransactionTab } from "Pages/Policy/components/TransactionTab/TransactionTabContainer";
import TransactionListModal from "Pages/Policy/components/TransactionTab/TransactionModal";
import { ITransaction } from "store/actions/TransactionAction";
import find from "lodash/find";

export interface ITransactionProps extends Pick<ITransactionTab, "transactions" | "fetching"> {
  transaction?: ITransaction;
  transactionModal: boolean;
  handleViewModal: () => void;
  handleAdjust: (data: Record<string, string>) => void;
  handleUpdateStatus: (status: string) => void;
  handleAddNote: (note: string) => void;
  viewTransaction: (trans?: ITransaction) => void;
}

const TransactionList: React.FC<ITransactionProps> = ({
  transaction,
  transactions,
  transactionModal,
  handleViewModal,
  handleAdjust, 
  handleUpdateStatus,
  handleAddNote,
  viewTransaction,
  fetching,
}) => {
  const columns = [
    {
      title: "Transaction",
      dataIndex: "Funct",
      key: "Funct",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Date",
      dataIndex: "Dte",
      key: "Dte",
      render: (date: string, record: any) => record.Funct === 'init' ? record.Funct :  Moment(date).format("D-MMM-YYYY"),
    },
    {
      title: "Value",
      dataIndex: "Tvalue",
      key: "Tvalue",
      render: (tValue: number) =>
        new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(
          tValue || 0
        ),
    },
    {
      title: "Actions",
      dataIndex: "Tranid",
      key: "Actions",
      render: (Tranid: string) => (
        <>
          <Tooltip title="View">
            <i className="zmdi zmdi-edit" onClick={() => viewTransaction(find(transactions, { Tranid }))} />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <TableContainer
        columns={columns}
        rowKey="Tranid"
        pagination={false}
        loading={fetching}
        dataSource={transactions}
      />
      {transaction && (
        <TransactionListModal
          fetching={fetching}
          transaction={transaction}
          isVisible={transactionModal}
          onOK={handleAdjust}
          onCancel={handleViewModal}
          onUpdateStatus={handleUpdateStatus}
          onAddNote={handleAddNote}
        />
      )}
    </>
  );
};

export default TransactionList;
