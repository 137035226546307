import React, { useCallback, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import Moment from 'moment';

import Field from 'common/Field';
import { IPolicy } from 'store/actions/PolicyAction';
import { CardContainer } from 'common/style';

interface IProps {
    data?: IPolicy;
}

const InsuredDetails: React.FC<IProps> = ({ data }) => {
    const methods = useForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setValue = useCallback(methods.setValue, []);

    useEffect(() => {
        if (data) {
            setValue([
                { Name: data.Owner.Name },
                { Age: Moment().diff(data.Owner.Dob, 'years', false) },
                { ICNumber: data.Owner.Id },
                { Gender: data.Owner.Gender },
                { Dob: data.Owner.Dob },
                { Phone: data.Owner.Contact.Phone },
                { Email: data.Owner.Contact.Email }
            ])
        }
    }, [data, setValue])

    return (
        <FormContext {...methods}>
            <CardContainer>
                <Field name="Name" label="Name" readonly={true} />
                <Field name="Age" label="Age" readonly={true} />
                <Field name="ICNumber" label="IC Number" readonly={true} />
                <Field name="Gender" label="Gender" readonly={true} />
                <Field name="Dob" label="Birthday" readonly={true} />
                <Field name="Phone" label="Phone" required={true} />
                <Field name="Email" label="Email" required={true} />
            </CardContainer>
        </FormContext>
    )
}

export default InsuredDetails;