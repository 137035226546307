interface IApi<T, F> {
  get: (url: string) => F;
  post: (url: string, data: T) => F;
}

const host = `http://localhost:8087/api`;

const api: IApi<Object, Promise<any>> = {
  get: async url => {
    try {
      const response = await fetch(`${host}/${url}`);
      return await response.json();
    } catch (e) {
      return undefined;
    }
  },
  post: async (url, data) => {
    try {
      const response = await fetch(`${host}/${url}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.status >= 400) {
        throw new Error(response.statusText)
      } else {
        return await response.json();
      }
    } catch (e) {
      throw e;
    }
  }
};

export default api;
