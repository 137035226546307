import React, { useEffect, useState } from 'react';
import endPoint from 'config/urls';
import { Table } from 'antd';
// import { Pagination } from 'antd';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import { eventProps, accountProps, searchAccountProps } from '../interfaces';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import { AllAccountsBreadcrumbItems, formData, allAccountsTableColumns } from '../AccountController';
import './AllAccounts.styles.scss';
import { navigate } from 'raviger';

const AllAccounts = () => {

    const [searchData, setSearchData] = useState<searchAccountProps>(formData);
    const [accountData, setAccountData] = useState<accountProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleUpdate = (e: eventProps) => {
        const { name, value } = e.target;
        const newFromData = { ...searchData };
        // @ts-ignore
        newFromData[name] = value;
        setSearchData(newFromData);
    }
    useEffect(() => {
        allAccountsTableColumns.map(column => {
            column["onCell"] =  (record, rowIndex: number) => {
                return {
                    onClick: (ev: eventProps) => {
                        console.log(record, rowIndex);
                        gotoViewAccount(record.AccountID);
                    },
                };
            }
        });
        searchAccount("");
    }, [])

    const getAccountList = () => {
        // let searchStr = `?search_by=AccountID&value=${searchData.search_value}&limit=1&order_by=AccountID_DESC`;
        let searchStr = "?search_by=";

       if (searchData.search_value) {
            searchStr += `${searchData.search_by}&value=${searchData.search_value}`;
        } 
        console.log("this is searchstr", searchStr);
        searchAccount(searchStr);
    }
    const searchAccount = (searchStr:any) => {

        allAccountsTableColumns.map(column => {
            column["onCell"] =  (record, rowIndex: number) => {
                return {
                    onClick: (ev: eventProps) => {
                        console.log(record, rowIndex);
                        gotoViewAccount(record.AccountID);
                    },
                };
            }
        });

        const startUrl = `${endPoint.getAccount}`;
        // let searchStr = `?search_by=AccountID&value=${searchData.search_value}&limit=1&order_by=AccountID_DESC`;
        
            setIsLoading(true);
            try {
                fetch(`${startUrl}${searchStr}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => {
                        const statusCode = response.status;
                        const data = response.json();
                        return Promise.all([statusCode, data]);
                    })
                    .then(([statusCode, data]) => {
                        setIsLoading(false);
                        if (statusCode === 200 && data.length > 0) {
                            data.forEach((acc: accountProps) => {
                                acc.ActiveFrom = acc.ActiveFrom.split("T")[0];
                                acc.ActiveTo = acc.ActiveTo.split("T")[0];
                            });
                            setAccountData(data);
                        }
                    });
            } catch (exception) {
                setIsLoading(false);
                console.log(exception);
            }
        
    }

    const clearFilter = () => {
        setSearchData(formData);
        setAccountData([]);
    }

    const gotoCreateAccount = () => {
        navigate('/CreateNewAccount');
    }

    const gotoViewAccount = (accountID: string) => {
        navigate(`/ViewAccounts/${accountID}`);
    }

    return (
        <div id="all-account-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <Breadcrumbs paths={AllAccountsBreadcrumbItems} />
                    <div className="m-ap-btn plush" onClick={gotoCreateAccount}>Create New</div>
                </div>

                <div className="m-ap-search m-ap-m-b-15">
                    <div className="m-ap-row">
                        <div className="m-ap-input-f m-ap-dropdown">
                            <select name="search_by" id="search_by"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.search_by}>
                                <option value="">Search By</option>
                                <option value="AccountID">Account ID</option>
                                <option value="AccountName">Account Name</option>
                                <option value="JournalId">Journal ID</option>
                            </select>
                        </div>
                        <div className="m-ap-input-f m-ap-search-f">
                            <input type="text" placeholder="Begin your search"
                                id="search_value" name="search_value"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.search_value}
                            />
                        </div>
                        <div className="m-ap-input-f m-ap-flex m-ap-action">
                            <div className="m-ap-btn m-ap-search m-ap-blue" id="receipt-search" onClick={getAccountList}>Search</div>
                            <div className="m-ap-btn" id="clear-filter" onClick={clearFilter}>Clear Filters</div>
                        </div>
                    </div>
                </div>
                <div className="m-ap-filter m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="m-ap-title">All Accounts ({accountData.length})</div>
                    
                    {/* <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                        <div className="m-ap-filter-text">Filter by : Account Type</div>
                        <div className="m-ap-input-f">
                            <select>
                                <option>All</option>
                                <option>Option text 1</option>
                                <option>Option text 2</option>
                                <option>Option text 3</option>
                            </select>
                        </div>
                    </div> */}
                </div>

                <div className="m-ap-table-box">
                    <div className="m-ap-table">

                    <Table
                                columns={allAccountsTableColumns}
                                dataSource={accountData}
                                rowKey={record => record.AccountID}
                                
                            />
                    </div>
                    {/* <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                        <Pagination defaultCurrent={1} total={50} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default AllAccounts;