import React, { useState } from 'react';
import { CollapseContainer } from './style';
import CollapseHeader from './CollapseHeader';
import CollapseContent from './CollapseContent';
import CollapseFooter from './CollapseFooter';

interface IProps {
    title: string;
    collapse?: boolean;
    extra?: any;
}

const Collapse: React.FC<IProps> = (props) => {
    const [show, setShow] = useState(!!props.collapse);

    const handleToggle = (show: boolean) => setShow(show);

    return (
        <CollapseContainer>
            <CollapseHeader
                isUp={show}
                title={props.title}
                extra={props.extra}
                onToggle={handleToggle} />
            {show && props.children}
        </CollapseContainer>
    )
}

export {
    CollapseHeader,
    CollapseContent,
    CollapseFooter
}

export default Collapse;