import { applyMiddleware } from 'redux';
import reduxLogger from 'redux-logger';
import reduxThunk from 'redux-thunk';
import { createStore } from 'react-hooks-global-state';

import GlobalReducers, { globalStates, IAction } from 'store/reducers';
import {
  TYPE_FETCHING,
  TYPE_FETCHED,
  TYPE_ERROR
} from 'store/actions/ActionTypes';

//@ts-ignore
const { GlobalStateProvider, dispatch, useGlobalState } = createStore(
  GlobalReducers,
  globalStates,
  applyMiddleware(reduxLogger, reduxThunk)
);

const apiRequest = (action: IAction): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    if (!action.payload) {
      dispatch({ type: action.type });
    } else {
      // Set type to pending
      await dispatch({
        type: action.type.concat(TYPE_FETCHING),
        payload: action.payload
      });

      try {
        // Wait request to finish
        const response = await action.payload;
        
        // Add type success
        dispatch({
          type: action.type.concat(TYPE_FETCHED),
          payload: response
        });

        resolve(response);
      } catch (error) {
        // Add type failed
        await dispatch({
          type: action.type.concat(TYPE_ERROR),
          payload: error
        });

        reject(error);
      }
    }
  });
};

export { GlobalStateProvider, useGlobalState, dispatch, apiRequest };
