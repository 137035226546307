import React from 'react';
import { useFormContext } from 'react-hook-form';

import { IFieldProps } from 'common/Field';
import { Textarea } from 'common/style';

const TextareaField: React.FC<IFieldProps> = (props) => {
    const { register } = useFormContext();

    return (
        <Textarea
            name={props.name}
            readOnly={props.readonly}
            ref={register({ required: props.required ? true : false })} >
        </Textarea>
    );
}

export default TextareaField;