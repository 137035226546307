import React, { useState } from 'react';
import {
    eventProps, loanFormProps
} from '../interfaces';
import { sampleLoanFormData, getLoanPayload } from '../ProductsController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Loan.styles.scss';


const Loan = () => {

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [paymentFormData, setPaymentFormData] = useState<loanFormProps>(sampleLoanFormData);

    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const newAccData = { ...paymentFormData };
        newAccData[name] = value;
        setPaymentFormData(newAccData);
    }

    const SubmitAccount = () => {
        const loanFormData = getLoanPayload(paymentFormData);
        const url = 'https://newbusiness.demo.sonic.dev.coherent.global/v1/ul/newloan';

        fetch(url, {
            method: "POST",
            body: JSON.stringify(loanFormData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZ3JvdXBzIjpbImFkbWluIiwiaGVhbHRoIl0sImlhdCI6MTUxNjIzOTAyMn0.CMEQlrAI3_MbWZJ1RF0qtvoZi6ZOHKduY3zyIXn8N-o'
            }
        })
        .then((response) => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(([statusCode, data]) => {
            if (statusCode === 200 && data.message === 'Success') {                
                setShowSuccessModal(true);
            } else {
                alert(`Error ${statusCode} - ${data.error}`)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const closeModal = () => {
        setShowSuccessModal(false);
    }

    return (
        <div id="create-new-account-container">
            <div className="m-ap-wrap">
                <div className="m-ap-title">Apply for a Loan</div>
                <div className={`m-ap-accordion m-ap-m-b-15 m-ap-active`}>
                    <div className="m-ap-ac-content pt-4">
                        <div className="m-ap-form">
                            <div className="m-ap-row">
                                <div className="m-ap-col-3 m-ap-input-f mb-4 ">
                                    <label className="m-ap-label">Policy Number</label>
                                    <input type="text" placeholder="eg. ILP-22-000085"
                                        name="PolicyId" id="PolicyId" value={paymentFormData.PolicyId}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>
                                <div className="m-ap-col-3 m-ap-input-f mb-4 ">
                                    <label className="m-ap-label">Loan Amount</label>
                                    <input type="text" placeholder="E"
                                        name="Amount" id="Amount" value={paymentFormData.Amount}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Repayment Months</label>
                                    <input type="text" placeholder="Eg. 12"
                                        name="Months" id="Months" value={paymentFormData.Months}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div className="m-ap-bottom">
                <button id="create-submit" onClick={SubmitAccount}>Apply</button>
                <button id="cancel">Cancel</button>
            </div>

            {
                showSuccessModal && (
                    <div className="modal1" id="myModal">
                        <div className="modal-content1">
                            <div className="col-md-12 mb-2 download-receipt-title1">
                                <span>
                                    Loan
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    Policy loan has been applied successfully!
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt-close" onClick={closeModal}>Close</button>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                )
            }
        </div >
    )
}

export default Loan;