import get from 'lodash/get';
// import forEach from 'lodash/forEach';

import { IFetch, InitializeState } from './index';
import { TYPE_FETCHED } from 'store/actions/ActionTypes';
import {
    IPolicy,
    IPolicyList,
    IAdjustedQuote,
    PolicyActionType,
    POLICY_GET_QUOTE,
    POLICY_NEW_POLICY,
    POLICY_GET_POLICY,
    POLICY_GET_BY_PHONE,
    POLICY_ADJUST_QUOTE,
    POLICY_SET_PHONE,
    POLICY_SET_POLICY,
    POLICY_CLEAR_DATA
} from 'store/actions/PolicyAction';
import { getLocalData, addLocalData } from 'lib/localStorage';

interface IState extends IFetch {
    list: Array<IPolicyList>;
    data: IPolicy | undefined;
    adjustedQuote: IAdjustedQuote;
    policyId: string | null;
    searchPhone: string | null;
    total: number;
}

export const PolicyInitialState: IState = {
    data: undefined,
    list: [],
    total: 0,
    policyId: getLocalData('policyId'),
    searchPhone: getLocalData('phone'),
    adjustedQuote: {
        ModalPremiumDiff: 0,
        BilledPremiumDiff: 0,
        GstDiff: 0,
        CoinsureDiff: 0
    },
    fetching: false,
    fetched: false,
    error: null,
};

export type PolicyType = typeof PolicyInitialState;

const PolicyActionsReducer = (
    state: PolicyType = PolicyInitialState,
    action: PolicyActionType,
): IState => {
    state = InitializeState(state, action, PolicyInitialState);

    switch (action.type) {

        case POLICY_GET_QUOTE.concat(TYPE_FETCHED): {
            const response = get(action, 'payload.Result.Message');
            return {
                ...state,
                data: JSON.parse(response)
            };
        }

        case POLICY_GET_POLICY.concat(TYPE_FETCHED): {
            const response = get(action, 'payload.Result.Message');
            const data = JSON.parse(response);
            addLocalData('policyId', data.ContID);
            return {
                ...state,
                data,
                policyId: data.ContID
            };
        }

        case POLICY_NEW_POLICY.concat(TYPE_FETCHED): {
            const policyId = get(action, 'payload.Result.Message', '');
            addLocalData('policyId', policyId);
            return {
                ...state,
                policyId
            };
        }

        case POLICY_GET_BY_PHONE.concat(TYPE_FETCHED): {
            const list: Array<IPolicyList> = [];
            return {
                ...state,
                list,
                total: list.length
            };
            // const data = JSON.parse(get(action, 'payload.Result.Message', {}));

            // forEach(data, (policy) => list.push(policy));

            // return {
            //     ...state,
            //     list,
            //     total: list.length
            // };
        }

        case POLICY_ADJUST_QUOTE.concat(TYPE_FETCHED): {
            const adjustedQuote = JSON.parse(get(action, 'payload.Result.Message', {}));

            return {
                ...state,
                adjustedQuote
            }
        }
        
        case POLICY_SET_POLICY.concat(TYPE_FETCHED): {
            const policyId = get(action, 'payload', null);
            addLocalData('policyId', policyId);
            return {
                ...state,
                policyId
            }
        }

        case POLICY_SET_PHONE.concat(TYPE_FETCHED): {
            const searchPhone = get(action, 'payload', null);
            addLocalData('phone', searchPhone);
            return {
                ...state,
                searchPhone
            }
        }

        case POLICY_CLEAR_DATA: {
            return {
                ...state,
                list: [],
                total: 0,
                data: undefined,
                adjustedQuote: {
                    ModalPremiumDiff: 0,
                    BilledPremiumDiff: 0,
                    GstDiff: 0,
                    CoinsureDiff: 0
                }
            }
        }

        default:
            return state;
    }
};

export default PolicyActionsReducer;
