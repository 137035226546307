import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
import './UnderwritingDetails.styles.scss';

import { apiRequest, useGlobalState } from 'store';
import { setSparkToken, setUWFormData } from 'store/actions/MotorRiskAction';

import { ReactComponent as BackArrow } from 'assets/images/backArrow.svg';
import { uwFormProps } from '../interfaces';
import { getToken, premiumCalcPayload, sampleUWData1 } from '../MotorController';
import endPoint from 'config/urls';

const UnderwritingDetails = () => {
    const [{ AgentFormData,
        OwnerFormData,
        VehicleFormData,
        DriverFormData,
        PlanFormData,
        BenefitFormData,
        UWFormData,
        SparkData
    }] = useGlobalState('MotorRiskReducer');

    const [uwData, setUwData] = useState<uwFormProps>(UWFormData);
    // const [premiumTot, setPremiumTot] = useState<uwFormProps>(UWFormData);

    const handleUpdate = (ques: string, value: string) => {
        const newUwData = { ...uwData };
        newUwData[ques] = value;
        setUwData(newUwData);
        apiRequest(setUWFormData(newUwData)); // set UW form data in reducer
    }
    // useEffect(() => {
    //     let AuthorizationToken = getToken(SparkData);
    //     if (!AuthorizationToken) {
    //         fetchTokenData().then((response) => {
    //             calcPremium(response);
    //         });
    //     } else {
    //         calcPremium(AuthorizationToken);
    //     }
    // }, [SparkData]);

    const fetchTokenData = async () => {
        const url = `${endPoint.keycloakURL}`;

        const tokenData = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': "application/x-www-form-urlencoded",
            }
        }).then((response) => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    apiRequest(setSparkToken({
                        access_token: data.access_token,
                        expires_in: data.expires_in,
                        timestamp: new Date()
                    }));
                    return data.access_token;
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return tokenData;
    }
    const calcPremium = async (AuthorizationToken: string) => {
        const url = `${endPoint.motorExcelEngine}`;
        let date = new Date();
        const TransDate = date.toISOString();
        const pfPayload = premiumCalcPayload({
            AgentFormData,
            OwnerFormData,
            VehicleFormData,
            DriverFormData,
            PlanFormData,
            BenefitFormData,
            UWFormData,
        });
        return await fetch(url, {
            method: "POST",
            body: JSON.stringify(pfPayload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthorizationToken}`
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    const NewBusinessUI2Arr = data.data.Outputs.NewBusinessUI2;
                    console.log("this is options array", NewBusinessUI2Arr);
                    const newPremiumData = { ...uwData }
                    newPremiumData.NewBusinessUI2 = NewBusinessUI2Arr;
                    console.log("this is new primium", newPremiumData);
                    // setPremiumTot(newPremiumData);
                    setUwData(newPremiumData);
                    apiRequest(setUWFormData(newPremiumData)); // set plan data in reducer
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const goToCheckoutDetails = () => {
        let AuthorizationToken = getToken(SparkData);
        if (!AuthorizationToken) {
            fetchTokenData().then((response) => {
                calcPremium(response);
            });
        } else {
            calcPremium(AuthorizationToken);
        }
        navigate('/CheckoutDetails');
    }
    const goToBenefitDetails = () => {
        navigate('/BenefitDetails');
    }

    console.log("this is from UW details", UWFormData);

    return (
        <div id="underwriting-container">
            <div className='container'>
                <div className='d-flex justify-contents-start'>
                    <BackArrow className='center' onClick={goToBenefitDetails} />
                    <h3 className='m-4'>Complete Your Application</h3>
                </div>
                <div className='container'>
                    <div className='benefit-container'>
                        <div className='color-style'>
                            &nbsp;
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container'>

                            <div className='d-block justify-content-center m-5 benefit-table'>

                                <div className='w-100 m-3 d-flex justify-content-between'>
                                    <div className='w-70'>
                                        <p><b>Have you ever had any prior claims?</b></p>
                                    </div>
                                    <div className='w-30'>
                                        <div className="" id="underwriting-question-container1">
                                            <div className="row d-flex" id="question-container1">
                                                <input type="hidden" id="underwriting_ans1" name="underwriting_ans1" value="" />
                                                <div className={`ans-btn p-2 ${uwData.ques1 === 'Y' ? 'element-active' : ''}`} id="underwriting-yes-el1"
                                                    onClick={() => handleUpdate('ques1', 'Y')}>
                                                    Yes
                                                </div>
                                                <div className={`ans-btn ml-2 p-2 ${uwData.ques1 === 'N' ? 'element-active' : ''}`} id="underwriting-no-el1"
                                                    onClick={() => handleUpdate('ques1', 'N')}>
                                                    No
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-100 m-3 d-flex justify-content-between'>
                                    <div className='w-70'>
                                        <p><b>Vehicle repairs at your preferred workshop</b></p>
                                    </div>
                                    <div className='w-30'>
                                        <div className="" id="underwriting-question-container2">
                                            <div className="row d-flex" id="question-container2">
                                                <input type="hidden" id="underwriting_ans2" name="underwriting_ans2" value="" />
                                                <div className={`ans-btn p-2 ${uwData.ques2 === 'Y' ? 'element-active' : ''}`} id="underwriting-yes-el2"
                                                    onClick={() => handleUpdate('ques2', 'Y')}>
                                                    Yes
                                                </div>
                                                <div className={`ans-btn ml-2 p-2 ${uwData.ques2 === 'N' ? 'element-active' : ''}`} id="underwriting-no-el2"
                                                    onClick={() => handleUpdate('ques2', 'N')}>
                                                    No
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group mt-4'>
                                <button type="button" className="btn next-style" onClick={goToCheckoutDetails}>Next: Checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnderwritingDetails;