import React from 'react';
import { useFormContext } from 'react-hook-form';

import { IFieldProps } from 'common/Field';
import { Input } from 'common/style';

const InputField: React.FC<IFieldProps> = (props) => {
    const { register } = useFormContext();

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(e.target.value)
    }

    return (
        <Input
            type={props.type || 'text'}
            id={props.name}
            defaultChecked={props.defaultChecked}
            name={props.name}
            defaultValue={props.value}
            placeholder={props.placeholder || props.label}
            readOnly={props.readonly}
            style={props.style}
            onChange={handleOnChange}
            ref={register({ required: props.required ? true : false })}
        />
    );
}

export default InputField;