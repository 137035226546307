import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import { createLedgerProps, eventProps, financialPeriodsListProps, ledgerProps } from '../interfaces';
import { sampleLedger, CreateLedgerBreadcrumbItems } from '../LedgerController';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateLedger.styles.scss';
import endPoint from 'config/urls';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';

const CreateLedger = () => {

    let formDataOne: createLedgerProps = {
        LedgerID: "",
        ActiveFrom: "",
        ActiveTo: "",
        Type: "",
        Tag1: "",
        Tag2: "",
        Description: "",
        Status: "",
        FinancialYear: "",
        FinancialPeriod: ""
    };

    // let formDataTwo: createLedgersecondform = {
    //     Data: ""
    // };


    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEditFlow, setIsEditFlow] = useState<boolean>(false);
    const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);
    const [basicSectionActive, setBasicSectionActive] = useState<boolean>(true);
    const [ledgerFormOne, setLedgerFormOne] = useState<createLedgerProps>(formDataOne);
    const [transSectionActive, setTransSectionActive] = useState<boolean>(false);
    // const [ledgerFormTwo, setLedgerFormTwo] = useState<createLedgersecondform>(formDataTwo);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    // const [selectedLedger, setSelectedLedger] = useState<ledgerProps>(sampleLedger);
    const [ledgerNumber, setLedgerNumber] = useState<string>("");
    const [yearLength] = useState<number>(10);
    const [financialPeriodsList, setFinancialPeriodsList] = useState<financialPeriodsListProps[]>([]);
    const [minDate, setMinDate] = useState<string>("");
    const [maxDate, setMaxDate] = useState<string>("");

    /**
     * 
     * @param ledgerNumber: string - Ledger ID to fetch data while update
     * @returns void;
     */
    const getLedgerData = async (ledgerNumber: string) => {
        if (!ledgerNumber) {
            throw new Error('Ledger number not found');
            return false;
        }
        setIsLoading(true);
        try {
            await fetch(`${endPoint.getOneLedger}${ledgerNumber}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200) {
                        const formData = { ...data };
                        formData.ActiveFrom = data.ActiveFrom.split('T')[0];
                        formData.ActiveTo = data.ActiveTo.split('T')[0];
                        getFinancialPeriod(formData.FinancialYear); // to get Financial Period data of Ledger's year
                        setLedgerFormOne(formData); // sets form data
                        setTransSectionActive(true); // to keep open second section by default when edit
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    useEffect(() => {
        const urlPath = window.location.pathname.split('/');
        if (urlPath.length > 2) {
            const ledgerId = urlPath[2];
            setLedgerNumber(ledgerId);
            setIsEditFlow(true);
            getLedgerData(ledgerId);
            const BreadCrumbLength = CreateLedgerBreadcrumbItems.length;
            CreateLedgerBreadcrumbItems[BreadCrumbLength - 1].title = 'Update Ledger';
        } else {
            setIsEditEnabled(true);
        }
    }, [])

    const CurrentYear = (new Date()).getFullYear();
    const FinancialYearList = [];
    for (let a = 0; a < yearLength; a++) {
        FinancialYearList.push(CurrentYear - a);
    }

    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const headerFormValue = { ...ledgerFormOne };
        headerFormValue[name] = value;
        setLedgerFormOne(headerFormValue);
        if (name === 'FinancialYear') {
            getFinancialPeriod(value);
        } else if (name === 'FinancialPeriod') {
            setLadgerDates(value);
        }
    }

    const getFinancialPeriod = (year: string) => {
        const startUrl = `${endPoint.AccountingPeriod}/${year}/-1`;
        if (startUrl) {
            setIsLoading(true);
            try {
                fetch(`${startUrl}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => {
                        const statusCode = response.status;
                        const data = response.json();
                        return Promise.all([statusCode, data]);
                    })
                    .then(([statusCode, data]) => {
                        setIsLoading(false);
                        if (statusCode === 200) {
                            // console.log(data);
                            setFinancialPeriodsList(data);
                        } else {
                            console.log('Error occurred!')
                        }
                    });
            } catch (exception) {
                setIsLoading(false);
                console.log(exception);
            }
        } else {
            console.log('Search value is empty');
        }
    }

    const setLadgerDates = (Period: string) => {
        const selectedPeriod = financialPeriodsList.filter(p => p.Period === parseInt(Period));
        if (selectedPeriod.length > 0) {
            setMinDate(selectedPeriod[0].FromDate.split('T')[0]);
            setMaxDate(selectedPeriod[0].ToDate.split('T')[0]);
        }
    }

    const SubmitLedger = () => {
        const fromDate = ledgerFormOne.ActiveFrom + "T00:00:00Z";
        const toDate = ledgerFormOne.ActiveTo + "T00:00:00Z";
        const Ledger = {
            "LedgerID": ledgerFormOne.LedgerID,
            "ActiveFrom": fromDate,
            "ActiveTo": toDate,
            "Type": ledgerFormOne.Type.substring(0, 9),
            "Tag1": ledgerFormOne.Tag1,
            "Tag2": ledgerFormOne.Tag2,
            "Description": ledgerFormOne.Description,// Transaction List Array
            "Status": ledgerFormOne.Status,
            "FinancialYear": Number(ledgerFormOne.FinancialYear),
            "FinancialPeriod": Number(ledgerFormOne.FinancialPeriod)

        }
        const url = `${endPoint.createLedger}`;

        fetch(url, {
            method: (!isEditFlow ? "POST" : "PUT"),
            body: JSON.stringify(Ledger),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                console.log(data);
                if (statusCode === 200) {
                    setLedgerNumber(data.LedgerID);
                    setShowSuccessModal(true);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const copyLedgerNumber = () => {
        const text = ledgerNumber;
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }
    const closeModal = () => {
        setShowSuccessModal(false);
        gotoAllLedgers();
    }

    const gotoAllLedgers = () => {
        navigate('/AllLedger');
    }

    console.log('is edit flow', isEditFlow)
    return (
        <div id="create-ledger-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />

            <div className="m-ap-wrap">
                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <Breadcrumbs paths={CreateLedgerBreadcrumbItems} />
                </div>

                <div className="m-ap-title">
                    {isEditFlow ? `Modify Ledger: ${ledgerNumber}` : 'Create New Ledgher'}
                    {
                        (isEditFlow) && (
                            <span className='float-right edit-link' onClick={() => setIsEditEnabled(!isEditEnabled)}>
                                {(isEditEnabled) ? 'Cancel' : 'Edit'}
                            </span>)
                    }
                </div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${basicSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setBasicSectionActive(!basicSectionActive)}>
                        <div className="m-ap-sub-title">Basic Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        basicSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-form">
                                    <div className="m-ap-row">
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Ledger ID</label>
                                            <input type="text" placeholder="Enter Ledger ID" id="LedgerID" name="LedgerID"
                                                value={ledgerFormOne.LedgerID} onChange={(e) => handleUpdate(e)}
                                                readOnly={isEditFlow}/>
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Ledger Type</label>
                                            <select name="Type" id="Type" value={ledgerFormOne.Type} onChange={(e) => handleUpdate(e)}
                                                className={(!isEditEnabled) ? 'disabled' : ''} disabled={!isEditEnabled}>
                                                <option value="">---Select ---</option>
                                                <option value="AR">Account Receivable </option>
                                                <option value="AP">Account Payable</option>
                                                <option value="OR">Other Receivable </option>
                                                <option value="OP">Other Payable </option>
                                                <option value="Liability">Liability or Income </option>
                                                <option value="Expenses">Expenses</option>
                                            </select>
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Ledger Name</label>
                                            <textarea placeholder="Enter Note Here..." id="Description" name="Description"
                                                value={ledgerFormOne.Description} onChange={(e) => handleUpdate(e)}
                                                className={(!isEditEnabled) ? 'disabled' : ''} readOnly={!isEditEnabled} ></textarea>
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Select Financial Year</label>
                                            <select name="FinancialYear" id="FinancialYear"
                                                value={ledgerFormOne.FinancialYear} onChange={(e) => handleUpdate(e)}
                                                className={(!isEditEnabled) ? 'disabled' : ''} disabled={!isEditEnabled}>
                                                <option value="">Enter Financial Year e.g.2020</option>
                                                {
                                                    FinancialYearList.map((year) => (
                                                        <option key={year} value={year}>{year}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Financial Period</label>
                                            <select name="FinancialPeriod" id="FinancialPeriod"
                                                value={ledgerFormOne.FinancialPeriod} onChange={(e) => handleUpdate(e)}
                                                className={(!isEditEnabled) ? 'disabled' : ''} disabled={!isEditEnabled}>
                                                <option value="">Select Financial Period</option>
                                                {
                                                    financialPeriodsList.map((period) => (
                                                        <option key={period.Period} value={period.Period}>{`${period.Period} - ${(JSON.parse(period.Data)).Description}`}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Status</label>
                                            <select name="Status" id="Status" value={ledgerFormOne.Status} onChange={(e) => handleUpdate(e)}
                                                className={(!isEditEnabled) ? 'disabled' : ''} disabled={!isEditEnabled}>
                                                <option value="">---Select ---</option>
                                                <option value="Open">Open</option>
                                                <option value="Close">Close</option>
                                            </select>
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Active From</label>
                                            <input type="date"
                                                placeholder="YYYY/MM/DD"
                                                id="ActiveFrom"
                                                name="ActiveFrom"
                                                value={ledgerFormOne.ActiveFrom}
                                                onChange={(e) => handleUpdate(e)}
                                                min={minDate}
                                                max={maxDate}
                                                className={(!isEditEnabled) ? 'disabled' : ''} readOnly={!isEditEnabled}
                                            />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Active To</label>
                                            <input
                                                type="date"
                                                placeholder="YYYY/MM/DD"
                                                id="ActiveTo"
                                                name="ActiveTo"
                                                value={ledgerFormOne.ActiveTo}
                                                onChange={(e) => handleUpdate(e)}
                                                min={minDate}
                                                max={maxDate}
                                                className={(!isEditEnabled) ? 'disabled' : ''} readOnly={!isEditEnabled}
                                            />
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">TagRef1</label>
                                            <input type="text" placeholder="Enter Account ID" id="Tag1" name="Tag1"
                                                value={ledgerFormOne.Tag1} onChange={(e) => handleUpdate(e)}
                                                className={(!isEditEnabled) ? 'disabled' : ''} readOnly={!isEditEnabled} />
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">TagRef2</label>
                                            <input type="text" placeholder="Enter Account ID" id="Tag2" name="Tag2"
                                                value={ledgerFormOne.Tag2} onChange={(e) => handleUpdate(e)}
                                                className={(!isEditEnabled) ? 'disabled' : ''} readOnly={!isEditEnabled}
                                            />
                                        </div>
                                        {/* <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Ledger Name</label>
                                            <textarea placeholder="Enter Note Here..." id="ledger-name" name="ledger-name"></textarea>
                                        </div> */}


                                    </div>
                                </div>

                            </div>
                        )}

                </div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${transSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setTransSectionActive(!transSectionActive)}>
                        <div className="m-ap-sub-title" >Other Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        transSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="p-l-r-24">
                                    <div className="m-ap-col-12 m-ap-input-f">
                                        <label className="m-ap-label">Notes</label>
                                        <textarea placeholder="Enter Note Here..." id="Description" name="Description"
                                            value={ledgerFormOne.Description} onChange={(e) => handleUpdate(e)}
                                            className={(!isEditEnabled) ? 'disabled' : ''} readOnly={!isEditEnabled}></textarea>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>

            <div className="m-ap-bottom">
                {
                    ((isEditFlow === false) || (isEditFlow && isEditEnabled)) && (
                        <button id="create-submit" onClick={SubmitLedger} >{isEditFlow ? 'Update' : 'Create'} </button>
                    )
                }
                {/* <button id="save-draft">
                    {
                        isEditFlow ? 'Update Changes' : 'Save as Draft'
                    }
                </button> */}
                <button id="cancel" onClick={gotoAllLedgers}>Back</button>
            </div>

            {
                showSuccessModal && (
                    <div className="modal1" id="myModal">
                        <div className="modal-content1">
                            <div className="col-md-12 mb-2 download-receipt-title1">
                                <span>
                                    {
                                        isEditFlow ? 'Changes Updated' : 'Ledger Created'
                                    }
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    Ledger has been created successfully!
                                </span>
                                <br />
                                <span className="entity-number">{ledgerNumber}&nbsp;</span>
                                <span className="receipt-copy" onClick={copyLedgerNumber} >Copy</span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt-close" onClick={closeModal} >Close</button>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default CreateLedger;