import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useFormContext } from 'react-hook-form';

import { IFieldProps } from 'common/Field';
import { Dropdown } from 'common/style';

const { Option } = Select;

const SelectField: React.FC<IFieldProps> = (props) => {
    const [fieldValue, setFieldValue] = useState(props.value);
    const { register, setValue } = useFormContext();

    useEffect(() => {
        setFieldValue(props.value);
    }, [props.value]);

    return (
        <>
            <input
                type="text"
                name={props.name}
                style={{ display: 'none' }}
                defaultValue={props.value}
                ref={register({ required: props.required ? true : false })}
            />
            <Dropdown
                showSearch
                value={fieldValue}
                placeholder={props?.placeholder}
                optionFilterProp="children"
                onSelect={(value) => {
                    setValue(props.name, value);
                    setFieldValue(value as string);
                    props.onChange && props.onChange(value as string);
                }}
            >
                {props.options && props.options.map((opt, key) => {
                    const [value, label] = Object.entries(opt)[0];
                    return <Option value={value} key={key}>{label}</Option>
                })}
            </Dropdown>
        </>
    );
}

export default SelectField;