import { message } from "antd";
import { get, forEach } from 'lodash';
import Moment from 'moment';

import { apiRequest } from "store";
import { IClaimItems, updateClaim, IClaim } from "store/actions/ClaimAction";
import { getPolicy, IBasicRisk } from "store/actions/PolicyAction";
import { IClaimBenefit, IClaimList } from "Pages/Claims/ClaimsContainter";

const getClaimsData = (risks: Array<IBasicRisk>): { claims: Array<IClaimList>, clist: Record<string, IClaimBenefit> } => {
    let claims: Array<IClaimList> = [];
    let clist: Record<string, IClaimBenefit> = {};

    forEach(risks, (risk: IBasicRisk) => {
        const dClaims = get(risk, 'RiskBody.ClaimList', []);

        forEach(dClaims, (claim: IClaim) => {
            clist[claim.ClaimRef] = {
                riskId: risk.RiskId,
                claim,
                benefits: get(risk, 'RiskBody.Benefit_Limits', [])
            }
            claims.push({
                ClaimRef: claim.ClaimRef,
                ClaimDate: Moment(claim.ClaimDate).format('D-MMM-YYYY'),
                Status: claim.Status
            })
        })
    });

    return { claims, clist };
}

const approveAll = async (
    policyId: string,
    claimRef: string,
    benefit: IClaimBenefit
) => {
    const items = benefit.claim.ClaimItems.map((claim: IClaimItems, ClaimIndex: number) => ({
        RiskID: benefit.riskId,
        ClaimRef: claimRef,
        ClaimIndex,
        Claim: {
            ...claim,
            Status: 'Manual Approved'
        }
    }));

    if (items && items.length > 0) {
        try {
            await apiRequest(updateClaim(policyId, items));
            await apiRequest(getPolicy(policyId));
            message.success(`${claimRef} has been approved`);
        } catch (e) {
            message.error('An error has occured');
        }
    }
};

const rejectAll = async (
    policyId: string,
    claimRef: string,
    benefit: IClaimBenefit
) => {
    const items = benefit.claim?.ClaimItems.map((claim: IClaimItems, ClaimIndex: number) => ({
        RiskID: benefit.riskId,
        ClaimRef: claimRef,
        ClaimIndex,
        Claim: {
            ...claim,
            Status: 'Manual Rejected'
        }
    }));

    if (items && items.length > 0) {
        try {
            await apiRequest(updateClaim(policyId, items));
            await apiRequest(getPolicy(policyId));
            message.warn(`${claimRef} has been rejected`);
        } catch (e) {
            message.error('An error has occured');
        }
    }
};

const approveClaimItem = async (
    policyId: string,
    claimRef: string,
    benefit: IClaimBenefit,
    claim: IClaimItems,
    ClaimIndex: number
) => {
    try {
        await apiRequest(updateClaim(policyId, [{
            RiskID: benefit.riskId,
            ClaimRef: claimRef,
            ClaimIndex,
            Claim: {
                ...claim,
                Status: 'Manual Approved'
            }
        }]));
        await apiRequest(getPolicy(policyId));
        message.success(`${claim.Benefit} has been approved`);
    } catch (e) {
        message.error('An error has occured');
    }
};

const rejectClaimItem = async (
    policyId: string,
    claimRef: string,
    benefit: IClaimBenefit,
    claim: IClaimItems,
    ClaimIndex: number
) => {
    try {
        await apiRequest(updateClaim(policyId, [{
            RiskID: benefit.riskId,
            ClaimRef: claimRef,
            ClaimIndex,
            Claim: {
                ...claim,
                Status: 'Manual Rejected'
            }
        }]));
        await apiRequest(getPolicy(policyId));
        message.warn(`${claim.Benefit} has been rejected`);
    } catch (e) {
        message.error('An error has occured');
    }
};

const ClaimsService = {
    rejectAll,
    approveAll,
    approveClaimItem,
    rejectClaimItem,
    getClaimsData
}

export default ClaimsService;