import React, { useState } from 'react';
import { navigate } from 'raviger';
import './DriverDetails.styles.scss';
import { useGlobalState, apiRequest } from 'store';
import { setDriverFormData } from 'store/actions/MotorRiskAction';
import { eventProps, createDriverProps, driverValidationProps } from '../interfaces';
import InputMask from "react-input-mask";
import { ReactComponent as BackArrow } from 'assets/images/backArrow.svg';
import { validateDriver } from '../MotorController';


const DriverDetails = () => {
    const [{ DriverFormData }] = useGlobalState('MotorRiskReducer');

    const [driverData, setDriverData] = useState<createDriverProps>(DriverFormData);
    const [validationData, setValidationData] = useState<driverValidationProps>(validateDriver);


    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const newDriverData = { ...driverData };
        newDriverData[name] = value;
        setDriverData(newDriverData);
        apiRequest(setDriverFormData(newDriverData)); // set driver form data in reducer
    }
    const setMaxDate = () => {
        const newDate = new Date();
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${year}-${(month < 9) ? '0' + month : month}-${day}`;
    }
    const setMinDate = () => {
        const newDate = new Date();
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear() - 60;
        return `${year}-${(month < 9) ? '0' + month : month}-${day}`;
    }
    const validateDate = (value: string) => {
        let isValidDate = true;
        let inputDate = value;

        let minDate = setMinDate();
        let maxDate = setMaxDate();

        console.log("These are dates", inputDate, minDate, maxDate)
        const minDateJ = new Date(minDate);
        const maxDateJ = new Date(maxDate);
        const inputDateJ = new Date(inputDate);

        if (inputDateJ < minDateJ || inputDateJ > maxDateJ) {
            isValidDate = false;
        } else {
            isValidDate = true;
        }
        return isValidDate;
    }
    const validateInfo = () => {
        let isValid = true;
        const validate = { ...validationData }
        console.log("This is driver validation", validate);
        Object.keys(validate).forEach(fieldName => {
            if (validate[fieldName].isRequired && !driverData[fieldName]) {
                isValid = false;
                validate[fieldName].isValid = false
                validate[fieldName].errMsg = '';
            } else if ('checkFor' in validate[fieldName]) {
                if ('ageLimit' in validate[fieldName].checkFor && !validateDate(driverData[fieldName])) {
                    isValid = false;
                    validate[fieldName].isValid = false
                    validate[fieldName].errMsg = validate[fieldName].ageErrorMsg;
                } else if ('validDate' in validate[fieldName].checkFor  && !validateDate(driverData[fieldName]) ) {
                    isValid = false;
                    validate[fieldName].isValid = false;
                    validate[fieldName].errMsg = validate[fieldName].checkFor.validDateError;

                }else {
                        validate[fieldName].isValid = true;
                        validate[fieldName].errMsg = '';
                    }
                } else {
                    validate[fieldName].isValid = true;
                }

            });
        setValidationData(validate);

        if (!isValid) {
            return false;
        }
    }
    const goToPlanDetails = () => {
        navigate('/PlanDetails');
    }
    const goToVehicleDetails = () => {
        navigate('/VehicleDetails');
    }

    return (
        <div id="driver-details-container">
            <div className='container'>
                <div className='d-flex justify-contents-start'>
                    <BackArrow className='center' onClick={goToVehicleDetails} />
                    <h3 className='m-4'>Driver Details</h3>
                </div>
                <div className='container'>
                    <div className='driver-container'>
                        <div className='color-style'>
                            &nbsp;
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container padding-style'>
                            <form>
                                <div className='row'>
                                    <div className="form-group col-2">
                                        <label>Title</label>
                                        <select className="form-control" id="DriverTitle" name='DriverTitle'
                                            value={driverData.DriverTitle} onChange={(e) => handleUpdate(e)}>
                                            <option value="">Select</option>
                                            <option value="Mr.">Mr.</option>
                                            <option value="Ms.">Ms.</option>
                                            <option value="Mrs.">Mrs.</option>
                                        </select>

                                    </div>
                                    <div className="form-group col-4">
                                        <label>Name</label>
                                        <input type="text" className="form-control" id="DriverName" name='DriverName' placeholder="Enter Driver Name"
                                            value={driverData.DriverName} onChange={(e) => handleUpdate(e)} />
                                    </div>
                                    <div className="form-group col-3">
                                        <label>Surname</label>
                                        <input type="text" className="form-control" id="DriverSurname" name='DriverSurname' placeholder="Enter Driver Name"
                                            value={driverData.DriverSurname} onChange={(e) => handleUpdate(e)} />
                                    </div>
                                    <div className="form-group col-3">
                                        <label >DOB</label>
                                        <input type="date" className="form-control" id="DriverDOB" name='DriverDOB' min={setMinDate()} max={setMaxDate()} 
                                            value={driverData.DriverDOB} onChange={(e) => handleUpdate(e)} onBlur={validateInfo}/>
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.DriverDOB.isValid ? 'block' : 'none') }}>
                                            {validationData.DriverDOB.errMsg || ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="form-group col-3">
                                        <label >Contact Number</label>
                                        <input type="text" className="form-control" id="DriverPhone" name='DriverPhone' placeholder="Enter Phone Number"
                                            value={driverData.DriverPhone} onChange={(e) => handleUpdate(e)} />
                                    </div>
                                    <div className="form-group col-3">
                                        <label >ID Type</label>
                                        <select className="form-control" id="DriverIdType" name='DriverIdType'
                                            value={driverData.DriverIdType} onChange={(e) => handleUpdate(e)}>
                                            <option value="">Select</option>
                                            <option value="National ID">National ID</option>
                                            <option value="Passport">Passport</option>

                                        </select>
                                    </div>
                                    <div className="form-group col-3">
                                        <label >ID Number</label>
                                        {/* <input type="text" className="form-control" id="DriverIdNumber" name='DriverIdNumber' placeholder="Enter Phone Number"
                                            value={driverData.DriverIdNumber} onChange={(e) => handleUpdate(e)} /> */}
                                        <InputMask
                                            className="form-control"
                                            id="DriverIdNumber" name='DriverIdNumber'
                                            value={driverData.DriverIdNumber}
                                            placeholder="Enter ID Number"
                                            onChange={(e) => handleUpdate(e)}
                                            mask="99999999999"
                                        />
                                    </div>
                                    <div className="form-group col-3">
                                        <label >Gender</label>
                                        <select className="form-control" id="DriverGender" name='DriverGender'
                                            value={driverData.DriverGender} onChange={(e) => handleUpdate(e)}>
                                            <option value="">Select</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="form-group col-3">
                                        <label >Driver License Date</label>
                                        <input type="date" className="form-control" id="DriverLicenseDate" name='DriverLicenseDate'
                                            value={driverData.DriverLicenseDate} min={setMinDate()} max={setMaxDate()}  onChange={(e) => handleUpdate(e)} onBlur={validateInfo} />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.DriverLicenseDate.isValid ? 'block' : 'none') }}>
                                            {validationData.DriverLicenseDate.errMsg || ''}
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group mt-4'>
                                    <button type="button" className="btn next-style" onClick={goToPlanDetails}>Next: Plan Details</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default DriverDetails;