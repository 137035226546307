import styled from 'styled-components';
import { Button } from 'antd';

export const ClaimActions = styled.div`
    display: flex;
    i {
        margin: 0 10px;
        cursor: pointer;
        font-weight: 500;
        font-size: 21px;
        line-height: 23px;
        width: 40px;
        text-align: center;
        height: 40px;
        padding-top: 9px;
        &.active {
            background-color: #bbb;
            border-radius: 50%;
        }
    }
`

export const Attachments = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
        background: #C4C4C4;
        border-radius: 2px;
        width: 191.9px;
        height: 125.39px;
    }
`
export const Conversation = styled.div`
`

export const Message = styled.div`
    background-color: #DCDCDC;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 21px;
    display: inline-block;

    span {
        margin-bottom: 8px;
        display: inline-block;
        &.convo-sender {
            color: #727272;
        }
    }
`

export const ClaimItemButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ApproveItem = styled(Button)`
    border: 1px solid #32C4CB !important;
    background-color: #fff;
    color: #32C4CB !important;
    height: 40px;
    border-radius: 4px;
    padding: 10px 30px;
    outline: 0;
    cursor: pointer;
    font-weight: bold;
    :hover,
    :focus {
        background-color: #fff;
        color: white;
    }
    margin-left: 8px;
`

export const RejectItem = styled(Button)`
    border: 1px solid #A0A0A0!important;
    box-sizing: border-box;
    background-color: #fff;
    color: #A0A0A0 !important;
    height: 40px;
    border-radius: 4px;
    padding: 10px 30px;
    outline: 0;
    cursor: pointer;
    font-weight: bold;
    :hover,
    :focus {
        background-color: #fff;
        color: #A0A0A0;
    } 
    margin-right: 8px;
`