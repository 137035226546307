import React from 'react';
import Moment from 'moment';
import get from 'lodash/get';
import forEach from 'lodash/forEach';

import Collapse, { CollapseContent } from 'common/Collapse';
import { IPolicy, IOption } from 'store/actions/PolicyAction';

interface IProps {
    policy: IPolicy;
}

const planType: Record<string, string> = {
    Silver: 'Silver',
    Gold: 'Gold',
    Platinum: 'Platinum'
}

const ClaimPolicy: React.FC<IProps> = ({ policy }) => {
    const renderOptions = () => {
        let options: Array<string> = [];

        const benefits = get(policy, 'Risks[1].TravelBody.Options', []);
        benefits && forEach(benefits, (opt: IOption) => {
            switch (opt.Option) {
                case 'Emergency':
                    options.push('Trip cancellation or Postponement')
                    break;
                case 'Delay':
                    options.push('Trip cut short');
                    break;
                case 'Baggage':
                    options.push('Trip diversion (1000 for every six hours diverted overseas)');
                    break;
                case 'Personal':
                    options.push('Travel delay (1000 for every six hours of delay overseas and 1000 after six hours of delay in Thailand)');
                    break;
            }
        });

        return <ul>{options.map((opt, key) => <li key={key}>{opt}</li>)}</ul>;
    }

    return (
        <Collapse title="Policy Overview">
            <CollapseContent>
                <div><strong>Policy: </strong>{policy?.ContID}</div>
                <div><strong>Product: </strong>{policy?.Product}</div>
                <div><strong>Term: </strong>{policy?.Term}</div>
                <div><strong>Status: </strong>{policy?.Status}</div>
                <div><strong>Plan: </strong>{planType[get(policy, 'Risks[1].TravelBody.Plan')]}</div>
                <div>
                    <strong>Start Date: </strong>
                    {policy && Moment(policy?.StartDate).format('D-MMM-YYYY')}
                </div>
                <div>
                    <strong>End Date: </strong>{policy && Moment(policy?.EndDate).format('D-MMM-YYYY')}
                </div>
                <div><strong>Benefits:</strong>
                    {renderOptions()}
                </div>
                <div>
                    <strong>Premium: </strong>
                        ${new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2
                    }).format(get(policy, 'PremiumTotals.ModalPremium', 0))}
                </div>
            </CollapseContent>
        </Collapse>
    );
}

export default ClaimPolicy;