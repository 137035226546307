import React,{ useEffect, useState } from 'react';
import endPoint from 'config/urls';
import { Table } from 'antd';
import { Pagination } from 'antd';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import './UploadFile.styles.scss';
import { navigate } from 'raviger';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { react } from '@babel/types';
import { func } from 'prop-types';
import { result } from 'lodash';
import { uploadprops } from '../interface';
import { upreport } from '../UploadController';
import { info } from 'console';
// import AllUpload from '../AllUploads/AllUpload.component';
const UploadFile = () => {
    interface eventList {
        name: string;
        value: string;
    }
    interface eventProps {
        target: eventList
    }
    interface formDataProps {
        search_by: string;
        file: string
    }
    const sampleFormData: formDataProps = {
        search_by: "",
        file: ""
    }
    const [uploadreport, setUploadReport] = useState<uploadprops>(upreport);
    const [formData, setFormData] = useState<formDataProps>(sampleFormData);
    const handleUpdate = (e: eventProps) => {
        const { name, value } = e.target;
        const newFromData = { ...formData };
        // @ts-ignore
        newFromData[name] = value;
        setFormData(newFromData);
    }
    const { Dragger } = Upload;
    const props = {
        // method:'POST',
        name: 'file',
        action:'https://general.sonic.dev.coherent.com.hk/general/v2/s3/upload',

        onChange(info: { file: { name?: any; status?: any; }; fileList: any; }) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log("This is a uploaded file",info.file,"This is info List", info.fileList);
            
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            SaveFileReport(info.file);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e: { dataTransfer: { files: any; }; }) {
          console.log('Dropped files', e.dataTransfer.files);
        },
      };

      // @ts-ignore
      const SaveFileReport = (file) =>{
          const currentTime = new Date();
        const uploadreport ={
            "FileName":file.name,
            "URL":file.response,
            "Type":formData.search_by,
            "Status": "Uploaded",
            "PostDate": currentTime.toISOString()
        }

      const url='https://general.sonic.dev.coherent.com.hk/general/v2/finance/v1/file';

      fetch(url, {
          method: "POST",
          body: JSON.stringify(uploadreport),
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          }
      })
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const showModal = () => {
        setShowSuccessModal(true)
    }
    const closeModal = () => {
        setShowSuccessModal(false)
    }
    const gotoAllAccount = () => {
        navigate('/CreateNewPeriod')
    }
    console.log("This is form data before render", formData)
    return (
        <div id="uploadfile-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div
                    className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="breadcrumbs">
                        <ul className="m-ap-flex">
                            <li className="b-link"><a href="">Policy 360</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-nolink">File Upload</li>
                        </ul>
                    </div>
                    {/* <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                        <div className="m-ap-btn plush">Create New</div>
                    </div> */}

                </div>
                <div className="m-ap-addbox">
                    <div className="m-ap-insidebox">
                        <div className="centered">
                            <p id="line1">File not added yet!</p>
                            <p id="line2">To add new file click below button.</p>
                            <div className="m-ap-btn plush" onClick={showModal}>Upload File</div>
                        </div>
                    </div>
                </div>

                    {
                    showSuccessModal && (
                        <div className="modal1" id="myModal">
                            <div className="modal-content1">
                                <div className="col-md-12 mb-4">
                                    <span>
                                        Upload New File<br></br>
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <div className="m-ap-input-f m-ap-dropdown">
                                        <label>Select File Type <span id="drop">*</span></label>
                                        <br></br>
                                        <select className="col-md-5 mb-3" name="search_by" id="search_by" onChange={(e) => handleUpdate(e)}>
                                            <option value="">Search By</option>
                                            <option value="TPA Claim Service fee file">TPA Claim Service fee file</option>
                                            <option value="TPA Claim Benefit Billing file">TPA Claim Benefit Billing file</option>
                                            <option value="Agent Service fee file">Agent Service fee file</option>
                                            <option value="Billing file">Billing file</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={`col-md-12 mb-2 receipt-success ${(!formData.search_by?"str-disable":"")}`}>
                                        <Dragger {...props} maxCount={1}>
                                            <p className="ant-upload-drag-icon">
                                            </p>
                                            <p className="ant-upload-text">Drop or Drag File Here <span id="or">or</span></p>
                                            <p className="ant-upload-hint">Click to upload a New File to upload document</p>
                                            <Button>Upload New File</Button>
                                        </Dragger>
                                </div>
                                <div className="col-md-12 mb-2 download-receipt-container">
                                    <button id="download-receipt-close" onClick={closeModal}>Upload</button>
                                    <button id="download-receipt-close" onClick={closeModal}>Cancel</button>
                                </div>
                                <br /><br />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default UploadFile;