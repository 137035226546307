import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { navigate } from 'raviger';

import { NaviContainer, ChildrenSpan } from 'layout/PrivateLayout/styles';

export interface ILink {
  title?: string;
  icon?: string;
  path: string;
  children?: any;
  isParent?: boolean;
  ParentPath?: string;
}

interface IProps {
  path: string;
  paths: Array<ILink>;
  isCollapse: boolean;
}

const Navigation: React.FC<IProps> = ({ path, paths }) => {
  
  const displayStyle = {
    display: 'none'
  };
  const [selected, setSelected] = useState<string>('');
  const [links, setLinks] = useState<Array<ILink>>([]);
  const [parentClass, setParentClass] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    let routes: Array<ILink> = [];
    let parentClassObj: { [key: string]: boolean; } = {};
    paths.forEach(route => route.icon && routes.push(route));
    // console.log("this is reouts", routes)
    paths.forEach((path) => {
      let parentPath = '';
      if (path.isParent) {
        parentPath = path.path;
        parentClassObj[parentPath] = false;
      }
    });
    setParentClass(parentClassObj);
    setLinks(routes);
  }, [paths]);

  useEffect(() => {
    if (paths.length > 0) {
      const link = paths.find(pLink => pLink.path === path);
      setSelected(link ? link.path : '/dashboard');
    }
  }, [path, paths]);

  const handleNavigationClick = (key: any, link: ILink) => {
    if (link.isParent) {
      const tempParentClassList = {...parentClass};
      tempParentClassList[key] = !tempParentClassList[key];
      setParentClass(tempParentClassList);
    } else {
      navigate(key);
    }
  }


  return (
    <NaviContainer
      mode="inline"
      defaultSelectedKeys={[selected]}
      selectedKeys={[selected]}
    >
      {links.map((link, index) => (
        <Menu.Item key={`${link.path}`} title={link.title}
        onClick={(item: any) => handleNavigationClick(item.key, link)}
        style={(link.children && link.ParentPath ? (!parentClass[link.ParentPath] ? displayStyle : {}) : {})}>
          {
            link.children ? (
              <ChildrenSpan key={index}>
                <i className={`zmdi zmdi-${link.icon}`} />
                <span>{link.title}</span>
              </ChildrenSpan>
            ) : (
              <>
                <i className={`zmdi zmdi-${link.icon}`} />
                <span>{link.title}</span>
                {
                  link.isParent && (
                    <i className={`zmdi zmdi-after zmdi-${!parentClass[link.path]?'chevron-down':'chevron-up'}`} />
                  )
                }
              </>
            )
          }
        </Menu.Item>
      ))}
    </NaviContainer>
  );
};

export default Navigation;
