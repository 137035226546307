import React from 'react';
import { HeaderContainer } from 'layout/PrivateLayout/styles';
import SearchPolicy from './SearchPolicy';

interface IProps {
  title: string;
  onBack?: () => void;
  hideExtra?: boolean;
}

const Header: React.FC<IProps> = props => {
  return (
    <HeaderContainer>
      <div className="header-heading">
        <div className="header-heading-title">{props.title}</div>
        <div className="header-heading-extra">
          <SearchPolicy />
        </div>
      </div>
      <div className="header-content">
        {props.children && <div>{props.children}</div>}
      </div>
    </HeaderContainer>
  );
};

export default Header;
