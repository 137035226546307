import get from 'lodash/get';

import { IFetch, InitializeState } from './index';
import { TYPE_FETCHED } from 'store/actions/ActionTypes';
import {
    ITransaction,
    TransactionActionType,
    TRANSACTION_GET,
    TRANSACTION_CLEAR_DATA
} from 'store/actions/TransactionAction';

interface IState extends IFetch {
    transactions: Array<ITransaction>;
}

export const TransactionInitialState: IState = {
    transactions: [],
    fetching: false,
    fetched: false,
    error: null,
};

export type TransactionType = typeof TransactionInitialState;

const TransactionActionsReducer = (
    state: TransactionType = TransactionInitialState,
    action: TransactionActionType,
): IState => {
    state = InitializeState(state, action, TransactionInitialState);

    switch (action.type) {

        case TRANSACTION_GET.concat(TYPE_FETCHED): {
            const response = get(action, 'payload.Result.Message');
            const data = JSON.parse(response);
            let transactions: Array<ITransaction> = [];
            
            if (data.length > 0) {
                transactions = data.map((transaction: ITransaction) => {
                    const Args = JSON.parse(get(transaction, 'Args', []));

                    console.log('====Args', Args)
                    return {
                        ...transaction,
                        Args
                    }
                })
            }

            return {
                ...state,
                transactions
            };
        }

        case TRANSACTION_CLEAR_DATA: {
            return {
                ...state
            }
        }

        default:
            return state;
    }
};

export default TransactionActionsReducer;
