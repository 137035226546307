import React, { useState, useEffect } from 'react';
import { navigate } from 'raviger';
import './PaymentSuccess.styles.scss';
import { ReactComponent as BackArrow } from 'assets/images/backArrow.svg';

import { useGlobalState } from 'store';
import endPoint from 'config/urls';

const ProductSelection = () => {
    const [{ PolicyIdData }] = useGlobalState('MotorRiskReducer');

    const [policyId, setPolicyID] = useState<string>(PolicyIdData.PolicyId)

    useEffect(() => {
        const { PolicyId } = PolicyIdData;
        setPolicyID(PolicyId);
    }, [PolicyIdData])

    const goToCheckoutDetails = () => {
        navigate('/CheckoutDetails');
    }

    return (
        <div id="product-selection-container">
            <div className='container'>
                <div className='d-flex justify-contents-start'>
                    <BackArrow className='center' onClick={goToCheckoutDetails} />
                    <h3 className='m-4'>Payment Details</h3>
                </div>
                <div className='container'>
                    <div className='payment-container'>
                        <div className='color-style'>
                            &nbsp;
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container padding-style'>

                            <h2>Your Payment was Successful !</h2>

                            <h6 className='mt-5'>Your policy number is <b>{policyId}</b></h6>

                            <div className='form-group mt-5'>
                                <a href={`${endPoint.policyEnquiry}${policyId}`} target='_blank' rel="noreferrer">
                                    <button type="button" className="btn next-style">
                                        View Policy
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductSelection;