import React, { useState, useEffect } from 'react';
// import { Table, Tag, Space } from 'antd';
// import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import {
    eventProps, withdrawFormProps, ilOptionsProps, fundDataProps
} from '../interfaces';
import { getWithdrawPayload } from '../ProductsController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Withdraw.styles.scss';


const Withdraw = () => {

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [ilOptionsData, setIlOptionsData] = useState<ilOptionsProps[]>([]);
    const [formData, setFormData] = useState<withdrawFormProps>({ PolicyId: "" });
    const [fundData, setFundData] = useState<fundDataProps[]>([]);

    const getIlOptions = () => {
        return [
            { Option: "A", Desc: "USD Cash fund" },
            { Option: "B", Desc: "Platinum Bond Fund" },
            { Option: "C", Desc: "Global Share" },
            { Option: "D", Desc: "SPX Index" }];

        // const payload = { "Language": "", "Product": "" };
        // const url = 'https://ellwood.technology/Iloptions';

        // fetch(url, {
        //     method: "POST",
        //     body: JSON.stringify(payload),
        //     headers: {
        //         'Content-Type': 'application/json;charset=UTF-8',
        //     }
        // })
        //     .then((response) => {
        //         const statusCode = response.status;
        //         const data = response.json();
        //         return Promise.all([statusCode, data]);
        //     })
        //     .then(([statusCode, data]) => {
        //         console.log(data);
        //         if (statusCode === 200) {
        //             console.log("success")
        //             // setAccountNumber(data.PolicyID);
        //             // setShowSuccessModal(true);
        //         } else {
        //             alert(`Error ${statusCode} - ${data.error}`)
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    }

    useEffect(() => {
        const ilData = async () => {
            const data = await getIlOptions();
            setIlOptionsData(data);
        };
        ilData();
    }, [setIlOptionsData]);

    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
    }

    const enquirePolicy = async (policyId: string) => {
        const url = `https://enquiry.demo.sonic.dev.coherent.global/v1/policy/id/` + policyId;

        return await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZ3JvdXBzIjpbImFkbWluIiwiaGVhbHRoIl0sImlhdCI6MTUxNjIzOTAyMn0.CMEQlrAI3_MbWZJ1RF0qtvoZi6ZOHKduY3zyIXn8N-o'
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    return data;
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
        // .catch((error) => {
        //     console.log(error);
        // });
    }

    const showFundDetails = () => {
        if (!formData.PolicyId) {
            alert("Enter policy number first");
            return false;
        }

        const policyData = enquirePolicy(formData.PolicyId);
        policyData.then(data => {

            const Accounts = data.Cont.Risks["1"].RiskBody.Accounts;
            const UnitPrices = data.Cont.Risks["1"].RiskBody.UnitPrices;
            // console.log('data received', Accounts, UnitPrices);

            const FundTableData: fundDataProps[] = ilOptionsData.map(il => {
                const unitCnt = Accounts.filter(acc => acc.Id === il.Option);
                const unitValue = UnitPrices.filter(unit => unit.Fund === il.Option);
                return {
                    Id: il.Option,
                    Fund: il.Desc,
                    Units: Number(unitCnt[0].Units).toFixed(2),
                    Value: Number(unitCnt[0].Units * unitValue[0].Offer).toFixed(2),
                    Withdraw: ''
                }
            });
            // console.log("Fund table data", FundTableData)
            setFundData(FundTableData);
        });
    }

    const handleFundWithdraw = (e: eventProps, index: number) => {
        const { value } = e.target;
        const newFundData = [ ...fundData ];
        newFundData[index].Withdraw = value;
        setFundData(newFundData);
    }

    const SubmitWithdraw = () => {

        const accountFormData = getWithdrawPayload(fundData, formData.PolicyId);

        const url = 'https://newbusiness.demo.sonic.dev.coherent.global/v1/ul/withdraw';

        fetch(url, {
            method: "POST",
            body: JSON.stringify(accountFormData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZ3JvdXBzIjpbImFkbWluIiwiaGVhbHRoIl0sImlhdCI6MTUxNjIzOTAyMn0.CMEQlrAI3_MbWZJ1RF0qtvoZi6ZOHKduY3zyIXn8N-o'
            }
        })
        .then((response) => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(([statusCode, data]) => {
            console.log(data);
            if (statusCode === 200) {
                setShowSuccessModal(true);
            } else {
                alert(`Error ${statusCode} - ${data.error}`)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const closeModal = () => {
        setShowSuccessModal(false);
        // gotoAllAccount();
    }

    return (
        <div id="create-new-account-container">
            <div className="m-ap-wrap">
                <div className="m-ap-title">Unit Link Insurance</div>
                <div className={`m-ap-accordion m-ap-m-b-15 m-ap-active`}>
                    <div className="m-ap-ac-content pt-4">
                        <div className="m-ap-form">
                            <div className="m-ap-row">

                                <div className="m-ap-col-3 m-ap-input-f mb-4 ">
                                    <label className="m-ap-label">Policy Number</label>
                                    <div className="m-ap-col-12 mb-12 ">
                                        <input type="text" placeholder="eg. ILP-22-000085" className='col-6'
                                            name="PolicyId" id="PolicyId" value={formData.PolicyId}
                                            onChange={(e) => handleUpdate(e)} />
                                        <button className='col-4 pull-right' id="search-button" onClick={showFundDetails}>Get Details</button>
                                    </div>
                                </div>

                                {
                                    fundData.length > 0 && (
                                        <div className="m-ap-col-3 m-ap-input-f mb-4 ">
                                            <label className="m-ap-label">Policy Number</label>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Fund</th>
                                                        <th scope="col">Units</th>
                                                        <th scope="col">Value</th>
                                                        <th scope="col">Withdraw</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        fundData.map((fund, index) => (
                                                            <tr key={fund.Fund}>
                                                                <td>{fund.Fund}</td>
                                                                <td>{fund.Units}</td>
                                                                <td>{fund.Value}</td>
                                                                <td>
                                                                    <input type="text" className='col-6'
                                                                        name="PolicyId" id="PolicyId" value={fund.Withdraw}
                                                                        onChange={(e) => handleFundWithdraw(e, index)} />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div className="m-ap-bottom">
                <button id="create-submit" onClick={SubmitWithdraw}>Withdraw</button>
                <button id="cancel">Cancel</button>
            </div>

            {
                showSuccessModal && (
                    <div className="modal1" id="myModal">
                        <div className="modal-content1">
                            <div className="col-md-12 mb-2 download-receipt-title1">
                                <span>
                                    Withdraw Request
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    Withdraw request has been created successfully!
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt-close" onClick={closeModal}>Close</button>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                )
            }
        </div >
    )
}

export default Withdraw;