import React, { useEffect, useCallback } from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { useForm, FormContext } from 'react-hook-form';

import Field from 'common/Field';
import { IOption, IPolicy } from 'store/actions/PolicyAction';
import { CardContainer, CardInfo, CardInfoLabel } from 'common/style';

interface IProps {
    data?: IPolicy;
}

const PolicyDetails: React.FC<IProps> = ({ data }) => {
    const methods = useForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setValue = useCallback(methods.setValue, []);

    useEffect(() => {
        if (data && data.Risks) {
            const planType: Record<string, string> = {
                Silver: 'Silver',
                Gold: 'Gold',
                Platinum: 'Platinum'
            }
            // Benefits
            setValue([
                { 'options[0]': false },
                { 'options[1]': false },
                { 'options[2]': false },
                { 'options[3]': false }
            ]);

            setValue([
                { ContID: data.ContID },
                { Product: data.Product },
                { Term: data.Term },
                { Status: data.Status },
                { Plan: planType[get(data, 'Risks[1].TravelBody.Plan')] },
                {
                    Premium: new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2
                    }).format(get(data, 'PremiumTotals.ModalPremium', 0))
                }
            ]);

            // Set Benefit checkbox
            const benefits = get(data, 'Risks[1].TravelBody.Options', []);
            if (benefits) {
                forEach(benefits, ((opt: IOption) => {
                    switch (opt.Option) {
                        case 'Emergency':
                            setValue('options[0]', 'On');
                            break;
                        case 'Delay':
                            setValue('options[1]', 'On');
                            break;
                        case 'Baggage':
                            setValue('options[2]', 'On');
                            break;
                        case 'Personal':
                            setValue('options[3]', 'On');
                            break;
                    }
                }));
            }
        }
    }, [data, setValue])

    return (
        <FormContext {...methods}>
            <CardContainer>
                <Field name="ContID" label="Policy" readonly={true} />
                <Field name="Product" label="Product" readonly={true} />
                <Field name="Term" label="Term" readonly={true} />
                <Field name="Status" label="Status" readonly={true} />
                <Field name="Plan" label="Plan Type" readonly={true} />
                <Field
                    type="dates"
                    name="startDate"
                    name2="endDate"
                    label="Start Date"
                    label2="End Date"
                    value={data?.StartDate}
                    value2={data?.EndDate}
                    readonly={true} />
                <CardInfo>
                    <CardInfoLabel>Benefit</CardInfoLabel>
                </CardInfo>
                <Field
                    type="checkbox"
                    name="options[0]"
                    value="Emergency"
                    label="Trip cancellation or Postponement" />
                <Field
                    type="checkbox"
                    name="options[1]"
                    value="Delay"
                    label="Trip cut short" />
                <Field
                    type="checkbox"
                    name="options[2]"
                    value="Baggage"
                    label="Trip diversion (1000 for every six hours diverted overseas)" />
                <Field
                    type="checkbox"
                    name="options[3]"
                    value="Personal"
                    label="Travel delay (1000 for every six hours of delay overseas and 1000 after six hours of delay in Thailand)
"/>
                <Field name="Premium" label="Premium Per Trip" placeholder="0.00" readonly={true} />
            </CardContainer>
        </FormContext>
    )
}

export default PolicyDetails;