import React, { useEffect, useState } from 'react';

import { ClaimsService } from 'services';
import { IPolicy } from 'store/actions/PolicyAction';
import { IClaimItems } from 'store/actions/ClaimAction';
import { IClaimList, IClaimBenefit } from 'Pages/Claims/ClaimsContainter';
import ClaimList from 'Pages/Claims/components/ClaimList';

interface IProps {
    data?: IPolicy;
    fetching: boolean;
}

const ClaimsTabContainer: React.FC<IProps> = ({ data, fetching }) => {
    const [tableData, setTableData] = useState<Array<IClaimList>>([]);
    const [claimBenefit, setClaimBenefit] = useState<Record<string, IClaimBenefit>>({});
    const [claimModal, setClaimModal] = useState(false);
    const [claimRef, setClaimRef] = useState<string>('');

    useEffect(() => {
        if (data && data.Risks) {
            const { claims, clist } = ClaimsService.getClaimsData(data.Risks);
            setClaimBenefit(clist);
            setTableData(claims);
        }
    }, [data])

    const viewClaimList = (ref: string) => {
        setClaimModal(true);
        setClaimRef(ref);
    }

    const handleViewModal = () => {
        setClaimModal(false);
    }

    const approveClaimItem = async (ClaimIndex: number, claim: IClaimItems) => {
        data && await ClaimsService.approveClaimItem(
            data?.ContID,
            claimRef,
            claimBenefit[claimRef],
            claim,
            ClaimIndex);
    }

    const rejectClaimItem = async (ClaimIndex: number, claim: IClaimItems) => {
        data && await ClaimsService.rejectClaimItem(
            data?.ContID,
            claimRef,
            claimBenefit[claimRef],
            claim,
            ClaimIndex);
    }

    const approveAll = async () => data && await ClaimsService.approveAll(data?.ContID, claimRef, claimBenefit[claimRef]);

    const rejectAll = async () => data && await ClaimsService.rejectAll(data?.ContID, claimRef, claimBenefit[claimRef]);

    return (
        data ? <ClaimList
            fetching={fetching}
            policy={data}
            claims={tableData}
            claimBenefit={claimBenefit}
            claimRef={claimRef}
            claimModal={claimModal}
            handleViewModal={handleViewModal}
            viewClaimList={viewClaimList}
            rejectAll={rejectAll}
            approveAll={approveAll}
            approveClaimItem={approveClaimItem}
            rejectClaimItem={rejectClaimItem}
        /> : <div />
    );

}

export default ClaimsTabContainer;