import React, { useState, useEffect } from 'react';
import { forEach, range, uniqBy } from 'lodash';
import Moment from 'moment';

import { CardContainer } from 'common/style';
import { ChartBox, ChartTitle, ChartLabels } from 'Pages/Policy/style';

// const riskDates = [
//     [Moment("2020-04-06", "YYYY-MM-DD"), Moment("2020-10-06", "YYYY-MM-DD")],
//     [Moment("2020-04-07", "YYYY-MM-DD"), Moment("2020-10-07", "YYYY-MM-DD")],
//     [Moment("2020-04-08", "YYYY-MM-DD"), Moment("2020-10-08", "YYYY-MM-DD")]
// ];

interface IProps {
    riskDates: Array<Array<Moment.Moment>>;
    totalAmount: number;
}

const Chart: React.FC<IProps> = ({ riskDates, totalAmount }) => {
    const [xLength, setXLength] = useState(5);
    const [dates, setDates] = useState<Array<Moment.Moment>>([]);

    useEffect(() => {
        if (riskDates && riskDates.length > 0) {
            let tmp: Array<Moment.Moment> = [
                ...range(2).map((i) => Moment().subtract(i + 1, 'day').startOf('day')),
                ...range(2).map((i) => Moment().add(i + 1, 'day').startOf('day')),
                Moment().startOf('day')
            ];

            const sortDate = (d: Array<Moment.Moment>) => d.sort((a, b) => a.diff(b));

            forEach(riskDates, (riskDate) => {
                tmp.push(riskDate[0]);
                tmp.push(riskDate[1]);
            });

            tmp = sortDate(tmp);
            // Add 2 days before
            range(2).map((i) => tmp.push(tmp[0].clone().subtract(i + 1, 'day').startOf('day')))
            tmp = sortDate(tmp);

            setDates(uniqBy(tmp, (val) => val.format('YYYY-MM-DD')));
            setXLength(tmp.length);
        }
    }, [riskDates]);

    const renderLines = (date: Moment.Moment, index: number) => {
        return riskDates.map((rDate: Array<Moment.Moment>, key) => {
            let classNames = ['risk-dates'];
            date.isSame(rDate[0]) && classNames.push('start-date');
            dates[index + 1] && dates[index + 1].isSame(rDate[1]) && classNames.push('end-date');
            // Dates inbetween range
            (date.isSameOrAfter(rDate[0]) && date.isBefore(rDate[1])) && classNames.push('active');
            return <div key={key} className={classNames.join(' ')} />
        });
    }

    return ((riskDates && riskDates.length > 0) ? (
        <CardContainer style={{ marginTop: 25 }}>
            <ChartTitle>Total Cover: <span>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(totalAmount)}</span></ChartTitle>
            <ChartBox>
                {
                    dates.map((i: Moment.Moment, key) => {
                        const width = `${100 / xLength}%`;
                        const start = i.format('YYYY-MM-DD') === Moment().format('YYYY-MM-DD');
                        return (
                            <div
                                style={{ width }}
                                key={key}
                                className={start ? 'chart-days start' : 'chart-days'}>
                                {renderLines(i, key)}
                            </div>
                        )
                    })
                }
            </ChartBox>
            <ChartLabels>
                {
                    dates.map((i, key) => {
                        const width = `${100 / xLength}%`;
                        return <div style={{ width }} key={key}>{i.format('D-MMM')}</div>
                    })
                }
            </ChartLabels>
        </CardContainer>
    ) : <></>)
}

export default Chart;