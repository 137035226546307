import React, { useEffect } from 'react';
import { navigate } from 'raviger';

const Logout = () => {
  useEffect(() => {
    const logout = async () => {
      navigate('/login');
    };
    logout();
  }, []);

  return <>Logging out...</>;
};

export default Logout;
