import React from 'react';

import { IClaimItems } from 'store/actions/ClaimAction';
import { IPolicy } from 'store/actions/PolicyAction';
import MainContent from 'common/MainContent';
import { IClaimList, IClaimBenefit } from 'Pages/Claims/ClaimsContainter';
import ClaimPolicy from 'Pages/Claims/components/ClaimPolicy';
import ClaimList from 'Pages/Claims/components/ClaimList';

export interface IClaims {
    policy: IPolicy;
    claims: Array<IClaimList>;
    claimBenefit: Record<string, IClaimBenefit>;
    claimRef: string;
    claimModal: boolean;
    fetching: boolean;
    handleViewModal: () => void;
    viewClaimList: (ref: string) => void;
    rejectAll: () => void;
    approveAll: () => void;
    approveClaimItem: (ClaimIndex: number, claim: IClaimItems) => void;
    rejectClaimItem: (ClaimIndex: number, claim: IClaimItems) => void;
}

const ClaimPage: React.FC<IClaims> = (props) => {
    return (
        <MainContent
            title='Claims'
            headerContent={props.policy && `${props.policy?.Owner.Name}'s Travel Insurance`}>
            <ClaimPolicy policy={props.policy} />
            <ClaimList {...props} />
        </MainContent>
    )
}

export default ClaimPage;