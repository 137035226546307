import styled from 'styled-components';

export const CollapseContainer = styled.div`
    background: #FAFAFA;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 24px;

    .collapse-header{
        padding: 21px 24px;
        color: #333333;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        justify-content: space-between;

        .collapse-header-control {
            font-weight: 500;
            font-size: 21px;
            line-height: 23px;
            color: #00CCDB;
            i {
                cursor: pointer;
            }
        }
    }

    .collapse-content {
        border-top: 1px solid #DCDCDC;
        padding: 24px;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
        div {
            margin: 6px 0;
            strong {
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
    
    .collapse-footer {
        border-top: 1px solid #DCDCDC;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 18px;
    }
`;