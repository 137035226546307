import get from 'lodash/get';
// import forEach from 'lodash/forEach';

import { IFetch, InitializeState } from './index';
import { TYPE_FETCHED } from 'store/actions/ActionTypes';
import {
    SET_SPARK_TOKEN,
    SET_AGENT_DATA,
    SET_OWNER_DATA,
    SET_VEHICLE_DATA,
    SET_DRIVER_DATA,
    SET_PLAN_DATA,
    SET_BENEFIT_DATA,
    SET_UW_DATA,
    SET_PAYMENT_DATA,
    SET_POLICYID_DATA,
    CLEAR_MOTOR_DATA,
    MotorRiskActionType
} from 'store/actions/MotorRiskAction';
import { addLocalData } from 'lib/localStorage'; // getLocalData
import {
    createAgentProps,
    createOwnerProps,
    createVehicleProps,
    createDriverProps,
    planFormProps,
    benefitFormProps,
    uwFormProps,
    paymentFormProps,
    policyIdProps,
    sparkDataProps
} from '../../Pages/MotorRisk/interfaces';
import {
    sampleAgent,
    sampleOwner,
    sampleVehicle,
    sampleDriver,
    sampleBenefitData,
    sampleUWData,
    samplePaymentForm,
    samplePolicyId
} from '../../Pages/MotorRisk/MotorController';

interface MRState extends IFetch {
    AgentFormData: createAgentProps;
    OwnerFormData: createOwnerProps;
    VehicleFormData: createVehicleProps;
    DriverFormData: createDriverProps;
    PlanFormData: planFormProps;
    BenefitFormData: benefitFormProps;
    UWFormData: uwFormProps;
    PaymentFormData: paymentFormProps;
    PolicyIdData : policyIdProps;
    SparkData: sparkDataProps;
}

export const MotorRiskInitialState: MRState = {
    AgentFormData: sampleAgent,
    OwnerFormData: sampleOwner,
    VehicleFormData: sampleVehicle,
    DriverFormData: sampleDriver,
    BenefitFormData: sampleBenefitData,
    UWFormData: sampleUWData,
    PaymentFormData: samplePaymentForm,
    PlanFormData: {Option: '', Desc: ''},
    PolicyIdData:samplePolicyId,
    SparkData: {access_token: '', expires_in: 0, timestamp: undefined},
    fetching: false,
    fetched: false,
    error: null,
};

export type MotorRiskType = typeof MotorRiskInitialState;

const MotorRiskActionsReducer = (
    state: MotorRiskType = MotorRiskInitialState,
    action: MotorRiskActionType,
): MRState => {
    state = InitializeState(state, action, MotorRiskInitialState);

    switch (action.type) {
        
        case SET_AGENT_DATA.concat(TYPE_FETCHED): {
            const AgentFormData = get(action, 'payload', null);
            addLocalData('AgentFormData', AgentFormData);
            return {
                ...state,
                AgentFormData
            }
        }

        case SET_OWNER_DATA.concat(TYPE_FETCHED): {
            const OwnerFormData = get(action, 'payload', null);
            addLocalData('OwnerFormData', OwnerFormData);
            return {
                ...state,
                OwnerFormData
            }
        }

        case SET_VEHICLE_DATA.concat(TYPE_FETCHED): {
            const VehicleFormData = get(action, 'payload', null);
            addLocalData('VehicleFormData', VehicleFormData);
            return {
                ...state,
                VehicleFormData
            }
        }

        case SET_DRIVER_DATA.concat(TYPE_FETCHED): {
            const DriverFormData = get(action, 'payload', null);
            addLocalData('DriverFormData', DriverFormData);
            return {
                ...state,
                DriverFormData
            }
        }

        case SET_PLAN_DATA.concat(TYPE_FETCHED): {
            const PlanFormData = get(action, 'payload', null);
            addLocalData('PlanFormData', PlanFormData);
            return {
                ...state,
                PlanFormData
            }
        }

        case SET_BENEFIT_DATA.concat(TYPE_FETCHED): {
            const BenefitFormData = get(action, 'payload', null);
            addLocalData('BenefitFormData', BenefitFormData);
            return {
                ...state,
                BenefitFormData
            }
        }

        case SET_UW_DATA.concat(TYPE_FETCHED): {
            const UWFormData = get(action, 'payload', null);
            addLocalData('UWFormData', UWFormData);
            return {
                ...state,
                UWFormData
            }
        }

        case SET_PAYMENT_DATA.concat(TYPE_FETCHED): {
            const PaymentFormData = get(action, 'payload', null);
            addLocalData('PaymentFormData', PaymentFormData);
            return {
                ...state,
                PaymentFormData
            }
        }

        case SET_POLICYID_DATA.concat(TYPE_FETCHED): {
            const PolicyIdData = get(action, 'payload', null);
            addLocalData('PaymentFormData', PolicyIdData);
            return {
                ...state,
                PolicyIdData
            }
        }

        case SET_SPARK_TOKEN.concat(TYPE_FETCHED): {
            const SparkData = get(action, 'payload', null);
            addLocalData('SparkData', SparkData);
            return {
                ...state,
                SparkData
            }
        }

        case CLEAR_MOTOR_DATA.concat(TYPE_FETCHED): {
            return {
                ...state,
                AgentFormData: sampleAgent,
                OwnerFormData: sampleOwner,
                VehicleFormData: sampleVehicle,
                DriverFormData: sampleDriver,
                BenefitFormData: sampleBenefitData,
                UWFormData: sampleUWData,
                PaymentFormData: samplePaymentForm,
                PlanFormData: {Option: '', Desc: ''},
                SparkData: {access_token: '', expires_in: 0, timestamp: undefined},
                fetching: false,
                fetched: false,
                error: null,
            }
        }

        default:
            return state;
    }
};

export default MotorRiskActionsReducer;
