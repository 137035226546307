import React from 'react';
import { BreadcrumbComponnetProps, BreadcrumbProps } from '../interfaces';
import './Breadcrumbs.styles.scss';

const Breadcrumbs = (props: BreadcrumbComponnetProps) => {
    const { paths } = props;
    const getRandomString = () => {
        let length = 18;
        let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    return (
        <div id="finance-breadcrumbs">
            <div className="breadcrumbs">
                <ul className="m-ap-flex">
                    {
                        paths.length > 0 && paths.map((path: BreadcrumbProps, index: number) => (
                            <React.Fragment key={getRandomString()}>
                                {
                                    index < paths.length - 1 && (
                                        <React.Fragment key={getRandomString()}>
                                            <li key={`link${getRandomString()}`} test={`link${index}-${getRandomString()}`} className="b-link"><a href={path.redirectTo}>{path.title}</a></li>
                                            <li key={`shlash${getRandomString()}`} test={`link${index}-${getRandomString()}`} className="b-slace">/</li>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    index === paths.length - 1 && (
                                        <li key={`link${getRandomString()}`} test={`link${index}-${getRandomString()}`} className="b-nolink">{path.title}</li>
                                    )
                                }
                            </React.Fragment>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Breadcrumbs;
