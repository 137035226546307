import React from 'react';
import get from 'lodash/get';
import { CSSProperties } from 'styled-components';
import { FieldError, NestDataObject } from 'react-hook-form';

import {
    CardInfo,
    CardInfoLabel,
    CardInfoValue,
    ErrorInfo
} from 'common/style';
import InputField from 'common/Field/InputField';
import TextareaField from 'common/Field/TextAreaField';
import SelectField from 'common/Field/SelectField';
import DateField from 'common/Field//DateField';
import DateRangeField from 'common/Field/DateRangeField';

interface IOption {
    [id: string]: string
}

export interface IFieldProps {
    id?: string;
    type?: string;
    name: string;
    name2?: string;
    label: string;
    label2?: string;
    placeholder?: string;
    required?: boolean;
    readonly?: boolean;
    value?: string;
    value2?: string;
    options?: Array<IOption>;
    style?: CSSProperties;
    onChange?: (value: string) => void;
    defaultChecked?: boolean;
    error?: FieldError | FieldError[] | NestDataObject<any> | NestDataObject<any>[];
}

const Field: React.FC<IFieldProps> = (props) => {
    const renderField = () => {
        switch (props.type) {
            case 'textarea':
                return <TextareaField {...props} />;
            case 'checkbox':
                return <label> {<InputField {...props} />} {props.label}</label>;
            case 'date':
                return <DateField {...props} />;
            case 'dates':
                return <DateRangeField {...props} />;
            case 'select':
                return <SelectField {...props} />;
            default:
                return <InputField {...props} />;
        }
    }

    return (
        <CardInfo>
            {props.type !== 'checkbox' && <CardInfoLabel>{props.label}</CardInfoLabel>}
            <CardInfoValue>
                {renderField()}
                {
                    props.error && (
                        <ErrorInfo>
                            <br />
                            {get(props.error, 'message') ? get(props.error, 'message') : `${props.label} is required`}
                        </ErrorInfo>
                    )
                }
            </CardInfoValue>
        </CardInfo>
    );
}


export {
    InputField,
    TextareaField,
    SelectField,
    DateField,
    DateRangeField
}

export default Field;