import React from 'react';
import { navigate } from 'raviger';
import './ProductSelection.styles.scss';
import { ReactComponent as Motor } from 'assets/images/Motor.svg';
import { ReactComponent as PA } from 'assets/images/PA.svg';
import { ReactComponent as Travel } from 'assets/images/Travel.svg';
import { ReactComponent as Home } from 'assets/images/Home.svg';
import { ReactComponent as Life } from 'assets/images/Life.svg';
import { ReactComponent as Health } from 'assets/images/Health.svg';
import { ReactComponent as Group } from 'assets/images/Group.svg';
import { ReactComponent as Pet } from 'assets/images/Pet.svg';

const ProductSelection = () => {


    const goToAgentDetails = () => {
        navigate('/AgentDetails');
    }

    const goToILP = () => {
        navigate('/ILP');
    }

    const goToNewBusiness = (productName = '') => {
        switch (productName) {
            case 'PA':
                window.open('https://policy360.demo.sonic.dev.coherent.global/enquiry/new_business/PA', '_blank');
                break;

            case 'PET':
                window.open('https://policy360.demo.sonic.dev.coherent.global/enquiry/new_business/PET', '_blank');
                break;

            case 'HOME':
                window.open('https://policy360.demo.sonic.dev.coherent.global/enquiry/new_business/HOME', '_blank');
                break;

            case 'QMS':
                window.open('https://ast.demo.sonic.dev.coherent.global/', '_blank');
                break;

            default:
                return false;
        }

    }

    return (
        <div id="product-selection-container">
            <div className='container'>
                <h3 className='m-4'>Select a product</h3>
                <div className='container'>
                    <div className='product-container'>
                        <div className='color-style'>
                            &nbsp;
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container padding-style'>

                            <div className='row mb-3'>

                                <div className='col-3'>
                                    <div className='product' onClick={goToAgentDetails}>
                                        <Motor className='center' />
                                    </div>
                                    <div className='text-center mt-2'>Motor</div>
                                </div>

                                <div className='col-3'>
                                    <div className='product' onClick={() => goToNewBusiness('PA')}>
                                        <PA className='center' />
                                    </div>
                                    <div className='text-center mt-2'>Personal Accident</div>
                                </div>

                                <div className='col-3'>
                                    <div className='product' onClick={goToAgentDetails}>
                                        <Travel className='center' />
                                    </div>
                                    <div className='text-center mt-2'>Travel</div>
                                </div>

                                <div className='col-3'>
                                    <div className='product' onClick={() => goToNewBusiness('HOME')}>
                                        <Home className='center' />
                                    </div>
                                    <div className='text-center mt-2'>Home</div>
                                </div>

                            </div>
                            <div className='row mb-3'>

                                <div className='col-3'>
                                    <div className='product'>
                                        <Life className='center' />
                                    </div>
                                    <div className='text-center mt-2'>Life</div>
                                </div>

                                <div className='col-3'>
                                    <div className='product'>
                                        <Health className='center' />
                                    </div>
                                    <div className='text-center mt-2'>Health</div>
                                </div>

                                <div className='col-3'>
                                    <div className='product' onClick={() => goToNewBusiness('QMS')}>
                                        <Group className='center' />
                                    </div>
                                    <div className='text-center mt-2'>Group</div>
                                </div>

                                <div className='col-3'>
                                    <div className='product'>
                                        <Pet className='center' onClick={() => goToNewBusiness('PET')} />
                                    </div>
                                    <div className='text-center mt-2'>Pet</div>
                                </div>

                            </div>

                            <div className='row'>

                                <div className='col-3'>
                                    <div className='product' onClick={goToILP}>
                                        <Group className='center' />
                                    </div>
                                    <div className='text-center mt-2'>Unit Link Policy</div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductSelection;