import React, { useEffect, useState } from "react";
import map from "lodash/map";

import { Tabs } from "Pages/Policy/style";
import PolicyDetails from "Pages/Policy/components/PolicyDetails";
import InsuredDetails from "Pages/Policy/components/InsuredDetails";
import Coverage from "Pages/Policy/components/Coverage";
import ClaimsTab from "Pages/Policy/components/ClaimsTab";
import TransactionTab from "Pages/Policy/components/TransactionTab";
import MainContent from "common/MainContent";
import { useGlobalState } from "store";
import { PolicyService, TransactionService } from "services";

interface ITab {
  label: string;
  component: any;
}

const Policy360: React.FC = () => {
  const [activeTab, setActiveTab] = useState("PolicyDetails");
  const [{ policyId, data, fetching }] = useGlobalState("PolicyReducer");
  const [{ transactions }] = useGlobalState("TransactionReducer");

  useEffect(() => {
    if (policyId) {
      PolicyService.getPolicy(policyId);
      TransactionService.getTransaction(policyId);
    }
  }, [policyId]);

  const tabs: Record<string, ITab> = {
    PolicyDetails: {
      label: "Policy Details",
      component: <PolicyDetails data={data} />,
    },
    InsuredDetails: {
      label: "Policy Holder Details",
      component: <InsuredDetails data={data} />,
    },
    Coverage: {
      label: "Coverage",
      component: <Coverage data={data} />,
    },
    Claims: {
      label: "Claims",
      component: <ClaimsTab data={data} fetching={fetching} />,
    },
    Transactions: {
      label: "Transactions",
      component: (
        <TransactionTab
          data={data}
          transactions={transactions}
          fetching={fetching}
        />
      ),
    },
  };

  const handleTabs = (active: string) => {
    setActiveTab(active);
  };

  return (
    <MainContent
      title="Policy 360"
      headerContent={data && `${data?.Owner.Name}'s Travel Insurance`}
    >
      <Tabs>
        {map(tabs, (tab, key) => (
          <li key={key}>
            <span
              onClick={() => handleTabs(key)}
              className={key === activeTab ? "active" : ""}
            >
              {tab.label}
            </span>
          </li>
        ))}
      </Tabs>

      {tabs[activeTab].component}
    </MainContent>
  );
};

export default Policy360;
