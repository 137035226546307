import React, { useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form'

import { Button } from 'common/style';
import { SearchContainer } from 'layout/PrivateLayout/styles';
import { useGlobalState, apiRequest } from 'store';
import { policyByPhone, policyClearData, IPolicyList, setPhone, setPolicy } from 'store/actions/PolicyAction';
import InputField from 'common/Field/InputField';
import SelectField from 'common/Field/SelectField';


const SearchPolicy = () => {
    const methods = useForm();
    const [{ searchPhone, policyId, list, fetching }] = useGlobalState('PolicyReducer');

    useEffect(() => {
        apiRequest(policyByPhone(searchPhone));
    }, [searchPhone])

    const submit = async (field: Record<string, any>) => {
        debugger;
        apiRequest(policyClearData());
        const phone = field.searchPhone;
        phone && apiRequest(setPhone(phone));
    }

    const handlePolicyChange = (pid: string) => {
        apiRequest(setPolicy(pid));
    }

    const handlePhoneChange = (data: string) => {
        data === '' && apiRequest(policyClearData());
    }

    return (
        <FormContext {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
                <SearchContainer>
                    <InputField
                        name="searchPhone"
                        label="Phone Number"
                        onChange={handlePhoneChange}
                        value={searchPhone}
                        required={true} />
                    {list.length === 0 && (
                        <Button
                            className="primary" 
                            htmlType="submit"
                            loading={fetching}>
                            Search
                        </Button>
                    )}

                    {list.length > 0 && (
                        <SelectField
                            name="policyId"
                            label="Policy Number"
                            value={policyId}
                            placeholder="Select Policy"
                            onChange={handlePolicyChange}
                            options={list.map((policy: IPolicyList) => ({
                                [policy.PolicyNo]: policy.PolicyNo
                            }))}
                            type="select" />
                    )}
                </SearchContainer>
            </form>
        </FormContext>
    )
}

export default SearchPolicy;