import React from 'react';
import { Link } from 'raviger';
import { Result, Row } from 'antd';

const Page404 = () => {
  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      style={{ width: '100%', fontSize: 18 }}
    >
      <Result
        status={404}
        title="TBD"
        subTitle="Sorry, the page is yet to be determined."
        extra={<Link href="/">Back Home</Link>}
      />
    </Row>
  );
};

export default Page404;
