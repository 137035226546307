import React from 'react';

import { CollapseContent, } from 'common/Collapse';
import TextareaField from 'common/Field/TextAreaField';

const ClaimNote: React.FC = () => {
    return (
        <CollapseContent>
            <div><strong>Note</strong></div>
            <TextareaField name="claimNote" label="Note" />
        </CollapseContent>
    )
}

export default ClaimNote;