import React, { useState } from 'react';
import { Tooltip } from 'antd';

import { CollapseFooter } from 'common/Collapse';
import { IClaimItems } from 'store/actions/ClaimAction';
import ClaimNote from 'Pages/Claims/components/ClaimListModal/ClaimNote';
import ClaimAttachment from 'Pages/Claims/components/ClaimListModal/ClaimAttachment';
import ClaimConversation from 'Pages/Claims/components/ClaimListModal/ClaimConversation';
import { ClaimActions, ClaimItemButtons, ApproveItem, RejectItem } from './style';

interface IProps {
    claimIndex: number
    claimItem: IClaimItems;
    fetching: boolean;
    onApprove: (key: number, claim: IClaimItems) => void;
    onReject: (key: number, claim: IClaimItems) => void;
}

const ClaimFooter: React.FC<IProps> = (props) => {
    const [toDisplay, setToDisplay] = useState('');

    const handleClick = (type: string) => {
        setToDisplay(type !== toDisplay ? type : '');
    }

    const classNames = (name: string, type: string) => {
        const names = ['zmdi', `zmdi-${name}`];
        type === toDisplay && names.push('active');
        return names.join(' ');
    }

    const handleApprove = () => {
        props.onApprove && props.onApprove(props.claimIndex, props.claimItem);
    }

    const handleReject = () => {
        props.onReject && props.onReject(props.claimIndex, props.claimItem);
    }

    return (
        <>
            <CollapseFooter>
                <ClaimActions>
                    <Tooltip title="Attachments">
                        <i
                            className={classNames('link', 'attachment')}
                            onClick={() => handleClick('attachment')} />
                    </Tooltip>
                    <Tooltip title="Notes">
                        <i
                            className={classNames('calendar-note', 'notes')}
                            onClick={() => handleClick('notes')} />
                    </Tooltip>
                    <Tooltip title="Conversation">
                        <i
                            className={classNames('comment-alt-text', 'conversation')}
                            onClick={() => handleClick('conversation')} />
                    </Tooltip>
                </ClaimActions>
                <ClaimItemButtons>
                    <RejectItem loading={props.fetching} onClick={handleReject}>Reject Item</RejectItem>
                    <ApproveItem loading={props.fetching} onClick={handleApprove}>Approve item</ApproveItem>
                </ClaimItemButtons>
            </CollapseFooter>
            {toDisplay === 'attachment' && <ClaimAttachment />}
            {toDisplay === 'notes' && <ClaimNote />}
            {toDisplay === 'conversation' && <ClaimConversation />}
        </>
    )
}

export default ClaimFooter;