import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import { Table } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { eventProps, ledgerProps, searchLedgerprops } from '../interfaces';
import {
    formData, allLedgerTable, AllLedgerBreadcrumbItems
} from '../LedgerController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AllLedger.styles.scss';

const AllLedger = () => {

    // const today = new Date();
    // const [currentTab, setCurrentTab] = useState<string>('All');
    // const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    // const [showSuccessModal1, setShowSuccessModal1] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchData, setSearchData] = useState<searchLedgerprops>(formData);
    const [filteredLmData, setFilteredLmData] = useState<ledgerProps[]>([]);

    const gotoCreateScreen = () => {
        navigate('/CreateLedger');
    }

    const goToEditLadger = (ladgerId: string) => {
        navigate(`/CreateLedger/${ladgerId}`);
    }

    useEffect(() => {
        allLedgerTable.map(column => {
            column["onCell"] =  (record, rowIndex: number) => {
                return {
                    onClick: (ev: eventProps) => {
                        console.log(record, rowIndex);
                        goToEditLadger(record.LedgerID);
                    },
                };
            }
        });
        getLedger("");
    }, [])

    const filterledgerList = (tab: string, data: ledgerProps[] = []) => {
        const dataToFilter = data; // (data.length > 0) ? data : ledgerData;
        // const filterdJournal = dataToFilter.filter(lm => {
        //     switch (tab) {
        //         case 'Drafts':
        //             return lm.Status === 'Draft' || lm.Status === "";
        //         case 'Awaiting_Approval':
        //             return lm.Status === 'Pending';
        //         case 'Posted':
        //             return lm.Status === 'Posted';
        //         default:
        //             return true;
        //     }
        // }).map(lm => {
        //     lm.ActiveFrom = showDate(lm.ActiveFrom);
        //     lm.ActiveTo = showDate(lm.ActiveTo);
        //     return lm;
        // });
        dataToFilter.map((ld: ledgerProps) => {
            ld.ActiveFrom = ld.ActiveFrom.split('T')[0];
            ld.ActiveTo = ld.ActiveTo.split('T')[0];
        })
        setFilteredLmData(dataToFilter);
    }

    const handleUpdate = (e: eventProps) => {
        const { name, value } = e.target;
        const newFromData = { ...searchData };
        newFromData[name] = value;
        setSearchData(newFromData);
        if (name === 'allJournal_filter') {
            filterledgerList(value);
        }
    }

    const searchLedger = () => {
        let searchStr = "?search_by=";
        if (searchData.search_value) {
            searchStr += `${searchData.search_by}&value=${searchData.search_value}`;
        } else {
            if (searchData.from_date && searchData.to_date) {
                searchStr += `PostingDate&value=${searchData.from_date}_${searchData.to_date}&order_by=JournalID_DESC&limit=40`;
            } else {
                alert('Please enter the search criteria properly.');
            }
        }
        getLedger(searchStr);
    }

    const getLedger = async (searchStr: string) => {
        setIsLoading(true);
        try {
            await fetch(`${endPoint.getLedger}${searchStr}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200 && data.length > 0) {
                        // getLedger(data);
                        filterledgerList('All', data);
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    const clearFilter = () => {
        setSearchData(formData);
        // setAccountData([]);
    }


    return (
        <div id="all-account-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <Breadcrumbs paths={AllLedgerBreadcrumbItems} />
                    <div className="m-ap-btn plush" onClick={gotoCreateScreen}>Create New</div>
                </div>

                <div className="m-ap-search m-ap-m-b-15 pad-l-r-30">
                    <div className="m-ap-row search-elements col-sm-12">
                        <div className="m-ap-input-f col-sm-2">
                            <label >Search Legder </label>
                            <select name="search_by" id="search_by"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.search_by}>
                                <option value="">Search By</option>
                                <option value="LedgerID">Ledger ID</option>
                            </select>
                        </div>
                        <div className="m-ap-input-f col-sm-2">
                            <label>&nbsp;</label>
                            <input type="text" placeholder="Begin your search"
                                id="search_value" name="search_value"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.search_value}
                            />    
                        </div>
                        <div className="m-ap-input-f m-ap-date col-sm-2">
                            <label>Active Date</label>
                            <input type="date" id="from_date" name="from_date"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.from_date}
                            />
                        </div>
                        <div className="m-ap-input-f m-ap-to pr-2">
                            <label>To</label>
                        </div>
                        <div className="m-ap-input-f m-ap-date col-sm-2">
                            <label>&nbsp;</label>
                            <input type="date" id="to_date" name="to_date"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.to_date}
                            />
                        </div>
                        <div className="col-sm-3 pt-4">
                            <div className="m-ap-btn btn-w-180 m-ap-search m-ap-blue mr-2" id="receipt-search" onClick={searchLedger}>Search</div>
                            <div className="m-ap-btn btn-w-180" id="clear-filter" onClick={clearFilter}>Clear Filters</div>
                        </div>
                    </div>
                </div>

                <div className="m-ap-filter m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="m-ap-title">All Ledger ({filteredLmData.length})</div>
                    <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                        <div className="m-ap-filter-text filter-status">Filter by : Ladger Type</div>
                        <div className="m-ap-input-f">
                            <select>
                                <option>All</option>
                                <option>Option text 1</option>
                                <option>Option text 2</option>
                                <option>Option text 3</option>
                            </select>
                        </div>
                        <div className="m-ap-filter-text filter-status"> Status</div>
                        <div className="m-ap-input-f">
                            <select name="allJournal_filter" id="allJournal_filter">
                                <option value="All">All</option>
                                <option value="Posted">Posted</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="m-ap-table-box">
                    <div className="m-ap-table">

                        <Table
                            columns={allLedgerTable}
                            dataSource={filteredLmData}
                            rowKey={record => record.LedgerID}
                        />
                        {/* <table>
                            <thead>
                                <tr>
                                    <th className="m-ap-account-id">Account ID</th>
                                    <th className="m-ap-account-type">Account Type</th>
                                    <th className="m-ap-account-name">Account Name</th>
                                    <th className="m-ap-ledger-id">Ledger ID</th>
                                    <th className="m-ap-active-from">Active From</th>
                                    <th className="m-ap-active-to">Active To</th>
                                    <th className="m-ap-status">Status</th>
                                    <th className="m-ap-action">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    accountData.AccountID && (
                                        <tr>
                                            <td>{accountData.AccountID}</td>
                                            <td>{accountData.Description}</td>
                                            <td>{accountData.Data}</td>
                                            <td>{accountData.LedgerID}</td>
                                            <td>{showDate(accountData.ActiveFrom)}</td>
                                            <td>{showDate(accountData.ActiveTo)}</td>
                                            <td>{accountData.Status}</td>
                                            <td>...</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table> */}
                    </div>
                    {/* <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                        <Pagination defaultCurrent={1} total={50} />
                    </div> */}
                </div>

                {/* <div className="popup-box popup-msg">
                        <div className="row success-msg success-msg-desktop">
                            <div className="background-success display-contents">
                                <span className="glyphicon glyphicon-ok-circle success-icon"><i className="bi bi-check-circle"></i></span>
                                <span className="message"> Please select only one payee code at a time </span>
                                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                <div className="dismiss-box">
                                    <span className="payment-timestamp">12:06 15/04/2021</span>
                                    &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                                    <span className="dismiss">Dismiss </span>
                                </div>
                            </div>
                        </div>
                    </div> */}

            </div>
        </div>
    )
}

export default AllLedger;