import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import {
    AllAccountsBreadcrumbItems,
    formData,
    sampleAccount,
    showDate,
    allAccountsTableColumns,
    parseDate,
    sampleBankDets,
    sampleContactDets
} from '../AccountController';
import {
    accountProps,
    searchAccountProps,
    ContactDetsProps,
    BankDetsProps,
    OtherDetsProps,
    transactionsProps,
    subAccountsProps
} from '../interfaces';
import { Table, Switch, Space, Tag } from 'antd';

import 'bootstrap/dist/css/bootstrap.min.css';
import './ViewAccounts.styles.scss';

const ViewAccounts = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<string>('Sub_Accounts');
    const [subAccountView, setSubAccountView] = useState<boolean>(true);
    const [transactionView, setTransactionView] = useState<boolean>(false);
    const [accountDetails, setAccountDetails] = useState<boolean>(false);
    const [mainAccountID, setMainAccountID] = useState<string>("");
    const [bankDetails, setBankDetails] = useState<BankDetsProps>(sampleBankDets);
    const [contactDetails, setContactDetails] = useState<ContactDetsProps>(sampleContactDets);
    const [accountInformation, setAccountInformation] = useState<accountProps>(sampleAccount);
    const [subAccountList, setSubAccountList] = useState<subAccountsProps>({});

    useEffect(() => {
        const urlPath = window.location.pathname.split('/');
        if (urlPath.length > 2) {
            const accountId = urlPath[2];
            getAccountDetails(accountId);
            setMainAccountID(accountId);
            getTransactions(accountId);
        }
    }, []);

    const getAccountDetails = async (accountId: string) => {
        setIsLoading(true);
        try {
            await fetch(`${endPoint.getAccount}?search_by=AccountID&value=${accountId}&limit=1&order_by=AccountID_DESC`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200 && data.length > 0) {
                        console.log('response', data);
                        const BankDetails = JSON.parse(data[0].Data)["BankDets"];
                        const Contact = JSON.parse(data[0].ContactDets);
                        console.log(BankDetails);
                        setBankDetails(BankDetails);
                        setContactDetails(Contact);
                        setAccountInformation(data[0]);
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    const getTransactions = async (accountId: string) => {
        setIsLoading(true);
        try {
            await fetch(`${endPoint.getAccount}transaction?limit=100&order_by=TranID&value=${accountId}&search_by=AccountID`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200 && data.length > 0) {
                        const SubAccounts: subAccountsProps = {};
                        data.forEach((sub: transactionsProps) => {
                            if (sub.SubAccount in SubAccounts) {
                                SubAccounts[sub.SubAccount].Amount += Number(sub.Amount);
                                SubAccounts[sub.SubAccount].Transactions.push(sub);
                            } else {
                                SubAccounts[sub.SubAccount] = {
                                    Amount: sub.Amount,
                                    Transactions: [sub],
                                    ShowHideChild: false
                                }
                            }
                        });
                        console.log('AccountList', SubAccounts);
                        setSubAccountList(SubAccounts);
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    const showHideTransactions = (subAccountName: string) => {
        const newSubAccounts = { ...subAccountList };
        newSubAccounts[subAccountName].ShowHideChild = !newSubAccounts[subAccountName].ShowHideChild;
        setSubAccountList(newSubAccounts);
    }

    const subAccount = () => {
        setSubAccountView(true);
        setTransactionView(false);
    }
    const transaction = () => {
        setSubAccountView(false);
        setTransactionView(true);
    }

    const accountdetails = () => {
        const accDets = !accountDetails;
        setAccountDetails(accDets);
    }
    const AccountTab = (tab: string) => {
        setCurrentTab(tab);
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };

    const columns11 = [
        { title: 'Description', dataIndex: 'Description', key: 'Description' },
        { title: 'TagRef 1', dataIndex: 'TagRef_11', key: 'TagRef_11' },
        { title: 'TagRef 2', dataIndex: 'TagRef_21', key: 'TagRef_21' },
        { title: 'Product', dataIndex: 'Product', key: 'Product' },
        { title: 'Amount', dataIndex: 'Amount1', key: 'Amount1' },
        { title: 'Balance', dataIndex: 'Balance1', key: 'Balance1' },

    ];

    const data11 = [
        {
            key: 1,
            Description: 'Stamp Duty',
            TagRef_11: '-',
            TagRef_21: 'H01UW80D',
            Product: 'PET',
            Amount1: '$ 3.00',
            Balance1: '$ 0.00'
        },
        {
            key: 2,
            Description: 'Stamp Duty',
            TagRef_11: '-',
            TagRef_21: 'H01UW80D',
            Product: 'PET',
            Amount1: '$ 3.00',
            Balance1: '$ 0.00'
        },
        {
            key: 3,
            Description: 'Stamp Duty',
            TagRef_11: '-',
            TagRef_21: 'H01UW80D',
            Product: 'PET',
            Amount1: '$ 3.00',
            Balance1: '$ 0.00'
        }
    ]

    const columns1 = [
        { title: 'Transaction ID', dataIndex: 'Transaction_ID', key: 'Transaction_ID' },
        { title: '', dataIndex: 'copy', key: 'copy' },
        { title: 'Sub Account', dataIndex: 'Sub_Account', key: 'Sub_Account' },
        { title: 'TagRef 1', dataIndex: 'TagRef_1', key: 'TagRef_1' },
        { title: 'TagRef 2', dataIndex: 'TagRef_2', key: 'TagRef_2' },
        { title: 'Tran. Type', dataIndex: 'Tran_Type', key: 'Tran_Type' },
        { title: 'Tran. Date', dataIndex: 'Tran_Date', key: 'Tran_Date' },
        { title: 'Amount', dataIndex: 'Amount', key: 'Amount' },
        { title: 'Balance', dataIndex: 'Balance', key: 'Balance' },

    ];

    const data1 = [
        {
            key: 1,
            Transaction_ID: '555c2f5bb5e967d',
            copy: <a id='copy'>Copy ID</a>,
            Sub_Account: 'S-PSC-21-APTO-665020',
            TagRef_1: 'INV84275',
            TagRef_2: 'PSCUWB00',
            Tran_Type: 'Premium',
            Tran_Date: '2020-03-21',
            Amount: '$ 1000.00',
            Balance: '$ 0.00',
            description: <a>
                <Table
                    columns={columns11}
                    dataSource={data11}
                    pagination={false}
                />
            </a>

        },
        {
            key: 2,
            Transaction_ID: '555c2f5bb5e967d',
            copy: <a id='copy'>Copy ID</a>,
            Sub_Account: 'S-PSC-21-APTO-665020',
            TagRef_1: 'INV84275',
            TagRef_2: 'PSCUWB00',
            Tran_Type: 'Premium',
            Tran_Date: '2020-03-21',
            Amount: '$ 1000.00',
            Balance: '$ 0.00',
            description: <a>
                <Table
                    columns={columns11}
                    dataSource={data11}
                    pagination={false}
                />

            </a>
        },
    ];


    const columns = [
        { title: 'Sub Account #', dataIndex: 'name', key: 'name' },
        { title: 'Amount', dataIndex: 'address', key: 'address', align: 'right' },
    ];


    const data = [
        {
            key: 1,
            name: '	POB6090502290',
            address: '$ 1799.00',
            description: <a> <Table
                columns={columns1}
                expandable={{
                    expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                }}
                rowSelection={{ ...rowSelection }}
                dataSource={data1}
            /></a>

        },
        {
            key: 2,
            name: '	POB4208109309',
            address: '$ 1799.00',
            description: <a> <Table
                columns={columns1}
                expandable={{
                    expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                }}
                dataSource={data1}
            /></a>

        },
    ];



    const columns2 = [
        { title: 'Document ID', dataIndex: 'name1', key: 'name1' },
        { title: 'Documents Type', dataIndex: 'name2', key: 'name2' },
        { title: 'Date', dataIndex: 'name3', key: 'name3' },
        {
            title: 'Status', dataIndex: 'status', key: 'status',
            // render: status => (
            //     <>
            //         {status.map(tag => {
            //             let color = tag;
            //             if (tag === 'approved') {
            //                 color = '#27AE60';
            //             } else if (tag === 'Pending') {
            //                 color = '#FFA800';
            //             } else if (tag === 'Dispatch') {
            //                 color = '#24C4CC';
            //             }
            //             return (
            //                 <Tag color={color} key={tag}>
            //                     {tag.toUpperCase()}
            //                 </Tag>
            //             );
            //         })}
            //     </>
            // ),
        },
        { title: '', dataIndex: 'name5', key: 'name5' },
        { title: '', dataIndex: 'name6', key: 'name6' },
    ];
    const data2 = [
        {
            key: 1,
            name1: '19891199',
            name2: 'Receipt ',
            name3: '2020-05-21',
            status: ['Approved'],
            name5: 'Dispatch',
            description2: <a>
                <Table
                    columns={columns1}
                    expandable={{
                        expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                    }}
                    dataSource={data1}
                />
            </a>
        },
        {
            key: 2,
            name1: '19891199',
            name2: 'Receipt ',
            name3: '2020-05-21',
            status: 'Approved',
            name5: 'Dispatch',

        },
    ];

    function TreeData() {
        const [checkStrictly, setCheckStrictly] = React.useState(false);
        return (
            <>

                <Table
                    columns={columns2}
                    expandable={{
                        expandedRowRender: record => <p style={{ margin: 0 }}>{record.description2}</p>,
                    }}
                    rowSelection={{ ...rowSelection, checkStrictly }}
                    dataSource={data2}
                />
            </>
        );
    }



    return (
        <div id="view-accounts-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-start m-ap-m-b-15 pad-l-r-30">
                    <Breadcrumbs paths={AllAccountsBreadcrumbItems} />
                </div>

                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-end m-ap-m-b-15 pad-l-r-30">
                    <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                        <div className="m-ap-input-f">
                            <select name="allJournal_filter" id="allJournal_filter">
                                <option value="All">Actions</option>
                                <option value="Posted">Create Receipt</option>
                                <option value="Posted">Create Dr./Cr. Note</option>
                                <option value="Posted">Generate Bill/Invoice</option>
                                <option value="Posted">Manual Matching Off</option>
                            </select>
                        </div>
                        <div className="m-ap-btn plush ml-4 mr-2" id="continue">Continue</div>
                        <div className="m-ap-btn plush">Cancel</div>
                    </div>
                </div>

                <div className={`m-ap-accordion  m-ap-m-b-15 ${accountDetails ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={accountdetails}>
                        <div className="m-ap-table-box">
                            <div className="m-ap-table" id="account-header">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="m-ap-account-id">Account ID</th>
                                            <th className="m-ap-total-debit">Total Dr. Amount</th>
                                            <th className="m-ap-total-credit">Total Cr. Amount</th>
                                            <th className="m-ap-net-ammount">Net Ammount</th>
                                            <th className="m-ap-status">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{mainAccountID}</td>
                                            <td>$ 25,000.00</td>
                                            <td>$ 15,000.00</td>
                                            <td>$ 10,000.00</td>
                                            <td>{accountInformation.Status}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        accountDetails &&
                        (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-table-box">
                                    <div className="m-ap-table">
                                        <div className="m-ap-title">Account Details</div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="m-ap-ledger-id">Ledger ID</th>
                                                    <th className="m-ap-active-from">Active From</th>
                                                    <th className="m-ap-credit">Active To</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{accountInformation.LedgerID}</td>
                                                    <td>{accountInformation.ActiveFrom.split("T")[0]}</td>
                                                    <td>{accountInformation.ActiveTo.split("T")[0]}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th className="m-ap-ledger-id">Contact Person</th>
                                                    <th className="m-ap-active-from">Contact Person Email</th>
                                                    <th className="m-ap-credit">Bank Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{contactDetails.ContactPerson}</td>
                                                    <td>{contactDetails.ContactPersonEmail}</td>
                                                    <td>{bankDetails.BankName}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th className="m-ap-acc-num">A/C Number</th>
                                                    <th className="m-ap-acc-name">Account Name</th>
                                                    <th className="m-ap-acc-name"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{bankDetails.AcccountNo}</td>
                                                    <td>Account Receivable</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>)}
                </div>


                <div className="m-ap-tabs m-ap-m-b-15">
                    <div className="m-ap-row">
                        <label className={`receipt-tab ${(currentTab === 'Sub_Accounts') ? 'tab-active' : ''}`} onClick={() => AccountTab('Sub_Accounts')}>
                            Sub Accounts
                        </label>
                        <label className={`receipt-tab ${(currentTab === 'Documents') ? 'tab-active' : ''}`} onClick={() => AccountTab('Documents')}>
                            Documents
                        </label>
                    </div>
                </div>



                {
                    currentTab === 'Sub_Accounts' && (
                        <div>
                            <div className="m-ap-search m-ap-m-b-15 pad-l-r-30">
                                <div className="m-ap-row search-elements">
                                    <div className="m-ap-input-f m-ap-dropdown">
                                        <label >Search Journal </label>
                                        <select name="search_by" id="search_by">
                                            <option value="">Search By</option>
                                            <option value="account">Invoice/PO Number</option>
                                            <option value="AccountName">Journal Number</option>
                                        </select>
                                    </div>
                                    <div className="m-ap-input-f m-ap-search-f">
                                        <label>&nbsp;</label>
                                        <input type="text" placeholder="Begin your search"
                                            id="search_value" name="search_value" />
                                    </div>
                                    <div className="m-ap-input-f m-ap-date">
                                        <label>Transaction Date</label>
                                        <input type="date" placeholder="Begin your search"
                                            id="from_date" name="from_date" />

                                    </div>
                                    <div className="m-ap-input-f m-ap-to">
                                        <label>To</label>
                                    </div>
                                    <div className="m-ap-input-f m-ap-date">
                                        <label>&nbsp;</label>
                                        <input type="date" placeholder="Begin your search"
                                            id="to_date" name="to_date"
                                        />
                                    </div>
                                    <div>
                                        <button id="receipt-search">Search</button>
                                        <button id="clear-filter">Clear Filter</button>
                                    </div>
                                    {/* <div className="m-ap-input-f m-ap-flex m-ap-action">
                            <div className="m-ap-btn m-ap-search m-ap-blue" id="receipt-search" onClick={searchManualJournal}>Search</div>
                            <div className="m-ap-btn" id="clear-filter" onClick={clearFilter}>Clear Filters</div>
                        </div> */}
                                </div>
                            </div>

                            <div className="m-ap-filter m-ap-flex m-ap-align-items-center m-ap-justify-content-start m-ap-m-b-15 pad-l-r-30">
                                <div className="m-ap-title" id="sub-account-title" >Sub Accounts</div>
                                {/* <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                                    <div className="m-ap-filter-text filter-status">Filter by : Tran. Type</div>
                                    <div className="m-ap-input-f">
                                        <select name="allJournal_filter" id="allJournal_filter">
                                            <option value="All">All</option>
                                            <option value="Posted">Premium</option>
                                            <option value="Posted">Receipt</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="m-ap-btn plush " id="sub-acc" onClick={subAccount}>Sub Account View</div>
                                <div className="m-ap-btn plush" id="trans" onClick={transaction}>Transaction View</div> */}
                            </div>

                            {
                                subAccountView &&
                                (
                                    <div className="pad-l-r-30">
                                        <div className="m-ap-table-box " id="sub-account">
                                            <div className="m-ap-table">
                                                {/* <Table
                                                    columns={columns}
                                                    expandable={{
                                                        expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                                                    }}
                                                    dataSource={data}
                                                /> */}
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "10%" }}></th>
                                                            <th style={{ width: "30%" }}>Sub Account #</th>
                                                            <th style={{ width: "40%", textAlign: "right" }}>Amount</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            Object.keys(subAccountList).map((subAccount, index) => (
                                                                <React.Fragment>
                                                                    <tr key={index} onClick={() => showHideTransactions(subAccount)} style={{ cursor: 'pointer' }}>
                                                                        <td>
                                                                            <div className="box grey"></div>
                                                                        </td>
                                                                        <td style={{ textAlign: "left" }}>{subAccount}</td>
                                                                        <td style={{ textAlign: "right" }}>{subAccountList[subAccount].Amount}</td>
                                                                    </tr>
                                                                    {
                                                                        subAccountList[subAccount].ShowHideChild && (
                                                                            <tr className='p-3'>
                                                                                <td colSpan={4}>
                                                                                    <table className='table table-borderless'>
                                                                                        <thead>
                                                                                            <th>Transaction ID</th>
                                                                                            <th>Sub Account</th>
                                                                                            <th>TypeRef 1</th>
                                                                                            <th>TypeRef 2</th>
                                                                                            <th>Tran. Type</th>
                                                                                            <th>Tran. Date</th>
                                                                                            <th>Amount</th>
                                                                                            <th>Balanace</th>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                subAccountList[subAccount].Transactions.map(Trans => (
                                                                                                    <tr>
                                                                                                        <td>{Trans.TranID}</td>
                                                                                                        <td>{Trans.SubAccount}</td>
                                                                                                        <td>{Trans.TagRef1}</td>
                                                                                                        <td>{Trans.TagRef2}</td>
                                                                                                        <td>{Trans.TranType}</td>
                                                                                                        <td>{showDate(Trans.TimeStamp)}</td>
                                                                                                        <td>{Trans.Amount}</td>
                                                                                                        <td>{Trans.Amount}</td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </React.Fragment>
                                                            ))
                                                        }

                                                    </tbody>

                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                transactionView && (
                                    <div className="pad-l-r-30">
                                        <div className="m-ap-table-box " id="sub-account">
                                            <div className="m-ap-table">
                                                <Table
                                                    columns={columns1}
                                                    rowSelection={{ ...rowSelection }}
                                                    expandable={{
                                                        expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                                                    }}
                                                    dataSource={data1}
                                                />
                                            </div>
                                        </div>
                                    </div>)}
                        </div>
                    )
                }
                {
                    currentTab === 'Documents' &&
                    (
                        <div>
                            <div className="m-ap-filter m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15 pad-l-r-30">
                                <div className="m-ap-title" id="sub-account-title">Documents</div>
                                <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                                    <div className="m-ap-filter-text filter-status">Filter by : Document Type</div>
                                    <div className="m-ap-input-f">
                                        <select name="allJournal_filter" id="allJournal_filter">
                                            <option value="All">All</option>
                                            <option value="Posted">Receipt Voucher</option>
                                            <option value="Posted">Receipt </option>
                                            <option value="Posted">Biling/Invoice</option>
                                            <option value="Posted">Dr./Cr. Note </option>

                                        </select>
                                    </div>
                                </div>
                                <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                                    <div className="m-ap-filter-text filter-status">Status</div>
                                    <div className="m-ap-input-f">
                                        <select name="allJournal_filter" id="allJournal_filter">
                                            <option value="All">All</option>
                                            <option value="All">Approved</option>
                                            <option value="Posted">Pending</option>
                                            <option value="Posted">Dispatch</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="pad-l-r-30">
                                <div className="m-ap-table-box " id="sub-account">
                                    <div className="m-ap-table">
                                        <TreeData />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }




            </div>
        </div >
    )
}

export default ViewAccounts;