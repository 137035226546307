// import api from 'lib/apiClient';
import {
    createAgentProps,
    createOwnerProps,
    createVehicleProps,
    createDriverProps,
    planFormProps,
    benefitFormProps,
    uwFormProps,
    paymentFormProps,
    policyIdProps,
    sparkDataProps
} from '../../Pages/MotorRisk/interfaces';

export const SET_SPARK_TOKEN = 'SET_SPARK_TOKEN';
export const SET_AGENT_DATA = 'SET_AGENT_DATA';
export const SET_OWNER_DATA = 'SET_OWNER_DATA';
export const SET_VEHICLE_DATA = 'SET_VEHICLE_DATA';
export const SET_DRIVER_DATA = 'SET_DRIVER_DATA';
export const SET_PLAN_DATA = 'SET_PLAN_DATA';
export const SET_BENEFIT_DATA = 'SET_BENEFIT_DATA';
export const SET_UW_DATA = 'SET_UW_DATA';
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';
export const SET_POLICYID_DATA = 'SET_POLICYID_DATA';
export const CLEAR_MOTOR_DATA = 'CLEAR_MOTOR_DATA';

export type MotorRiskActionType =
    | { type: string; payload: Promise<void> }
    | { type: string };


export const setSparkToken = (sparkData: sparkDataProps) => ({
    type: SET_SPARK_TOKEN,
    payload: sparkData
});

export const setAgentFormData = (agentData: createAgentProps) => ({
    type: SET_AGENT_DATA,
    payload: agentData
});

export const setOwnerFormData = (ownerData: createOwnerProps) => ({
    type: SET_OWNER_DATA,
    payload: ownerData
});

export const setVehicleFormData = (vehicleData: createVehicleProps) => ({
    type: SET_VEHICLE_DATA,
    payload: vehicleData
});

export const setDriverFormData = (driverData: createDriverProps) => ({
    type: SET_DRIVER_DATA,
    payload: driverData
});

export const setPlanFormData = (planData: planFormProps) => ({
    type: SET_PLAN_DATA,
    payload: planData
});

export const setBenefitFormData = (benefitData: benefitFormProps) => ({
    type: SET_BENEFIT_DATA,
    payload: benefitData
});

export const setUWFormData = (uwData: uwFormProps) => ({
    type: SET_UW_DATA,
    payload: uwData
});

export const setPaymentFormData = (paymentData: paymentFormProps) => ({
    type: SET_PAYMENT_DATA,
    payload: paymentData
});

export const setPolicyIdData = (policyIdData: policyIdProps) => ({
    type: SET_POLICYID_DATA,
    payload: policyIdData
});

export const clearMotorData = () => ({
    type: CLEAR_MOTOR_DATA,
    payload: {}
});
