import React from 'react';

interface IProps {
    
}

const CollapseContent: React.FC<IProps> = (props) => {
    return (
        <div className="collapse-content">
           {props.children}
        </div>
    )
}

export default CollapseContent;