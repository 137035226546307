import React from 'react';

import SideBar from 'layout/PrivateLayout/Sidebar';
import { MainLayout } from 'layout/PrivateLayout/styles';

// import ReceiptHeader from '../../Pages/Receipt/ReceiptHeader';

export interface IPrivateLayout {
  title: string;
  path: string;
}

const PrivateLayout: React.FC<IPrivateLayout> = props => {
  return (
    <MainLayout style={{ height: '100vh' }}>
      <SideBar path={props.path} />
      {/* <ReceiptHeader /> */}
      {props.children}
    </MainLayout>
  );
};

export default PrivateLayout;
