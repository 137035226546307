import api from "lib/apiClient";

export const TRANSACTION_GET = "TRANSACTION_GET";
export const TRANSACTION_ADJUSTMENT = "TRANSACTION_ADJUSTMENT";
export const TRANSACTION_CLEAR_DATA = "TRANSACTION_CLEAR_DATA";

export type TransactionActionType =
  | { type: string; payload: Promise<void> }
  | { type: string };

export interface IGLEntry {
  TranID: string;
  Ref: string;
  Dbacc: string;
  Db: string;
  Cracc: string;
  Cr: string;
  Stat: string;
}

export interface ITransaction {
  Methd: string;
  Funct: string;
  Cont: string;
  Args: any;
  Tranid: string;
  Dte: string;
  EndValue: number;
  Tvalue: number;
  Status: string;
  Adjustments: any;
  Gltran: Record<string, IGLEntry>;
  Notes: Array<string>;
  Documents: Array<string>
}

export interface ITransactionAdjust {
  Args: Array<string>;
  Tranid: string;
  Dte: string;
  Status: string;
  Notes: Array<string>;
}

export const getTransaction = (pid: string) => ({
  type: TRANSACTION_GET,
  payload: api.get(`newbusiness/PolicyHistory/${pid}`),
});

export const adjustTransaction = (pid: string, data: ITransactionAdjust) => ({
  type: TRANSACTION_ADJUSTMENT,
  payload: api.post(`updatebusiness/BackdatedAdjustment/${pid}`, { ...data }),
});

export const claimClearData = () => ({
  type: TRANSACTION_CLEAR_DATA,
});
