import { ledgerProps, searchLedgerprops } from "./interfaces"
import { BreadcrumbProps } from '../Components/interfaces';
import { eventProps } from "Pages/MotorRisk/interfaces";

export const AllLedgerBreadcrumbItems: BreadcrumbProps[] = [
    { title: "Policy 360", redirectTo: "/"},
    { title: "Finance", redirectTo: "/"},
    { title: "Ledgers", redirectTo: "/AllLedger"},
    { title: "All Ledgers", redirectTo: ""}
];

export const CreateLedgerBreadcrumbItems: BreadcrumbProps[] = [
    { title: "Policy 360", redirectTo: "/"},
    { title: "Finance", redirectTo: "/"},
    { title: "Ledgers", redirectTo: "/AllLedger"},
    { title: "Create New Ledger", redirectTo: ""}
];

export const formData: searchLedgerprops = {
    search_value: "",
    search_by: "",
    from_date: "",
    to_date: "",
    allLedger_filter: ""
};

export const sampleLedger :ledgerProps = {
    LedgerID: "",
    ActiveFrom: "",
    ActiveTo: "",
    Type: "",
    Tag1: "",
    Tag2: "",
    Description: "",
    Status: "",
    Data:""
}

export const showDate = (date: string) => {
    if (date) {
        return date.substring(0, 10);
    }
    return date;
}
export const parseDate = (date: Date) => {
    const DateToParse = date || new Date();
    return `${DateToParse.getFullYear()}-${DateToParse.getMonth() + 1}-${DateToParse.getDate()}`
}
export const allLedgerTable = [
    {
        title: 'Ledger ID',
        width: 100,
        dataIndex: 'LedgerID',
        key: 'LedgerID',
        onCell: (record, rowIndex: number) => {
            return {
                onClick: (ev: eventProps) => {
                    console.log(record, rowIndex);
                },
            };
        }
    },
    {
        title: 'Ledger Name',
        dataIndex: 'Description',
        key: 'Description',
        width: 100,
    },
    {
        title: 'Active From',
        dataIndex: 'ActiveFrom',
        key: 'ActiveFrom',
        width: 100,
    },
    {
        title: 'Active To',
        dataIndex: 'ActiveTo',
        key: 'ActiveTo',
        width: 100,
    },
    {
        title: 'Ledger Type',
        dataIndex: 'Type',
        key: 'Type',
        width: 100,
    },
    {
        title: 'TagRef1',
        dataIndex: 'Tag1',
        key: 'Tag1',
        width: 100
    },
    {
        title: 'TagRef2',
        dataIndex: 'Tag2',
        key: 'Tag2',
        width: 100
    },
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        width: 100
    },
    
    // {
    //     title: '',
    //     dataIndex: 'Modify',
    //     key: 'Modify',
    //     width: 100
    // },

];
