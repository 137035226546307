import api from 'lib/apiClient';
import { IClaim } from './ClaimAction';

export const POLICY_GET_QUOTE = 'POLICY_GET_QUOTE';
export const POLICY_NEW_POLICY = 'POLICY_NEW_POLICY';
export const POLICY_GET_POLICY = 'POLICY_GET_POLICY';
export const POLICY_GET_BY_PHONE = 'POLICY_GET_BY_PHONE';
export const POLICY_APPLY_PREMIUM = 'POLICY_APPLY_PREMIUM';
export const POLICY_ADJUST_QUOTE = 'POLICY_ADJUST_QUOTE';
export const POLICY_EDIT_RISK = 'POLICY_EDIT_RISK';
export const POLICY_REMOVE_RISK = 'POLICY_REMOVE_RISK';
export const POLICY_ADJUSTMENT = 'POLICY_ADJUSTMENT';
export const POLICY_SET_POLICY = 'POLICY_SET_POLICY';
export const POLICY_SET_PHONE = 'POLICY_SET_PHONE';
export const POLICY_CLEAR_DATA = 'POLICY_CLEAR_DATA';

export type PolicyActionType =
    | { type: string; payload: Promise<void> }
    | { type: string };

interface IBasicOwner {
    Dob: string;
    Name: string;
    Gender: string;
    Age: number;
    Id: string;
}

export interface IOwnerContact {
    Add: {
        Add1: string;
        Add2: string;
        Add3: string;
        Add4: string;
        City: string;
        Pcode: string;
        Country: string;
    };
    FbId: string;
    Email: string;
    Phone: string;
}

export interface IOwner {
    Dob: string;
    Name: string;
    Gender: string;
    Smoker: string;
    Id: string;
    Contact: IOwnerContact;
}

export interface IOption {
    Option: string;
    Included: string;
}

export interface ILimits {
    Cover: string;
    Descr: string;
    Benefit: string;
    ClaimLimit: number;
    PolicyLimit: number;
}

export interface IPremiumCollection {
    Reference: string;
    Premium: number;
    Collection_Date: string;
    Allocated: number;
    UnAllocated: number;
}

export interface IRisk {
    RiskType: string;
    PolicyId: string;
    RiskId: string;
    Riskclass: string;
}

export interface IBasicRisk extends IRisk {
    TravelBody?: IRiskBody;
    RiskBody?: IRiskBody;
}

export interface IRiskBody extends IRisk {
    Plan: string;
    CoverType: string;
    Sum_Insured: number;
    Adults: Array<IBasicOwner>;
    Children: Array<IBasicOwner>;
    TermUnits: string;
    Term: number;
    Region: string;
    Payment_Frequency: string;
    AdditionalCover?: any
    BasePremium: number;
    OptionalPremium: number;
    PremiumLevy: number;
    NetPremium: number;
    Installment_Premium: number;
    TotalPremium: number;
    AppliedPremium?: any;
    Risk_Comm: string;
    Risk_End: string;
    StampDuty: number;
    Gst: number;
    Status: string;
    UWStatus: string;
    Engine: string;
    Benefit_Limits?: Array<ILimits>;
    Options?: Array<IOption>;
    ClaimList?: Array<IClaim>;
}

export interface IPolicy {
    Product: string;
    ContID: string;
    Term: number;
    Channel: string;
    StartDate: string;
    EndDate: string;
    Status: string;
    PaymentFrequency: number;
    Owner: IOwner;
    Email: string;
    UWStatus: string;
    GrossAnnualPrem: number;
    NetAnnualPremium: number;
    ModalPremium: number;
    GST: number;
    PremiumReceipts: any;
    PremiumCollection: Array<IPremiumCollection> | null;
    InitRisks: Array<IBasicRisk>;
    Risks: Array<IBasicRisk> | null;
}

export interface IPolicyList {
    Owner: string;
    PolicyNo: string;
    Start: string;
    Type: string;
    Id: string;
}

export interface IPayment {
    premium: string,
    reference: string
}

export interface IAdjustedQuote {
    ModalPremiumDiff: number;
    BilledPremiumDiff: number;
    GstDiff: number;
    CoinsureDiff: number;
}

export const getQuote = (policy: IPolicy) => ({
    type: POLICY_GET_QUOTE,
    payload: api.post('newbusiness/DoQuotation', policy)
});

export const newPolicy = (policy: IPolicy) => ({
    type: POLICY_NEW_POLICY,
    payload: api.post('newbusiness/newpolicy', policy)
});

export const getPolicy = (pid: string) => ({
    type: POLICY_GET_POLICY,
    payload: api.get(`newbusiness/ContractDetails/${pid}`)
});

export const payment = (pid: string, payment: IPayment) => ({
    type: POLICY_APPLY_PREMIUM,
    payload: api.post(`updatebusiness/ApplyPremium/${pid}`, payment)
});

export const policyByPhone = (phone: string) => ({
    type: POLICY_GET_BY_PHONE,
    payload: api.get(`newbusiness/PolicyList/${phone}`)
});

export const adjustQuote = (pid: string, policy: IPolicy) => ({
    type: POLICY_ADJUST_QUOTE,
    payload: api.post(`updatebusiness/AdjustQuote/${pid}`, policy)
});

export const adjustPolicy = (pid: string, policy: IPolicy) => ({
    type: POLICY_ADJUSTMENT,
    payload: api.post(`updatebusiness/Adjustment/${pid}`, policy)
});

export const editRisk = (pid: string, adjustedRisk: IRiskBody) => ({
    type: POLICY_EDIT_RISK,
    payload: api.post(`updatebusiness/AlterRisk/${pid}`, { ...adjustedRisk })
});

export const removeRisk = (pid: string, riskID: string) => ({
    type: POLICY_REMOVE_RISK,
    payload: api.post(`updatebusiness/RemoveRisk/${pid}`, { riskID })
});

export const setPolicy = (pId: string) => ({
    type: POLICY_SET_POLICY,
    payload: pId
});

export const setPhone = (phone: string) => ({
    type: POLICY_SET_PHONE,
    payload: phone
});

export const policyClearData = () => ({
    type: POLICY_CLEAR_DATA
});
