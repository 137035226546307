import React from 'react';
import Moment from 'moment';
import find from 'lodash/find';
import { useForm, FormContext } from 'react-hook-form';

import { IClaims } from 'Pages/Claims/components/ClaimPage';
import ClaimFooter from 'Pages/Claims/components/ClaimListModal/ClaimFooter';
import { ModalContainer } from 'Pages/Policy/style';
import { ILimits } from 'store/actions/PolicyAction';
import { IClaim, IClaimItems } from 'store/actions/ClaimAction';
import Collapse, { CollapseContent } from 'common/Collapse';
import { Tag } from 'layout/PrivateLayout/styles';
import { ButtonContainer, Button } from 'common/style';

interface IClaimsModal extends Pick<IClaims,
    | 'fetching'
    | 'rejectAll'
    | 'approveAll'
    | 'approveClaimItem'
    | 'rejectClaimItem'> {
    policyId: string;
    riskId: string;
    claim: IClaim;
    benefits: Array<ILimits>;
    isVisible: boolean;
    onOK: () => void;
    onCancel: () => void;
}

const ClaimListModal: React.FC<IClaimsModal> = (props) => {
    const methods = useForm();

    const getBenefitLimit = (Benefit: string): ILimits | undefined => (
        find(props.benefits, { Benefit })
    )

    return (
        <ModalContainer
            onCancel={props.onCancel}
            maskClosable={false}
            width="80%"
            title={`Manage Claim / ${props.claim?.ClaimRef}`}
            visible={props.isVisible}
            footer={null}
        >
            <ButtonContainer>
                <Button
                    loading={props.fetching}
                    onClick={props.rejectAll}
                    style={{ marginRight: 8 }}>
                    Reject Claim
                </Button>
                <Button
                    loading={props.fetching}
                    onClick={props.approveAll}
                    className="primary"
                    style={{ marginLeft: 8 }}>
                    Approve Claim
                </Button>
            </ButtonContainer>
            <FormContext {...methods}>


                <Collapse title="Claim Details" collapse={true}>
                    <div style={{ padding: 24 }}>
                        <div><strong>Claim Reference: </strong>{props.claim?.ClaimRef}</div>
                        <div><strong>Claim Date: </strong>{Moment(props.claim?.ClaimDate).format('D-MMM-YYYY')}</div>
                        <div><strong>Status: </strong>{props.claim?.Status}</div>
                    </div>
                </Collapse>

                {
                    props.claim?.ClaimItems.map((item: IClaimItems, key: number) => (
                        <Collapse
                            key={key}
                            title={item.Benefit}
                            extra={<Tag className={item.Status.toLowerCase()}>{item.Status}</Tag>}>
                            <CollapseContent>
                                <div><strong>Benefit: </strong>{item.Benefit}</div>
                                <div>
                                    <strong>Benefit Limit: </strong>
                                    $ {new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2
                                    }).format(getBenefitLimit(item.Benefit)?.ClaimLimit || 0)}
                                </div>
                                <div><strong>Description: </strong>{getBenefitLimit(item.Benefit)?.Descr}</div>
                            </CollapseContent>
                            <CollapseContent>
                                <div>
                                    <strong>Time of Incident: </strong>
                                    {Moment(item.ClaimDate).format('D-MMM-YYYY')}
                                </div>
                                <div>
                                    <strong>Claim Amount: </strong>
                                    $ {new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2
                                    }).format(item.ClaimedAmount)}
                                </div>
                                <div><strong>Message: </strong> {item.Message}</div>
                                <div> Other claim details specific to the Benefit</div>
                            </CollapseContent>

                            <ClaimFooter
                                claimIndex={key}
                                claimItem={item}
                                fetching={props.fetching}
                                onApprove={props.approveClaimItem}
                                onReject={props.rejectClaimItem}
                            />
                        </Collapse>
                    ))
                }
            </FormContext>
        </ModalContainer>
    )
}

export default ClaimListModal;
