import {
    createAgentProps,
    createOwnerProps,
    createVehicleProps,
    createDriverProps,
    benefitFormProps,
    uwFormProps,
    uwFormProps1,
    paymentFormProps,
    policyIdProps,
    sparkDataProps,
    ownerValidationProps,
    vehicleValidationProps,
    agentValidationProps,
    driverValidationProps,
    benefitOptionProps,
    premiumCalcProps,

} from './interfaces';


export const sampleAgent: createAgentProps = {
    AgentName: "",
    AgentPhone: "",
    AgentEmail: "",
    AgentId: "",
    AgentChannel: ""
}


export const sampleOwner: createOwnerProps = {
    OwnerSearchBy: "",
    OwnerInput: "",
    OwnerTitle: "",
    OwnerName: "",
    OwnerSurname: "",
    OwnerDOB: "",
    CorporateName:"",
    OwnerGender: "",
    OwnerEmail: "",
    OwnerPhone: "",
    OwnerOccupation: "",
    OwnerClientID: "",
    OwnerType: "Individual",
    OwnerID: "",
    OwnerIDType: "",
    Nationality: "",
    OwnerFlat: "",
    OwnerFloor: "",
    OwnerBlock: "",
    OwnerStreet: "",
    OwnerBuilding: "",
    OwnerDistrict: "",
    OwnerArea: "",
    ResidentialStatus: "",
};
export const validateAgent: agentValidationProps = {
    AgentName: {
        isRequired: true,
        isValid: true,
    },
    AgentPhone: {
        isRequired: false,
        isValid: true,
    },
    AgentEmail: {
        isRequired: false,
        isValid: true,
    },
    AgentId: {
        isRequired: true,
        isValid: true,
    },
    AgentChannel: {
        isRequired: true,
        isValid: true,
    },

};
export const validateOwner: ownerValidationProps = {
    OwnerTitle: {
        isRequired: true,
        isValid: true,
    },
    OwnerName: {
        isRequired: true,
        isValid: true,
    },
    OwnerSurname: {
        isRequired: true,
        isValid: true,
    },
    OwnerDOB: {
        isRequired: true,
        isValid: true,
        checkFor: {
            ageLimit : true,
        },
        ageErrorMsg: 'Invalid Date'
    },
    OwnerGender: {
        isRequired: true,
        isValid: true,
    },
    CorporateName: {
        isRequired: false,
        isValid: true,
    },
    OwnerEmail: {
        isRequired: true,
        isValid: true,
        checkFor: {
            // emailFormat: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
            emailFormat:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
,            emailErrMsg: 'Invalid Email!'
        },
        errMsg: ''
    },
    OwnerPhone: {
        isRequired: true,
        isValid: true,
    },
    OwnerOccupation: {
        isRequired: false,
        isValid: true,
    },
    OwnerClientID: {
        isRequired: false,
        isValid: true,
    },
    OwnerType: {
        isRequired: false,
        isValid: true,
    },
    OwnerID: {
        isRequired: true,
        isValid: true,
    },
    OwnerIDType: {
        isRequired: true,
        isValid: true,
    },
    Nationality: {
        isRequired: false,
        isValid: true,
    },
    OwnerFlat: {
        isRequired: false,
        isValid: true,
    },
    OwnerFloor: {
        isRequired: false,
        isValid: true,
    },
    OwnerBlock: {
        isRequired: false,
        isValid: true,
    },
    OwnerStreet: {
        isRequired: false,
        isValid: true,
    },
    OwnerBuilding: {
        isRequired: false,
        isValid: true,
    },
    OwnerDistrict: {
        isRequired: true,
        isValid: true,
    },
    OwnerArea: {
        isRequired: false,
        isValid: true,
    },
    ResidentialStatus: {
        isRequired: true,
        isValid: true,
    }
}

export const validateVehicle: vehicleValidationProps = {
    Make: {
        isRequired: true,
        isValid: true,
    },
    ManufactureYear: {
        isRequired: true,
        isValid: true,
        checkFor: {
            validDate:true,
            validDateError: 'Invalid Date!'
        },
    },
    Model: {
        isRequired: true,
        isValid: true,
    },
    ModelVarient: {
        isRequired: false,
        isValid: true,
    },
    Capacity: {
        isRequired: false,
        isValid: true,
    },
    ChassisNo: {
        isRequired: false,
        isValid: true,
    },
    Colour: {
        isRequired: false,
        isValid: true,
    },
    EngineNo: {
        isRequired: false,
        isValid: true,
    },
    EngineSize: {
        isRequired: false,
        isValid: true,
    },
    EngineType: {
        isRequired: false,
        isValid: true,
    },
    Group: {
        isRequired: false,
        isValid: true,
    },
    Mileage: {
        isRequired: false,
        isValid: true,
    },
    RegistrationNo: {
        isRequired: true,
        isValid: true,
    },
    Scheme: {
        isRequired: false,
        isValid: true,
    },
    SeatingCapacity: {
        isRequired: false,
        isValid: true,
    },
    UsageType: {
        isRequired: true,
        isValid: true,
    },
    VehicleType: {
        isRequired: true,
        isValid: true,
    },
    NCDYears: {
        isRequired: true,
        isValid: true,
    },
}

export const validateDriver: driverValidationProps = {
    DriverTitle:{
        isRequired: false,
        isValid: true,
    },
    DriverName: {
        isRequired: false,
        isValid: true,
    },
    DriverSurname:{
        isRequired: false,
        isValid: true,
    },
    DriverPhone: {
        isRequired: false,
        isValid: true,
    },
    DriverDOB: {
        isRequired: false,
        isValid: true,
        checkFor:{
            ageLimit : true,
        },
        ageErrorMsg: 'DOB not valid',
    },
    DriverIdType: {
        isRequired: false,
        isValid: true,
    },
    DriverIdNumber: {
        isRequired: false,
        isValid: true,
    },
    DriverGender: {
        isRequired: false,
        isValid: true,
    },
    DriverLicenseDate: {
        isRequired: false,
        isValid: true,
        checkFor: {
            validDate:true,
            validDateError: 'Invalid Date!'
        },
    },
};

export const sampleVehicle: createVehicleProps = {
    Make: "",
    ManufactureYear: "",
    Model: "",
    ModelVarient: "",
    Capacity: "",
    ChassisNo: "",
    Colour: "",
    EngineNo: "",
    EngineSize: "",
    EngineType: "",
    Group: "",
    Mileage: "",
    RegistrationNo: "",
    Scheme: "",
    SeatingCapacity: "",
    UsageType: "",
    VehicleType: "",
    NCDYears: ""
}


export const sampleDriver: createDriverProps = {
    DriverTitle: "",
    DriverName: "",
    DriverSurname: "",
    DriverPhone: "",
    DriverDOB: "",
    DriverIdType: "",
    DriverIdNumber: "",
    DriverGender: "",
    DriverLicenseDate: "",
}

export const sampleBenefitData: benefitFormProps = {
    OptionalBenefits: []
}
export const sampleUWData1: uwFormProps1 = {
    "GrossPremium": null,
    "PromoCodeDiscount": null,
    "TotalCommission": null,
    "Levy": null,
    "TotalDue": null,
    "NetDue": null,
    "ModalPremium": null
}

// export const premiumCalcData: premiumCalcProps={
//     NewBusinessUI2:sampleUWData1[],
// }
export const sampleUWData: uwFormProps = {
    ques1: 'Y',
    ques2: 'N',
    NewBusinessUI2:[],
}

export const samplePaymentForm: paymentFormProps = {
    PaymentFrequency: "1.0",
    PaymentMethod: 'CreditCard',
    CardNumber: "3456 3456 4564 6565",
    ExpMonth: "09",
    ExpYear: "2024",
    cvv: "534"
}
export const samplePolicyId: policyIdProps = {
    PolicyId: "",
}


const TimeDiff = (start: Date, end: Date, term: string) => {
    const difference = end.getTime() - start.getTime();
    return Math.round(difference / (60 * 1000));
}

export const getToken = (tokenData: sparkDataProps) => {
    const now = new Date();
    if (!tokenData.timestamp || !tokenData.expires_in) {
        return '';
    }
    const timeDiff = TimeDiff(tokenData.timestamp, now, 'Minutes')
    if (Number(tokenData.expires_in) / 60 > Number(timeDiff)) {
        return tokenData.access_token;
    }
    return '';
}

export const getYearsList = () => {
    const currentYear = (new Date()).getFullYear();
    const yearList = [];
    for (let a = 0; a <= 20; a++) {
        yearList.push(currentYear - a);
    }
    return yearList;
}

export const premiumCalcPayload = (formData: any) => {
    const { AgentFormData, OwnerFormData, VehicleFormData, DriverFormData, PlanFormData, BenefitFormData, PaymentFormData } = formData;
    const driverDob = new Date(DriverFormData.DriverDOB);
    const payload = {
        "Inputs": {
            "BillPeriodEnd": "2022-10-20",
            "BillPeriodStart": "2021-10-20",
            "BrokerID": null,
            "CancelAllocatedPremium": 100.0,
            "CancellationDate": "2022-05-20",
            "CancelRef": null,
            "Channel": null,
            "ChassisNo": VehicleFormData.ChassisNo,
            "ClaimAmount": [
                {
                    "Sno": 1.0,
                    "Benefit": "MOT5",
                    "Amount": 200.0,
                    "EventType": null,
                    "EventStart": "2021-05-20",
                    "EventEnd": null,
                    "Claimant": null
                },
                {
                    "Sno": 2.0,
                    "Benefit": "MOT1",
                    "Amount": 20000.0,
                    "EventType": null,
                    "EventStart": "2021-05-21",
                    "EventEnd": null,
                    "Claimant": null
                }
            ],
            "ClaimApproveAmount": 1000.0,
            "ClaimHistory": [],
            "ClaimRef": null,
            "ClaimRejectAmount": 1000.0,
            "ClaimStatus": null,
            "ClaimToDate": [],
            "ClmStatus": "Initiate",
            "Country": "thailand",
            "CoverType": null,
            "DriverAge": 27.0,
            "DriverDOB": driverDob,
            "DriverGender": null,
            "EngineNo": VehicleFormData.EngineNo,
            "EngineSize": VehicleFormData.EngineSize,
            "EngineType": VehicleFormData.EngineType,
            "Excess": null,
            "LicenseDate": null,
            "Make": VehicleFormData.Make,
            "ManufactureDate": `${VehicleFormData.ManufactureYear}T00:00:36.940641599Z`,
            "Mileage": VehicleFormData.Mileage,
            "Model": VehicleFormData.Model,
            "NamedDrivers": [],
            "NCDYears": Number(VehicleFormData.NCDYears),
            "OptionalBenefits": BenefitFormData.OptionalBenefits,
            "PaymentFrequency":1,
            "Plan": PlanFormData.Option,
            "PolicyNo": "",
            "PromoCode": null,
            "Purchase_Date": null,
            "Ratings": [
                {
                    "RatingFactor": "area",
                    "Value": 0.0
                }
            ],
            "Reference2": null,
            "Scheme": VehicleFormData.Scheme,
            "SeatingCapacity": VehicleFormData.SeatingCapacity,
            "StartDate": (new Date()).toISOString().split('T')[0],
            "Sum_Insured": null,
            "Term": null,
            "UsageType": null,
            "UWQuestions": [],
            "Vage": 8.0,
            "VehicleGroup": null,
            "VehicleLocation": null,
            "VehicleType": VehicleFormData.VehicleType
        }
    }
    // console.log("this is input payload",payload)
    return payload;

}
const endDate = () => {
    const newDate = new Date();
    let day = newDate.getDate()-1;
    let month = newDate.getMonth()+1;
    let year = newDate.getFullYear()+1;
    console.log("this is year", year, month, day);
    return `${year}-${(month < 9) ? '0' + month : month}-${day}`;
}
export const getNewPolicyPayload = (formData: any) => {
    const { AgentFormData, OwnerFormData, VehicleFormData, DriverFormData, PlanFormData, BenefitFormData, PaymentFormData } = formData;
    console.log("This is form Data", OwnerFormData);
    const driverDob = new Date(DriverFormData.DriverDOB);

    let NameOfOwner;
    if(OwnerFormData.OwnerType == "Corporate"){
        NameOfOwner = OwnerFormData.CorporateName
    }else{
        NameOfOwner = OwnerFormData.OwnerName
    }
    const presentDate = new Date();
    const policyEndDate = new Date(endDate());
    const payload = {
        "Header": {
            "Product": "Motor:Motor",
            "Term": 1,
            "Channel": "Direct",
            "Agent": AgentFormData.AgentId,
            "AgentName": AgentFormData.AgentName,
            "Email": AgentFormData.AgentEmail,
            "StartDate": presentDate,
            "EndDate": policyEndDate,
            "PaymentMethod": PaymentFormData.PaymentMethod,
            "PaymentFrequency": Number(PaymentFormData.PaymentFrequency),
            "InstallmentPremium": 65000,
            "PaymentReference": "",
            "PreferredLanguage": "English",
            "PromoCode": "",
            "ReferenceCode": "",
            "EmailPreferance": "e-policy",
            "Plan": "Plan1",
            "Mandate": {
                "MandateRefNo": "000546",
                "MandateStatus": "Pending",
                "CreditCard": {
                    "BankRefNum": "6867",
                    "CardNumber": PaymentFormData.CardNumber,
                    "CardType": "Master",
                    "ExpiryMonth": PaymentFormData.ExpMonth,
                    "ExpityYear": PaymentFormData.ExpYear
                }
            },
            "Owner": {
                "Occupation": "software",
                "Nationality": OwnerFormData.Nationality,
                "Race": "Thailand",
                "Data": null,
                "Title": OwnerFormData.OwnerTitle,
                "Name": NameOfOwner,
                "GivenName": OwnerFormData.OwnerSurname,
                "Gender": OwnerFormData.OwnerGender,
                "Dob": OwnerFormData.OwnerDOB,
                // "Smoker": "N",
                "ResidentialStatus": OwnerFormData.ResidentialStatus,
                "ClientType": OwnerFormData.OwnerType,
                "ID": [
                    {
                        "IDType": OwnerFormData.OwnerIDType,
                        "IDNumber": OwnerFormData.OwnerID,
                        "IssueDate": "2020-01-20",
                        "ExpiryDate": "2025-02-08",
                        "IssuingAgency": "abcd"
                    }
                ],
                "Contact": {
                    "Email": OwnerFormData.OwnerEmail,
                    "Phone": OwnerFormData.OwnerPhone,
                    "FbId": "",
                    "Add": {
                        "Add1": OwnerFormData.OwnerFlat,
                        "Add2": OwnerFormData.OwnerFloor,
                        "Add3": OwnerFormData.OwnerBlock,
                        "Add4": OwnerFormData.OwnerStreet,
                        "Add5": OwnerFormData.OwnerBuilding,
                        "City": OwnerFormData.OwnerDistrict,
                        "Country": "",
                        "Pcode": OwnerFormData.OwnerArea
                    }
                }
            },
            "Data": [
                {
                    "PreferredLanguage": "English",
                    "Hostname": "http://localhost:8100//web/policy",
                    "User": "admin1234",
                    "Header_StartDate": "2021-12-14",
                    "Header_EndDate": "2023-04-20",
                    "RenewalType": "Manual"
                }
            ]
        },
        "Risks": [
            {
                "RiskType": "MT",
                "RiskId": "1",
                "Riskclass": "CO",
                "CoverType": "Motor:Motor",
                "Risk_Comm": presentDate,
                "RiskBody": {
                    "RiskType": "MT",
                    "RiskId": "1",
                    "Riskclass": "CO",
                    "Plan": PlanFormData.Option,
                    "CoverType": "NH_Test:Motor",
                    "Country": "Thailand",
                    "Ratings": [
                        {
                            "RatingFactor": "Offence Free Discount",
                            "Value": "0.5"
                        },
                        {
                            "RatingFactor": "No. of accidents in last 3 years",
                            "Value": "1"
                        }
                    ],
                    "Vehicle": {
                        "Capacity": VehicleFormData.Capacity,
                        "ChassisNo": VehicleFormData.ChassisNo,
                        "Colour": VehicleFormData.Colour,
                        "Data": null,
                        "DateOfManufacture": (new Date(VehicleFormData.ManufactureYear)).toISOString(),
                        "EngineNo": VehicleFormData.EngineNo,
                        "EngineSize": VehicleFormData.EngineSize,
                        "EngineType": VehicleFormData.EngineType,
                        "Group": "",
                        "Location": {
                            "LocationCode": "loc"
                        },
                        "Make": VehicleFormData.Make,
                        "Mileage": VehicleFormData.Mileage,
                        "Model": VehicleFormData.Model,
                        "RegistrationNo": VehicleFormData.RegistrationNo,
                        "Scheme": VehicleFormData.Scheme,
                        "SeatingCapacity": VehicleFormData.SeatingCapacity,
                        "UsageType": VehicleFormData.UsageType,
                        "VehicleType": VehicleFormData.VehicleType,
                        "NCDYears": Number(VehicleFormData.NCDYears)
                    },

                    "InsuredParty": {
                        "Name": "FirstName",
                        "GivenName": "LastName",
                        "Id": {
                            "IDType": "National ID",
                            "IDNumber": "4356789707958892",
                            "IssueDate": "2020-01-20",
                            "ExpiryDate": "2025-02-08",
                            "IssuingAgency": "abcd"
                        },
                        "Dob": "2003-07-02T00:00:00Z",
                        "Gender": "M",
                        "LicenseDate": "2020-01-02",
                        "Occupation": "Job",
                        "NCDYears": 0
                    },
                    "NamedDrivers": [
                        {
                            "Title": DriverFormData.DriverTitle,
                            "Name": DriverFormData.DriverName,
                            "GivenName": DriverFormData.DriverSurname,
                            "Id": {
                                "IDType": DriverFormData.DriverIdType,
                                "IDNumber": DriverFormData.DriverIdNumber,
                                "IssueDate": "2020-01-20",
                                "ExpiryDate": "2025-02-08",
                                "IssuingAgency": "abcd"
                            },
                            "Dob": driverDob,
                            "Gender": DriverFormData.DriverGender,
                            "LicenseDate": DriverFormData.DriverLicenseDate,
                            "Occupation": "Job",
                            "NCDYears": 0
                        }
                    ],
                    "TermUnits": "years",
                    "Term": 1,
                    "PaymentTerm": 1,
                    "Payment_Frequency": PaymentFormData.PaymentFrequency,
                    "AdditionalCover": [],
                    "NcdYears": 0,
                    "Excess": 0,
                    "BasePremium": 0,
                    "OptionalPremium": 0,
                    "Risk_Comm": presentDate,
                    "Risk_End": policyEndDate,
                    "Sum_Insured": Number(BenefitFormData.SumInsured),
                    "Options": BenefitFormData.OptionalBenefits
                },
                "Data": []
            }
        ]
    }
    console.log("This is policy payload",payload);
    return payload;
}

export const currencyFormat = (value: number, currency: string = 'SGD') => {
    if (isNaN(value)) {
        return (' - ');
    }
  return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: currency
        }).format(value);
}
