import api from 'lib/apiClient';

export const CLAIM_CREATE = 'CLAIM_CREATE';
export const CLAIM_UPDATE = 'CLAIM_UPDATE';
export const CLAIM_CLEAR_DATA = 'CLAIM_CLEAR_DATA';

export type ClaimActionType =
    | { type: string; payload: Promise<void> }
    | { type: string };

export interface INewClaim {
    riskID: string;
    claim: IClaim;
}

export interface IClaim {
    ClaimRef: string;
    ClaimDate: string;
    Status: string;
    ClaimTotals: number | null;
    Data: any;
    ClaimItems: Array<IClaimItems>;
}

export interface IClaimItems {
    ClaimDate: string;
    Benefit: string;
    ClaimedAmount: number;
    SettledAmount: number;
    Status: string;
    Message: string
}

export interface IClaimUpdate {
    RiskID: string;
    ClaimRef: string;
    ClaimIndex: number;
    Claim: IClaimItems;
}

export const createClaim = (pid: string, claim: INewClaim) => ({
    type: CLAIM_CREATE,
    payload: api.post(`updatebusiness/InitiateClaim/${pid}`, claim)
});

export const updateClaim = (pid: string, updateClaimLists: Array<IClaimUpdate>) => ({
    type: CLAIM_UPDATE,
    payload: api.post(`updatebusiness/UpdateClaim/${pid}`, { updateClaimLists })
});

export const claimClearData = () => ({
    type: CLAIM_CLEAR_DATA
});
