import { IFetch, InitializeState } from './index';
import {
    ClaimActionType,
    CLAIM_CLEAR_DATA
} from 'store/actions/ClaimAction';

interface IState extends IFetch {
    
}

export const ClaimInitialState: IState = {
    fetching: false,
    fetched: false,
    error: null,
};

export type ClaimType = typeof ClaimInitialState;

const initiateClaimReducer = (
    state: ClaimType = ClaimInitialState,
    action: ClaimActionType,
): IState => {
    state = InitializeState(state, action, ClaimInitialState);

    switch (action.type) {

        case CLAIM_CLEAR_DATA: {
            return {
                ...state
            }
        }

        default:
            return state;
    }
};

export default initiateClaimReducer;
