import React, { useState, useEffect } from 'react';
import { navigate } from 'raviger';
import './PlanDetails.styles.scss';
import endPoint from 'config/urls';
import { useGlobalState, apiRequest } from 'store';
import { setPlanFormData, setSparkToken } from 'store/actions/MotorRiskAction';

import { planFormProps } from '../interfaces';
import { getToken } from '../MotorController';
import { ReactComponent as BackArrow } from 'assets/images/backArrow.svg';

const PlanDetails = () => {
    const [{ SparkData }] = useGlobalState('MotorRiskReducer');

    const [planList, setPlanList] = useState<planFormProps[]>([]);

    useEffect(() => {
        let AuthorizationToken = getToken(SparkData);
        if (!AuthorizationToken) {
            fetchTokenData().then((response) => {
                vehicleOptions(response);
            });
        } else {
            vehicleOptions(AuthorizationToken);
        }
    }, [SparkData]);

    const fetchTokenData = async () => {
        const url = `${endPoint.keycloakURL}`;

        const tokenData = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': "application/x-www-form-urlencoded",
            }
        }).then((response) => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    apiRequest(setSparkToken({
                        access_token: data.access_token,
                        expires_in: data.expires_in,
                        timestamp: new Date()
                    }));
                    return data.access_token;
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return tokenData;
    }

    const vehicleOptions = async (AuthorizationToken: string) => {
        const url = `${endPoint.motorExcelEngine}`;
        let date =new Date();
        const TransDate = date.toISOString();
        const pfPayload = {
            "Inputs": {},
            "TransactionDate": TransDate,
            "EngineType": "Type3",
            "Solves": [],
            "RequestedServiceCategory": "Options"
        }
        return await fetch(url, {
            method: "POST",
            body: JSON.stringify(pfPayload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthorizationToken}`
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    const OptionsArr = data.data.Outputs;
                    // setVehicleOptionList(OptionsArr);
                    setPlanList(OptionsArr["Options.Plan"]);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const goToBenefitDetails = (planOption: planFormProps) => {
        apiRequest(setPlanFormData(planOption)); // set plan data in reducer
        navigate('/BenefitDetails');
    }

    const goToDriverDetails = () => {
        navigate('/DriverDetails');
    }
    return (
        <div id="plan-details-container">
            <div className='container'>
                <div className='d-flex justify-contents-start'>
                    <BackArrow className='center' onClick={goToDriverDetails} />
                    <h3 className='m-4'>Select your Plan</h3>
                </div>
                <div className='container d-flex'>
                    <div className='plan-container'>
                        <div className='color-style'>
                            &nbsp;
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container'>
                            <div className='d-flex justify-content-center mt-5 mb-5'>

                                {planList.map((option, index) => (
                                    <div key={index} className='plan-box p-3 m-4' onClick={() => goToBenefitDetails(option)}>
                                        <div className='d-flex'>
                                            <div className='w-75'>
                                                <p className='plan-name'>{option.Option}</p>
                                            </div>
                                            <div className='w-25'>
                                                <p className='plan-code'>M150</p>
                                            </div>
                                        </div>
                                        <div className=''>
                                            {/* <div className='plan-info'>MOST POPULAR</div> */}
                                        </div>
                                        <div className='m-2 plan-benefits'>
                                            <ul className='mt-3'>
                                                <li>{option.Desc}</li>
                                                {/* <li>30,000 Sum Insured</li>
                                                <li>1500 excess</li>
                                                <li>Minor Illness Coverage</li>
                                                <li>Third Party Liability</li>
                                                <li>30,000 Sum Insured</li>
                                                <li>1500 excess</li>
                                                <li>Minor Illness Coverage</li> */}
                                            </ul>
                                        </div>
                                        <div>
                                            <div className='read-more'>Read more</div>
                                        </div>
                                    </div>
                                )
                                )}




                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlanDetails;