import styled, { css } from 'styled-components';
import { Row, Select, DatePicker, Button as AButton } from 'antd';

const color = {
  Aqua: '#24c4cc',
  Dark: '#201a3d',
  Red: '#BC4E4E',
  Gray: '#545454',
};

export const Container = styled.div`
  padding: 30px;
`;

export const Header = styled(Row)`
  font-size: 20px;
  line-height: 23px;
  color: ${color.Dark};
  flex-direction: column;
  margin-bottom: 30px;
  a {
    color: ${color.Aqua};
    font-size: 18px;
    line-height: 32px;
    text-decoration-line: underline;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
`;

export const HeaderTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 0;
`;

export const SearchBarContainer = styled.div`
  border: 1px solid rgba(32, 26, 61, 0.2);
  border-radius: 4px;
`;

export const SearchBar = styled.input`
  background: #ffffff;
  min-width: 300px;
  border: 0;
  padding: 4px;
  outline: 0;
  border-right: 0;
  padding-left: 5px;
`;

export const StyleButton = css`
  border: 0;
`;

export const TableStyle = css`
  tr > th {
    background-color: rgba(36, 196, 204, 0.2);
    border-bottom: 0;
  }
  .ant-table-column-title,
  .ant-table-tbody {
    font-size: 16px;
    color: #201a3d;
    a {
      color: #24c4cc;
    }
  }
  .ant-pagination-item-active {
    background: #24c4cc;
    border: 1px solid #24c4cc;
    a {
      color: #fff;
    }
  }
  .ant-pagination-item,
  .ant-pagination-item-link {
    &:hover {
      border-color: #24c4cc;
      color: #24c4cc;
    }
  }
`;

export const CardContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(32, 26, 61, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
`

export const CardInfo = styled(Row)`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 18px;
  color: ${color.Dark};
  margin: 15px 0 25px;
`

export const CardInfoLabel = styled.div`
  padding-bottom: 5px;
`

export const CardInfoValue = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: rgba(32, 26, 61, 0.6);
  a {
    text-decoration-line: underline;
    color: ${color.Aqua};
  }
  input[type=checkbox] {
    width: 14px;
    height: 14px;
  }
`

export const Input = styled.input`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 44px;
  padding: 7px 18px;
  margin: 5px 0;
  color: rgba(32, 26, 61, 0.4);
  font-size: 16px;
  line-height: 18px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: 0;
  
  ::placeholder {
    color: #cccccc;
  }

  :read-only{
    background-color: rgb(240, 242, 245);
  }
`

export const Textarea = styled.textarea`
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 100px;
  padding: 7px 18px;
  margin: 5px 0;
  color: rgba(32, 26, 61, 0.4);
  font-size: 18px;
  line-height: 21px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: 0;

  ::placeholder {
    color: #cccccc;
  }

  :read-only{
    background-color: rgb(240, 242, 245);
  }
`

export const Dropdown = styled(Select)`
  .ant-select-selection {
    height: 44px;
    padding: 7px 18px;
    color: rgba(32, 26, 61, 0.4);
    font-size: 16px;
    line-height: 18px;
  }
  .ant-select-selection__rendered {
    margin-left: 0px;
  }
`
export const StyleDatePicker = styled(DatePicker)`
  width: 100%;
  .ant-calendar-picker-input {
    height: 44px;
    font-size: 16px;
    line-height: 18px;
    color: rgba(32, 26, 61, 0.4);
  }
`

export const DateRange = styled(DatePicker.RangePicker)`
  width: 100%;
  .ant-calendar-picker-input {
    height: 44px;
    font-size: 16px;
    line-height: 18px;
    color: rgba(32, 26, 61, 0.4);
  }
`

const ButtonCss = css`
  border: 1px solid #A0A0A0;
  border-radius: 4px;
  color: #A0A0A0;
  background-color: #fff;
  font-weight: bold;
  padding: 10px 30px;
  outline: 0;
  cursor: pointer;
  height: 44px;

  &.primary {
    background-color: ${color.Aqua};
    color: #fff !important;
    border: 1px solid ${color.Aqua};
  }

  &.cancel {
    background-color: ${color.Red};
    color: #fff !important;
    border: 1px solid ${color.Red};
  }
  :disabled,
  :disabled:hover {
    background-color: ${color.Gray};
    color: #fff !important;
    border: 1px solid ${color.Gray};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
  button {
    ${ButtonCss}
  }
`

export const Button = styled(AButton)`
  ${ButtonCss}
`

export const ErrorInfo = styled.span`
  font-size: small;
  color: red;
`