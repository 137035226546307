import React, { useState, useEffect } from 'react';

interface IProps {
    title: string;
    isUp: boolean;
    extra?: any;
    onToggle?: (hide: boolean) => void;
}

const CollapseHeader: React.FC<IProps> = (props) => {
    const [arrow, setArrow] = useState(props.isUp ? 'up' : 'down');

    useEffect(() => {
        props.isUp ? setArrow('up') : setArrow('down');
    }, [props.isUp])

    const handleChevron = () => {
        arrow === 'down' ? setArrow('up') : setArrow('down');
        if (props.onToggle) {
            arrow === 'down' ? props.onToggle(true) : props.onToggle(false);
        }

    }

    return (
        <div className="collapse-header">
            <div className="collapse-header-title">
                {props.title} &nbsp; &nbsp;
                {props?.extra}
            </div>
            <div className="collapse-header-control">
                <i className={`zmdi zmdi-chevron-${arrow}`} onClick={handleChevron} />
            </div>
        </div>
    )
}

export default CollapseHeader;