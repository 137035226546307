import React, { useState } from 'react';
// import { Table, Tag, Space } from 'antd';
// import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import {
    eventProps, createPolicyProps
} from '../interfaces';
import { sampleAccount, getNewPolicyPayload } from '../ProductsController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreatePolicy.styles.scss';


const CreatePolicy = () => {

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [accountData, setAccountData] = useState<createPolicyProps>(sampleAccount);
    const [accountNumber, setAccountNumber] = useState<string>("");

    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const newAccData = { ...accountData };
        newAccData[name] = value;
        setAccountData(newAccData);
    }

    const SubmitAccount = () => {
        const accountFormData = getNewPolicyPayload(accountData);

        const url = 'https://newbusiness.demo.sonic.dev.coherent.global/v1/newpolicy';

        fetch(url, {
            method: "POST",
            body: JSON.stringify(accountFormData),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZ3JvdXBzIjpbImFkbWluIiwiaGVhbHRoIl0sImlhdCI6MTUxNjIzOTAyMn0.CMEQlrAI3_MbWZJ1RF0qtvoZi6ZOHKduY3zyIXn8N-o'
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                console.log(data);
                if (statusCode === 200) {
                    setAccountNumber(data.PolicyID);
                    setShowSuccessModal(true);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
        // .catch((error) => {
        //     console.log(error);
        // });
    }

    const copyAccountNumber = () => {
        const text = accountNumber;
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy')
        textField.remove();
    }

    const closeModal = () => {
        setShowSuccessModal(false);
        // gotoAllAccount();
    }

    // const gotoAllAccount = () => {
    //     navigate('/AllAccounts')
    // }

    return (
        <div id="create-new-account-container">
            <div className="m-ap-wrap">
                <div className="m-ap-title">Unit Link Insurance</div>
                <div className={`m-ap-accordion m-ap-m-b-15 m-ap-active`}>
                    <div className="m-ap-ac-content pt-4">
                        <div className="m-ap-form">
                            <div className="m-ap-row">
                                <div className="m-ap-col-3 m-ap-input-f mb-4 ">
                                    <label className="m-ap-label">Insured Name *</label>
                                    <input type="text" placeholder="eg. Neo Macheline"
                                        name="InsuredName" id="InsuredName" value={accountData.InsuredName}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>
                                <div className="m-ap-col-3 m-ap-input-f mb-4 ">
                                    <label className="m-ap-label">Insured IC *</label>
                                    <input type="text" placeholder="E"
                                        name="InsuredIC" id="InsuredIC" value={accountData.InsuredIC}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Date of Birth *</label>
                                    <input type="date" placeholder="YYYY/MM/DD"
                                        name="InsuredDob" id="InsuredDob" value={accountData.InsuredDob}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Gender</label>
                                    <select name="Gender" id="Gender" value={accountData.Gender} onChange={(e) => handleUpdate(e)} >
                                        <option value="">---Select Gender ---</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Owner Phone</label>
                                    <input type="text" placeholder="Phone number"
                                        name="Phone" id="Phone" value={accountData.Phone}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Owner Email *</label>
                                    <input type="text" placeholder="Email"
                                        name="Email" id="Email" value={accountData.Email}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Plan</label>
                                    <select name="PlanType" id="PlanType" value={accountData.PlanType} onChange={(e) => handleUpdate(e)} >
                                        <option value="">--- Select Plan ---</option>
                                        <option value="Standard Plan">Standard Plan</option>
                                        <option value="Premium Plan">Premium Plan</option>
                                    </select>
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Term</label>
                                    <select name="PolicyTerm" id="PolicyTerm" value={accountData.PolicyTerm} onChange={(e) => handleUpdate(e)} >
                                        <option value="">--- Select Term ---</option>
                                        <option value="5">5 Years</option>
                                        <option value="10">10 Years</option>
                                        <option value="15">15 Years</option>
                                    </select>
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Payment Frequency</label>
                                    <select name="PaymentFrequency" id="PaymentFrequency" value={accountData.PaymentFrequency} onChange={(e) => handleUpdate(e)} >
                                        <option value="">--- Select Term ---</option>
                                        <option value="1">Annualy</option>
                                        <option value="2">Half Yearly</option>
                                        <option value="4">Quarterly</option>
                                        <option value="12">Monthly</option>
                                    </select>
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Fund Allocation</label>
                                    {/* <Table dataSource={fundAllocationDataSource} columns={fundAllocationTableColumn} pagination={false} /> */}

                                    <div className="ant-table-wrapper">
                                        <div className="ant-spin-nested-loading">
                                            <div className="ant-spin-container">
                                                <div className="ant-table">
                                                    <div className="ant-table-container">
                                                        <div className="ant-table-content">
                                                            <table >
                                                                <colgroup></colgroup>
                                                                <thead className="ant-table-thead">
                                                                    <tr>
                                                                        <th className="ant-table-cell">Fund</th>
                                                                        <th className="ant-table-cell">% Allocation</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ant-table-tbody">
                                                                    <tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
                                                                        <td className="ant-table-cell">USD Cash fund</td>
                                                                        <td className="ant-table-cell">
                                                                            <input type="text" placeholder="%"
                                                                                name="Opt1" id="Opt1" value={accountData.Opt1}
                                                                                onChange={(e) => handleUpdate(e)} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr data-row-key="2" className="ant-table-row ant-table-row-level-0">
                                                                        <td className="ant-table-cell">Platinum Bond Fund</td>
                                                                        <td className="ant-table-cell">
                                                                        <input type="text" placeholder="%"
                                                                                name="Opt2" id="Opt2" value={accountData.Opt2}
                                                                                onChange={(e) => handleUpdate(e)} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr data-row-key="3" className="ant-table-row ant-table-row-level-0">
                                                                        <td className="ant-table-cell">Global Share</td>
                                                                        <td className="ant-table-cell">
                                                                        <input type="text" placeholder="%"
                                                                                name="Opt3" id="Opt3" value={accountData.Opt3}
                                                                                onChange={(e) => handleUpdate(e)} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr data-row-key="4" className="ant-table-row ant-table-row-level-0">
                                                                        <td className="ant-table-cell">SPX Index</td>
                                                                        <td className="ant-table-cell">
                                                                        <input type="text" placeholder="%"
                                                                                name="Opt3" id="Opt3" value={accountData.Opt3}
                                                                                onChange={(e) => handleUpdate(e)} />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Start Date *</label>
                                    <input type="date" placeholder="YYYY/MM/DD"
                                        name="StartDate" id="StartDate" value={accountData.StartDate}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Address *</label>
                                    <input type="text" placeholder="Address Line 1"
                                        name="Add1" id="Add1" value={accountData.Add1}
                                        onChange={(e) => handleUpdate(e)} className='mb-1' />
                                    <input type="text" placeholder="Address Line 2"
                                        name="Add2" id="Add2" value={accountData.Add2}
                                        onChange={(e) => handleUpdate(e)} className='mb-1' />
                                    <input type="text" placeholder="Address Line 3"
                                        name="Add3" id="Add3" value={accountData.Add3}
                                        onChange={(e) => handleUpdate(e)} className='' />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">City *</label>
                                    <input type="text" placeholder="City"
                                        name="City" id="City" value={accountData.City}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Post Code *</label>
                                    <input type="text" placeholder="Post Code"
                                        name="PostCode" id="PostCode" value={accountData.PostCode}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Premium</label>
                                    <input type="text" placeholder="Premium Amount"
                                        name="Premium" id="Premium" value={accountData.Premium}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div className="m-ap-bottom">
                <button id="create-submit" onClick={SubmitAccount}>Create Policy</button>
                <button id="cancel">Cancel</button>
            </div>

            {
                showSuccessModal && (
                    <div className="modal1" id="myModal">
                        <div className="modal-content1">
                            <div className="col-md-12 mb-2 download-receipt-title1">
                                <span>
                                    Policy Created
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    Policy has been created successfully!
                                </span>
                                <br />
                                <span className="entity-number">{accountNumber}&nbsp;</span>
                                <span className="receipt-copy" onClick={copyAccountNumber}>Copy</span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt-close" onClick={closeModal}>Close</button>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                )
            }
        </div >
    )
}

export default CreatePolicy;