import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
import endPoint from 'config/urls';
import './AgentDetails.styles.scss';
import { useGlobalState, apiRequest } from 'store';
import { setAgentFormData, setSparkToken } from 'store/actions/MotorRiskAction';
import { ReactComponent as BackArrow } from 'assets/images/backArrow.svg';

import { eventProps, createAgentProps, selectAgentProps, agentValidationProps } from '../interfaces';
import { getToken, validateAgent } from '../MotorController';
import { IsKeyCloakTokenNeeded } from 'config/env'

const AgentDetails = () => {

    const [{ AgentFormData, SparkData }] = useGlobalState('MotorRiskReducer');

    const [agentData, setAgentData] = useState<createAgentProps>(AgentFormData);
    const [agentList, setAgentList] = useState<any[]>([]);
    const [validationData, setValidationData] = useState<agentValidationProps>(validateAgent);

    // useEffect(() => {
    //     let AuthorizationToken = getToken(SparkData);
    //     if (!AuthorizationToken) {
    //         fetchTokenData().then((response) => {
    //             vehicleOptions(response);
    //         });
    //     } else {
    //         vehicleOptions(AuthorizationToken);
    //         if (VehicleFormData.Make && VehicleFormData.Model) {
    //             onMakeChange(VehicleFormData.Make);
    //         }
    //     }

    // }, [SparkData])

    const fetchTokenData = async () => {

        console.log("this is isNeeded", IsKeyCloakTokenNeeded);
        if (IsKeyCloakTokenNeeded) {
            let AuthorizationToken = getToken(SparkData);
            if (AuthorizationToken) {
                return `Bearer ${AuthorizationToken}`
            } else {
                const url = `${endPoint.keycloakURL}`;
                const tokenData = await fetch(url, {
                    method: "GET",
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded",
                    }
                }).then((response) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                    .then(([statusCode, data]) => {
                        if (statusCode === 200) {
                            apiRequest(setSparkToken({
                                access_token: data.access_token,
                                expires_in: data.expires_in,
                                timestamp: new Date()
                            }));
                            return data.access_token;
                        } else {
                            alert(`Error ${statusCode} - ${data.error}`)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                return `Bearer ${tokenData}`;
            }
        } else {
            return 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZ3JvdXBzIjpbImFkbWluIiwiaGVhbHRoIl0sImlhdCI6MTUxNjIzOTAyMn0.CMEQlrAI3_MbWZJ1RF0qtvoZi6ZOHKduY3zyIXn8N-o'
        }
    }

    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const newAgentData = { ...agentData };
        newAgentData[name] = value;
        setAgentData(newAgentData);
        apiRequest(setAgentFormData(newAgentData)); // set agent form data in reducer
        if (name === 'AgentName' && value !== '') {
            agentSearch(value);
        }
    }

    const agentSearch = (agentSearchVal: string) => {

        const url = `${endPoint.getAgent}FirstName/` + agentSearchVal;
        fetchTokenData().then((Token => {
            // console.log("Token Token", Token);

            fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Token,
                }
            })
                .then((response) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    if (statusCode === 200) {
                        const AgentKeys = Object.keys(data.AgentDetails);
                        const AgentList = AgentKeys.map(key => {
                            return data.AgentDetails[key];
                        });
                        setAgentList(AgentList);

                    } else {
                        alert(`Error ${statusCode} - ${data.error}`)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }));
    }


    const selectAgent = (agent: selectAgentProps) => {
        let AgentData = agent;
        const oneAgentData = { ...agentData };
        oneAgentData.AgentName = AgentData.FirstName;
        oneAgentData.AgentPhone = AgentData.ContactDetails.Phone;
        oneAgentData.AgentEmail = AgentData.ContactDetails.Email;
        oneAgentData.AgentId = AgentData.AgentID;
        oneAgentData.AgentChannel = AgentData.ChannelName;
        setAgentData(oneAgentData);
        setAgentList([]);
        apiRequest(setAgentFormData(oneAgentData)); // set agent form data in reducer
    }

    const goToOwnerDetails = () => {
        let isValid = true;
        const validate = { ...validationData }
        //console.log("This is validate", validate);
        Object.keys(validate).forEach(fieldName => {
            if (validate[fieldName].isRequired && !agentData[fieldName]) {
                isValid = false;
                validate[fieldName].isValid = false;
            } else {
                validate[fieldName].isValid = true;
            }
        });
        setValidationData(validate);

        if (!isValid) {
            return false;
        }
        navigate('/OwnerDetails');

    }
    const goToProductSelection = () => {
        navigate('/Product');
    }

    return (
        <div id="agent-details-container">
            <div className='container'>
                <div className='d-flex justify-contents-start'>
                    <BackArrow className='center' onClick={goToProductSelection} />
                    <h3 className='m-4'>Agent Details</h3>
                </div>

                <div className='container'>
                    <div className='agent-container'>
                        <div className='color-style'>
                            &nbsp;
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container padding-style'>
                            <form>
                                <div className="form-group">
                                    <label>Intermediary Name
                                        {
                                            validationData.AgentName.isRequired && (
                                                <span style={{ color: 'red' }}>*</span>
                                            )
                                        }
                                    </label>
                                    <input type="text" className="form-control" id="AgentName" name="AgentName" placeholder="Enter Agent Name"
                                        value={agentData.AgentName} onChange={(e) => handleUpdate(e)} />
                                    {
                                        agentList.length > 0 && (
                                            <ul className="search-autocomplete" >
                                                {agentList.map((agent, index) => (
                                                    <li key={index} onClick={() => selectAgent(agent)}>
                                                        <p className='m-0'>{agent.FirstName} {agent.LastName}
                                                            <span className='float-right pb-1'>{agent.AgentID}</span>
                                                        </p>
                                                    </li>
                                                )
                                                )}
                                            </ul>
                                        )
                                    }
                                    <div className="invalid-feedback error-msg" style={{ display: (!validationData.AgentName.isValid ? 'block' : 'none') }}>
                                        This field is mandatory !
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label >Intermidiary Code
                                        {
                                            validationData.AgentId.isRequired && (
                                                <span style={{ color: 'red' }}>*</span>
                                            )
                                        }
                                    </label>
                                    <input type="input" className="form-control" id="AgentId" name='AgentId'
                                        value={agentData.AgentId} onChange={(e) => handleUpdate(e)} />
                                    <div className="invalid-feedback error-msg" style={{ display: (!validationData.AgentId.isValid ? 'block' : 'none') }}>
                                        This field is mandatory !
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label >Channel
                                        {
                                            validationData.AgentChannel.isRequired && (
                                                <span style={{ color: 'red' }}>*</span>
                                            )
                                        }
                                    </label>
                                    <input type="text" className="form-control" id="AgentChannel" name='AgentChannel'
                                        value={agentData.AgentChannel} onChange={(e) => handleUpdate(e)} />
                                    <div className="invalid-feedback error-msg" style={{ display: (!validationData.AgentChannel.isValid ? 'block' : 'none') }}>
                                        This field is mandatory !
                                    </div>
                                </div>
                                {/* <div className="form-group">
                                    <label >Phone Number</label>
                                    <input type="text" className="form-control" id="AgentPhone" name='AgentPhone' placeholder="Enter Phone Number"
                                        value={agentData.AgentPhone} onChange={(e) => handleUpdate(e)} />
                                </div> */}
                                {/* <div className="form-group">
                                    <label >Email</label>
                                    <input type="email" className="form-control" id="AgentEmail" name='AgentEmail' placeholder="Enter Email ID"
                                        value={agentData.AgentEmail} onChange={(e) => handleUpdate(e)} />
                                </div> */}
                                <div className='form-group mt-4'>
                                    <button type="button" className="btn next-style" onClick={goToOwnerDetails}>Next: Owner Details</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgentDetails;
