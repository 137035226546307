import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
import endPoint from 'config/urls';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import { accProps, subObj } from '../interfaces';
import { AllAPBreadcrumbItems, newObj } from '../AccountingPeriodController';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import './AllPeriod.styles.scss';


const AllPeriod = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [yearLength] = useState<number>(10)
    // const [showHide, setShowHide] = useState<boolean>(false);
    const [accPeriodData, setAccPeriodData] = useState<accProps[]>([]);
    // const [subObjArr, setSubObjArr] = useState<subObj>(newSubObj);
    const [newPeriodObj] = useState<accProps>(newObj);

    useEffect(() => {
        let promiseArr = [];
        let FullObj: accProps[] = [];
        const currentYear = (new Date()).getFullYear();
        for (let j = 0; j < yearLength; j++) {
            const FinancialYear = Number(currentYear) - j;
            const obj = JSON.parse(JSON.stringify(newPeriodObj));
            obj.FinYear = FinancialYear;

            const Pr = new Promise((resolve, reject) => {
                setIsLoading(true);
                const startUrl = `${endPoint.AccountingPeriod}/${FinancialYear}/-1`;
                fetch(startUrl, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'application/json'
                    }
                })
                    .then((response) => {
                        const statusCode = response.status;
                        const data = response.json();
                        return Promise.all([statusCode, data]);
                    })
                    .then(([statusCode, data]) => {
                        if (statusCode === 200) {
                            const FinanceArr = data;
                            const dataArr: subObj[] = [];
    
                            FinanceArr.map((item: any, index: number) => {
                                let periodData = JSON.parse(item.Data);
                                if (index === 0) {
                                    obj.FinPeriod = periodData.FinPeriod;
                                }
                                let dataObj = {
                                    Description: periodData.Description,
                                    Period: item.Period,
                                    FromDate: item.FromDate.split("T")[0],
                                    ToDate: item.ToDate.split("T")[0],
                                    Status: item.Status
                                }
                                dataArr.push(dataObj);
                                return true;
                            })
                            obj.Data = dataArr;
                            obj.ShowHideChild = false;
    
                            // FullObj.push(obj);
                            // setAccPeriodData(FullObj);
                            // console.log("This is full obj", FullObj);
                            setIsLoading(false);
                            resolve(obj);
                        } else {
                            setIsLoading(false);
                            reject(`Promise ${j} failed for year ${FinancialYear}`);
                            alert(`Error ${statusCode} - ${data.error}`)
                        }
                    })
            });
            promiseArr.push(Pr);
        }
        Promise.all(promiseArr).then((res) => {
            // @ts-ignore
            FullObj = res;
            setAccPeriodData(FullObj);
            return true;
        });
    }, [newPeriodObj, yearLength]);

    const goToCreatePeriod = () => {
        navigate('/CreateNewPeriod');
    };
    const goToEditPeriod = (Year:number) => {
        navigate(`/UpdateNewPeriod/${Year}`);
    };
    
    const showHideRow = (accPIndex: number) => {
        // setShowHide(!showHide);
        const newAccPeriodData = [...accPeriodData];
        newAccPeriodData[accPIndex].ShowHideChild = !newAccPeriodData[accPIndex].ShowHideChild;
        setAccPeriodData(newAccPeriodData);
    }

    return (
        <div id="all-period-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div
                    className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <Breadcrumbs paths={AllAPBreadcrumbItems} />
                    <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                        <div className="m-ap-btn plush" onClick={goToCreatePeriod}>Create New</div>
                    </div>
                </div>

                <div className="m-ap-table-box">
                    <div className="m-ap-table">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}></th>
                                    <th style={{ width: "30%" }}>Accounting Period</th>
                                    <th style={{ width: "40%" }}>Financial Period</th>
                                    <th style={{ width: "20%" }}></th>
                                </tr>
                            </thead>
                            {accPeriodData.length > 0 && (
                                <tbody>
                                    {
                                        accPeriodData.map((oneYear, index) => (
                                            <>
                                                <tr key={index} onClick={() => showHideRow(index)} style={{ cursor: 'pointer' }}>
                                                    <td>
                                                        <div className="box grey"></div>
                                                    </td>
                                                    <td>{oneYear.FinYear}</td>
                                                    <td>{oneYear.FinPeriod}</td>
                                                    <td style={{ textAlign: "right" }} onClick={()=> goToEditPeriod(oneYear.FinYear)}><u>Modify</u></td>
                                                </tr>
                                                {oneYear.ShowHideChild && (

                                                    <tr className='p-3'>
                                                        <td colSpan={4}>
                                                            <table className='table table-borderless'>
                                                                <thead>
                                                                    <th>Period</th>
                                                                    <th>Description</th>
                                                                    <th>From Date</th>
                                                                    <th>To Date</th>
                                                                    <th>Status</th>
                                                                </thead>
                                                                <tbody>
                                                                    {oneYear.Data.map((oneData, childIndex) => (
                                                                        <tr key={childIndex}>
                                                                            <td>{oneData.Period}</td>
                                                                            <td>{oneData.Description}</td>
                                                                            <td>{oneData.FromDate}</td>
                                                                            <td>{oneData.ToDate}</td>
                                                                            <td>{oneData.Status}</td>
                                                                        </tr>
                                                                    ))
                                                                    }


                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        ))
                                    }
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllPeriod;