import React, { useState } from "react";
import Moment from "moment";

import { IPolicy } from "store/actions/PolicyAction";
import { ITransaction } from "store/actions/TransactionAction";
import TransactionList from "Pages/Policy/components/TransactionTab/TransactionList";
import { TransactionService } from "services";

export interface ITransactionTab {
  data?: IPolicy;
  transactions?: Array<ITransaction>;
  fetching: boolean;
}

const Transactions: React.FC<ITransactionTab> = ({
  data,
  transactions,
  fetching,
}) => {
  const [transactionModal, setTransactionClaimModal] = useState(false);
  const [transaction, setTransaction] = useState<ITransaction>();

  const handleViewModal = () => {
    setTransactionClaimModal(false);
  };

  const viewTransaction = (trans?: ITransaction) => {
    setTransactionClaimModal(true);
    trans && setTransaction(trans);
  }

  const handleAdjust = async (formData: Record<string, string>) => {
    if (data && transaction) {
      await TransactionService.adjustTransaction(data?.ContID, {
        Args: [JSON.stringify(formData)],
        Tranid: transaction?.Tranid,
        Dte: Moment().format('YYYY-MM-DD'), 
        Status: transaction?.Status,
        Notes: transaction?.Notes,
      });
      await TransactionService.getTransaction(data?.ContID);
    }
    setTransactionClaimModal(false);
  }

  const handleUpdateStatus = async (status: string) => {
    if (data && transaction) {
      await TransactionService.adjustTransaction(data?.ContID, {
        Args: [],
        Tranid: transaction?.Tranid,
        Dte: Moment().format('YYYY-MM-DD'),
        Status: status,
        Notes: transaction?.Notes,
      });
      await TransactionService.getTransaction(data?.ContID);
    }
    setTransactionClaimModal(false);
  }

  const handleAddNote = async (note: string) => {
    if (data && transaction) {
      const dateValue =Moment().format('YYYY-MM-DD').toString()
      let noteValue: { [key: string]: string } = {}
      noteValue[dateValue] = note
      const val = transaction?.Notes === null ? [JSON.stringify(noteValue)] : [...transaction?.Notes, JSON.stringify(noteValue)]
      await TransactionService.adjustTransaction(data?.ContID, {
        Args: [],
        Tranid: transaction?.Tranid,
        Dte: Moment().format('YYYY-MM-DD'),
        Status: "AddNote",
        Notes: val,
      });
      await TransactionService.getTransaction(data?.ContID);
    }
    setTransactionClaimModal(false);
  }

  return (
    <TransactionList
        transaction={transaction}
        transactions={transactions}
        transactionModal={transactionModal}
        handleAdjust={handleAdjust}
        handleViewModal={handleViewModal}
        handleUpdateStatus={handleUpdateStatus}
        handleAddNote={handleAddNote}
        viewTransaction={viewTransaction}
        fetching={fetching}
      />
  );
};

export default Transactions;
