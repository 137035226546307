import React, { ReactNode, useEffect, useState } from 'react';
import { navigate } from 'raviger';
import './CheckoutDetails.styles.scss';


import { useGlobalState, apiRequest } from 'store';
import { setPaymentFormData, setPolicyIdData, clearMotorData, setSparkToken } from 'store/actions/MotorRiskAction';

import { ReactComponent as BackArrow } from 'assets/images/backArrow.svg';
import { eventProps, paymentFormProps, paymentFrequencyProps, uwFormProps } from '../interfaces';
import { getNewPolicyPayload, getToken, premiumCalcPayload, currencyFormat } from '../MotorController';
import { IsKeyCloakTokenNeeded } from 'config/env';
import Loader from '../../Components/Loader/Loader.componet';

import endPoint from 'config/urls';

const CheckoutDetails = () => {
    // const [{  }] = useGlobalState('MotorRiskReducer');
    const [{
        AgentFormData,
        OwnerFormData,
        VehicleFormData,
        DriverFormData,
        PlanFormData,
        BenefitFormData,
        UWFormData,
        PaymentFormData,
        SparkData
    }] = useGlobalState('MotorRiskReducer');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentData, setPaymentData] = useState<paymentFormProps>(PaymentFormData);
    const [premiumTot, setPremiumTot] = useState<uwFormProps>(UWFormData);
    const [paymentList, setPaymentList] = useState<paymentFrequencyProps[]>([]);

    useEffect(() => {
        setPremiumTot(UWFormData);
    }, [UWFormData])

    console.log("This is from Chekout", UWFormData)
    useEffect(() => {
        let AuthorizationToken = getToken(SparkData);
        if (!AuthorizationToken) {
            fetchTokenData().then((response) => {
                paymentOptions(response);
            });
        } else {
            paymentOptions(AuthorizationToken);
        }
    }, [SparkData]);


    // const fetchTokenData = async () => {
    //     const url = `${endPoint.keycloakURL}`;

    //     const tokenData = await fetch(url, {
    //         method: "GET",
    //         headers: {
    //             'Content-Type': "application/x-www-form-urlencoded",
    //         }
    //     }).then((response) => {
    //         const statusCode = response.status;
    //         const data = response.json();
    //         return Promise.all([statusCode, data]);
    //     })
    //         .then(([statusCode, data]) => {
    //             if (statusCode === 200) {
    //                 apiRequest(setSparkToken({
    //                     access_token: data.access_token,
    //                     expires_in: data.expires_in,
    //                     timestamp: new Date()
    //                 }));
    //                 return data.access_token;
    //             } else {
    //                 alert(`Error ${statusCode} - ${data.error}`)
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    //     return tokenData;
    // }
    const fetchTokenData = async () => {

        console.log("this is isNeeded", IsKeyCloakTokenNeeded);
        if (IsKeyCloakTokenNeeded) {
            let AuthorizationToken = getToken(SparkData);
            if (AuthorizationToken) {
                return `Bearer ${AuthorizationToken}`
            } else {
                const url = `${endPoint.keycloakURL}`;
                const tokenData = await fetch(url, {
                    method: "GET",
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded",
                    }
                }).then((response) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                    .then(([statusCode, data]) => {
                        if (statusCode === 200) {
                            apiRequest(setSparkToken({
                                access_token: data.access_token,
                                expires_in: data.expires_in,
                                timestamp: new Date()
                            }));
                            return data.access_token;
                        } else {
                            alert(`Error ${statusCode} - ${data.error}`)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                return `Bearer ${tokenData}`;
            }
        } else {
            return 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZ3JvdXBzIjpbImFkbWluIiwiaGVhbHRoIl0sImlhdCI6MTUxNjIzOTAyMn0.CMEQlrAI3_MbWZJ1RF0qtvoZi6ZOHKduY3zyIXn8N-o'
        }
    }
    const calcPremium = async (AuthorizationToken: string, payFreqVal: string) => {
        const url = `${endPoint.motorExcelEngine}`;
        let date = new Date();
        const TransDate = date.toISOString();
        let pfPayload = premiumCalcPayload({
            AgentFormData,
            OwnerFormData,
            VehicleFormData,
            DriverFormData,
            PlanFormData,
            BenefitFormData,
            UWFormData,
        });
        pfPayload.Inputs.PaymentFrequency = Number(payFreqVal);
        console.log("this is pf payload", pfPayload);
        return await fetch(url, {
            method: "POST",
            body: JSON.stringify(pfPayload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthorizationToken}`
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    const NewBusinessUI2Arr = data.data.Outputs.NewBusinessUI2;
                    const newPremiumData = { ...premiumTot }
                    newPremiumData.NewBusinessUI2 = NewBusinessUI2Arr;
                    setPremiumTot(newPremiumData);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        // console.log("this is name value", name, value);
        const newPaymentData = { ...paymentData };
        newPaymentData[name] = value;
        if (name === "PaymentFrequency") {
            let AuthorizationToken = getToken(SparkData);
            if (!AuthorizationToken) {
                fetchTokenData().then((response) => {
                    calcPremium(response, value);
                });
            } else {
                calcPremium(AuthorizationToken, value);
            }
            calcPremium(AuthorizationToken, value);
        }
        setPaymentData(newPaymentData);
        apiRequest(setPaymentFormData(newPaymentData)); // set payment form data in reducer
    }

    const goToUnderwriting = () => {
        navigate('/UnderwritingDetails');
    }
    const paymentOptions = async (AuthorizationToken: string) => {
        const url = `${endPoint.motorExcelEngine}`;
        let date = new Date();
        const TransDate = date.toISOString();
        const pfPayload = {
            "Inputs": {},
            "TransactionDate": TransDate,
            "EngineType": "Type3",
            "Solves": [],
            "RequestedServiceCategory": "Options"
        }
        return await fetch(url, {
            method: "POST",
            body: JSON.stringify(pfPayload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthorizationToken}`
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    const OptionsArr = data.data.Outputs;
                    setPaymentList(OptionsArr["Options.PaymentFrequency"]);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const submit = () => {
        setIsLoading(true);
        // console.log(AgentFormData, OwnerFormData, VehicleFormData, DriverFormData, PlanFormData, BenefitFormData, UWFormData);
        const motorPayload = getNewPolicyPayload({
            AgentFormData,
            OwnerFormData,
            VehicleFormData,
            DriverFormData,
            PlanFormData,
            BenefitFormData,
            UWFormData,
            PaymentFormData
        })
        const url = `${endPoint.createPolicy}`;
        
        fetchTokenData().then((Token => {
            // console.log("Token Token", Token);

            fetch(url, {
                method: "POST",
                body: JSON.stringify(motorPayload),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Token
                }
            })
                .then((response) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    console.log(data);
                    if (statusCode === 200) {
                        apiRequest(clearMotorData());
                        apiRequest(setPolicyIdData({ PolicyId: data.PolicyID }));
                        setIsLoading(false);
                        navigate('/Payment');
                    } else {
                        alert(`Error ${statusCode} - ${data.error}`);
                        setIsLoading(false);
                    }
                })
            // .catch((error) => {
            //     console.log(error);
            // });
        }));

        // return false;
    }



    console.log("This is premium total76598798", premiumTot);
    return (
        <div id="checkout-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <div className='container'>
                <div className='d-flex justify-contents-start'>
                    <BackArrow className='center' onClick={goToUnderwriting} />
                    <h3 className='m-4'>Checkout</h3>
                </div>
                <div className='container'>
                    <div className='checkout-container'>
                        <div className='color-style'>
                            &nbsp;
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container'>
                            <div className='m-5'>

                                <div className='payment-box p-2 pl-3 pr-3 mb-2'>
                                    {/* <div className='d-flex justify-content-between'>
                                        <div className=''>
                                            <input type='radio' className='mt-2'
                                                value={12}
                                                checked={paymentData.PaymentFrequency === "1"}
                                                onChange={(e) => handleUpdate(e)}
                                            />
                                            <label className='ml-3'>Yearly Payment</label>
                                        </div>
                                        <div className=''>
                                            <p className='mb-2'><b>$XXX.XX</b> yearly</p>
                                        </div>
                                    </div> */}
                                    <div className="form-group col-3">
                                        <label><b>Payment Frequency</b>
                                        </label>
                                        <select className="form-control" id="PaymentFrequency" name='PaymentFrequency'
                                            value={paymentData.PaymentFrequency} onChange={(e) => handleUpdate(e)}>
                                            {
                                                paymentList.map((option, index) => (
                                                    <option key={index} value={option.Option}>{option.Desc}</option>
                                                ))
                                            }

                                        </select>
                                    </div>
                                </div>

                                <div className='payment-box p-2 pl-3 pr-3 mb-2 mt-5'>
                                    {
                                        premiumTot.NewBusinessUI2.map((premiumVal, index) => (
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <div className=''>
                                                        <label className=''>Gross Premium</label>
                                                    </div>
                                                    <div className=''>
                                                        <label>
                                                            {
                                                                premiumVal.GrossPremium ? currencyFormat(premiumVal.GrossPremium) : ' - '
                                                            }
                                                            </label>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <div className=''>
                                                        <label className=''>Modal Premium</label>
                                                    </div>
                                                    <div className=''>
                                                        <label>
                                                            {
                                                            premiumVal.ModalPremium ? `(${currencyFormat(premiumVal.ModalPremium)})` : ' - '
                                                            }
                                                            </label>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <div className=''>
                                                        <label className=''>Total Commission</label>
                                                    </div>
                                                    <div className=''>
                                                        <label>
                                                            {
                                                                premiumVal.TotalCommission ? `(${currencyFormat(premiumVal.TotalCommission)})` : ' - '
                                                            }
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <div className=''>
                                                        <label className=''>Net Due</label>
                                                    </div>
                                                    <div className=''>
                                                        <label>
                                                            {
                                                                premiumVal.NetDue ? currencyFormat(premiumVal.NetDue) : ' - '
                                                            }
                                                            </label>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <div className=''>
                                                        <label className='total-font'><b>Total Due</b></label>
                                                    </div>
                                                    <div className='total-font'>
                                                        <label><b>
                                                            {
                                                                premiumVal.TotalDue ? currencyFormat(premiumVal.TotalDue) : ' - '
                                                            }
                                                        </b></label>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                </div>

                                <h6 className='mt-3 mb-3'><b>Payment Method</b></h6>

                                <div className='payment-box p-2 pl-3 pr-3 mb-2'>
                                    <div className='d-flex justify-content-between'>
                                        <div className=''>
                                            <input type='radio' className='mt-2'
                                                value="CreditCard"
                                                checked={paymentData.PaymentMethod === "CreditCard"}
                                                onChange={(e) => handleUpdate(e)}
                                            />
                                            <label className='ml-3'>Credit Card</label>
                                        </div>
                                    </div>
                                </div>

                                <div className='payment-box p-2 pl-3 pr-3 mb-2 mt-5'>
                                    <h6 className='mt-3'><b>Card details</b></h6>
                                    <form>
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="CardNumber" name="CardNumber" placeholder="e.g. 5342 5464 7549 4004"
                                                value={paymentData.CardNumber} onChange={(e) => handleUpdate(e)} />
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control col-md-3 float-left" id="ExpMonth" name='ExpMonth'
                                                value={paymentData.ExpMonth} onChange={(e) => handleUpdate(e)}>
                                                <option value="">Select Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>

                                            <select className="form-control col-md-3 float-left ml-2 mr-2" id="ExpYear" name='ExpYear'
                                                value={paymentData.ExpYear} onChange={(e) => handleUpdate(e)}>
                                                <option value="">Select Year</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                                <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                            </select>

                                        </div>
                                        <div className="form-group mt-2">
                                            <input type="text" className="form-control col-md-3 ml-2" placeholder="Security Code"
                                                id="cvv" name="cvv" value={paymentData.cvv} onChange={(e) => handleUpdate(e)} />
                                        </div>

                                    </form>

                                </div>

                                <div className='form-group mt-4'>
                                    <button type="button" className="btn next-style" onClick={submit}>Confirm Payment</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutDetails;