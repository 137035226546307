import React from 'react';
import { Layout } from 'antd';

import Header from 'layout/PrivateLayout/Header';
import { MainLayout } from 'layout/PrivateLayout/styles';

interface IProps {
  title: string;
  onBack?: () => void;
  hideExtra?: boolean;
  headerContent?: any;
}

const MainContent: React.FC<IProps> = props => {
  return (
    <MainLayout>
      <Header
        title={props.title}
        onBack={props.onBack}
        hideExtra={props.hideExtra}
      >
        {props.headerContent}
      </Header>
      <Layout.Content style={{ padding: 30 }}>{props.children}</Layout.Content>
    </MainLayout>
  );
};

export default MainContent;
