import React from 'react';
import TopupPay from '../Topup-Pay-Form/TopupPay';


const Pay = () => {
    const PayContent = {
        Module: 'Pay',
        PageTitle: 'Make a Payment',
        ButtonTitle: 'Make Payment',
        SuccessPopupTitle: 'Policy Payment',
        SuccessPopupMessage: 'Policy payment has been done successfully!'
    }

    return (
        <TopupPay content={PayContent} />
    )
}

export default Pay;