// Check if browser is using Web Storage
export const withLocalStorage = (typeof(Storage) !== 'undefined') ? true : false;

// Save browser local data
export const addLocalData = (field: string, value: string) => window.localStorage.setItem(field, value);

// Retrieve browser local data
export const getLocalData = (field: string) => window.localStorage.getItem(field);

// Remove browser local data
export const removeLocalData = (field: string) => window.localStorage.removeItem(field);