import React from 'react';
import MainContent from 'common/MainContent';

const Dashboard = () => {
    // @ts-ignore
    const User = window.loggedIn || 'Admin';
    return (
        <MainContent title="Dashboard" headerContent={`Welcome back, ${User}`}/>
    )
}

export default Dashboard;