import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
import './VehicleDetails.styles.scss';
import endPoint from 'config/urls';
import { useGlobalState, apiRequest } from 'store';
import { setVehicleFormData, setSparkToken } from 'store/actions/MotorRiskAction';

import { eventProps, createVehicleProps, vehicleValidationProps } from '../interfaces';
import { sampleVehicle, getToken, validateVehicle } from '../MotorController';
import { ReactComponent as BackArrow } from 'assets/images/backArrow.svg';

const VehicleDetails = () => {

    const [{ VehicleFormData, SparkData }] = useGlobalState('MotorRiskReducer');

    const [vehicleData, setVehicleData] = useState<createVehicleProps>(VehicleFormData);
    const [makeList, setMakeList] = useState<{ Option: string }[]>([]);
    const [modelList, setModelList] = useState<{ Option: string }[]>([]);
    const [validationData, setValidationData] = useState<vehicleValidationProps>(validateVehicle);


    useEffect(() => {
        let AuthorizationToken = getToken(SparkData);
        if (!AuthorizationToken) {
            fetchTokenData().then((response) => {
                vehicleOptions(response);
            });
        } else {
            vehicleOptions(AuthorizationToken);
            if (VehicleFormData.Make && VehicleFormData.Model) {
                onMakeChange(VehicleFormData.Make);
            }
        }
        setMaxDate();
        setMinDate();
    }, [SparkData])

    const setMaxDate = () => {
        const newDate = new Date();
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        console.log("this is year", year, month, day);
        return `${year}-${(month < 9) ? '0' + month : month}-${day}`;
    }
    const setMinDate = () => {
        const newDate = new Date();
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear() - 60;
        console.log("this is year", year, month, day);
        return `${year}-${(month < 9) ? '0' + month : month}-${day}`;
    }

    const validateDate = (value: string) => {
        let isValidDate = true;
        let inputDate = value;

        let minDate = setMinDate();
        let maxDate = setMaxDate();

        console.log("These are dates", inputDate, minDate, maxDate)
        const minDateJ = new Date(minDate);
        const maxDateJ = new Date(maxDate);
        const inputDateJ = new Date(inputDate);

        if (inputDateJ < minDateJ || inputDateJ > maxDateJ) {
            isValidDate = false;
        } else {
            isValidDate = true;
        }
        return isValidDate;
    }
    const fetchTokenData = async () => {
        const url = `${endPoint.keycloakURL}`;

        const tokenData = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': "application/x-www-form-urlencoded",
            }
        }).then((response) => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    apiRequest(setSparkToken({
                        access_token: data.access_token,
                        expires_in: data.expires_in,
                        timestamp: new Date()
                    }));
                    return data.access_token;
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return tokenData;
    }

    const vehicleOptions = async (AuthorizationToken: string) => {

        const url = `${endPoint.motorExcelEngine}`;

        let date = new Date();
        const TransDate = date.toISOString();

        const pfPayload = {
            "Inputs": {},
            "TransactionDate": TransDate,
            "EngineType": "Type3",
            "Solves": [],
            "RequestedServiceCategory": "Options"
        }
        return await fetch(url, {
            method: "POST",
            body: JSON.stringify(pfPayload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthorizationToken}`
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    const OptionsArr = data.data.Outputs;
                    // setVehicleOptionList(OptionsArr);
                    setMakeList(OptionsArr["Options.Make"]);
                    // setModelList(OptionsArr["Options.Model"]);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onMakeChange = async (value: string) => {
        const AuthorizationToken = getToken(SparkData);
        const url = `${endPoint.motorExcelEngine}`;
        const VehicleMake = value
        let date = new Date();
        const TransDate = date.toISOString();
        const pfPayload = {
            "Inputs": {
                "Options.Make": VehicleMake
            },
            "TransactionDate": TransDate,
            "EngineType": "Type3",
            "Solves": [],
            "RequestedServiceCategory": "Options"
        }
        return await fetch(url, {
            method: "POST",
            body: JSON.stringify(pfPayload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthorizationToken}`
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    console.log("This is my response", data.data.Outputs)
                    const OptionsArr = data.data.Outputs;
                    setModelList(OptionsArr["Options.Model"]);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        // console.log("this is name & value - ", name, value)
        const newVehicleData = { ...vehicleData };
        newVehicleData[name] = value;
        setVehicleData(newVehicleData);
        apiRequest(setVehicleFormData(newVehicleData)); // set vehicle form data in reducer
        if (name === 'Make' && value !== '') {
            onMakeChange(value)
        }
    }

    const goToDriverDetails = () => {
        let isValid = true;
        const validate = { ...validationData }
        //console.log("This is validate", validate);
        Object.keys(validate).forEach(fieldName => {
            if (validate[fieldName].isRequired && !vehicleData[fieldName]) {
                isValid = false;
                validate[fieldName].isValid = false;
            } else if ('checkFor' in validate[fieldName]) {
                if ('validDate' in validate[fieldName].checkFor && !validateDate(vehicleData[fieldName])) {
                    isValid = false;
                    validate[fieldName].isValid = false
                    validate[fieldName].errMsg = validate[fieldName].checkFor.validDateError;
                } else {
                    validate[fieldName].isValid = true;
                    validate[fieldName].errMsg = '';
                }
            } else {
                validate[fieldName].isValid = true;
            }
        });
        setValidationData(validate);

        if (!isValid) {
            return false;
        }
        navigate('/DriverDetails');
    }
    const goToOwnerDetails = () => {
        navigate('/OwnerDetails');
    }

    // console.log("this is validation data", validationData);

    return (
        <div id="vehicle-details-container">
            <div className='container'>
                <div className='d-flex justify-contents-start'>
                    <BackArrow className='center' onClick={goToOwnerDetails} />
                    <h3 className='m-4'>Tell us about your vehicle</h3>
                </div>
                <div className='container'>
                    <div className='vehicle-container'>
                        <div className='color-style'>
                            &nbsp;
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container padding-style'>
                            <form>
                                <div className='d-flex'>
                                    <div className='w-100 m-2'>
                                        <div className='row'>
                                            <div className="form-group col-3">
                                                <label>Make
                                                    {
                                                        validationData.Make.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <select className="form-control" id="Make" name='Make'
                                                    value={vehicleData.Make} onChange={(e) => handleUpdate(e)}>
                                                    <option value="">Select</option>
                                                    {
                                                        makeList.map((option, index) => (
                                                            <option key={index} value={option.Option}>{option.Option}</option>
                                                        ))
                                                    }

                                                </select>
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.Make.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Date of Manufacture
                                                    {
                                                        validationData.ManufactureYear.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='date' className="form-control" id="ManufactureYear" name='ManufactureYear'
                                                    value={vehicleData.ManufactureYear} onChange={(e) => handleUpdate(e)} min={setMinDate()} max={setMaxDate()} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.ManufactureYear.isValid ? 'block' : 'none') }}>
                                                {validationData.ManufactureYear.errMsg || 'This field is mandatory !'}
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Model
                                                    {
                                                        validationData.Model.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <select className="form-control" id="Model" name='Model'
                                                    value={vehicleData.Model} onChange={(e) => handleUpdate(e)}>
                                                    <option value="">Select</option>
                                                    {
                                                        modelList.map((option, index) => (
                                                            <option key={index} value={option.Option}>{option.Option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.Model.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Model Variant
                                                    {
                                                        validationData.ModelVarient.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <select className="form-control" id="ModelVarient" name='ModelVarient'
                                                    value={vehicleData.ModelVarient} onChange={(e) => handleUpdate(e)}>
                                                    <option value="">Select</option>

                                                </select>
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.ModelVarient.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="form-group col-3">
                                                <label>Vehicle Capacity
                                                    {
                                                        validationData.Capacity.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="Capacity" name='Capacity'
                                                    value={vehicleData.Capacity} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.Capacity.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Chassis No.
                                                    {
                                                        validationData.ChassisNo.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="ChassisNo" name='ChassisNo'
                                                    value={vehicleData.ChassisNo} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.ChassisNo.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Colour
                                                    {
                                                        validationData.Colour.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="Colour" name='Colour'
                                                    value={vehicleData.Colour} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.Colour.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Engine No.
                                                    {
                                                        validationData.EngineNo.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="EngineNo" name='EngineNo'
                                                    value={vehicleData.EngineNo} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.EngineNo.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="form-group col-3">
                                                <label>Vehicle Scheme
                                                    {
                                                        validationData.Scheme.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="Scheme" name='Scheme'
                                                    value={vehicleData.Scheme} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.Scheme.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Seating Capacity
                                                    {
                                                        validationData.SeatingCapacity.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='number' min='0' className="form-control" id="SeatingCapacity" name='SeatingCapacity'
                                                    value={vehicleData.SeatingCapacity} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.SeatingCapacity.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Usage Type
                                                    {
                                                        validationData.UsageType.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                {/* <select className="form-control" id="UsageType" name='UsageType'
                                                    value={vehicleData.UsageType} onChange={(e) => handleUpdate(e)}>
                                                    <option value="">Select</option>
                                                    <option value="Personal">Personal</option>

                                                </select> */}
                                                <input type='text' className="form-control" id="UsageType" name='UsageType'
                                                    value={vehicleData.UsageType} onChange={(e) => handleUpdate(e)} />

                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.UsageType.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Vehicle Type
                                                    {
                                                        validationData.VehicleType.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="VehicleType" name='VehicleType'
                                                    value={vehicleData.VehicleType} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.VehicleType.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="form-group col-3">
                                                <label>Engine Size
                                                    {
                                                        validationData.EngineSize.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="EngineSize" name='EngineSize'
                                                    value={vehicleData.EngineSize} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.EngineSize.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Engine Type
                                                    {
                                                        validationData.EngineType.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="EngineType" name='EngineType'
                                                    value={vehicleData.EngineType} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.EngineType.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Mileage
                                                    {
                                                        validationData.Mileage.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="Mileage" name='Mileage'
                                                    value={vehicleData.Mileage} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.Mileage.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Registration No.
                                                    {
                                                        validationData.RegistrationNo.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='text' className="form-control" id="RegistrationNo" name='RegistrationNo'
                                                    value={vehicleData.RegistrationNo} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.RegistrationNo.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group col-3">
                                                <label>NCD Years
                                                    {
                                                        validationData.NCDYears.isRequired && (
                                                            <span style={{ color: 'red' }}>*</span>
                                                        )
                                                    }
                                                </label>
                                                <input type='number' min='0' className="form-control" id="NCDYears" name='NCDYears'
                                                    value={vehicleData.NCDYears} onChange={(e) => handleUpdate(e)} />
                                                <div className="invalid-feedback error-msg" style={{ display: (!validationData.NCDYears.isValid ? 'block' : 'none') }}>
                                                    This field is mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group mt-4'>
                                            <button type="button" className="btn next-style" onClick={goToDriverDetails}>Next: Driver Details</button>
                                        </div>
                                    </div>
                                    {/* <div className='w-50 m-3'>
                                        <div className='upload-box'>
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">Upload a file</button>
                                                <input type="file" name="myfile" className='d-none' />
                                            </div>
                                            <label className="btn ">
                                                <input type="file" />
                                            </label>
                                        </div>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VehicleDetails;