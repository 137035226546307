import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
// import { Calendar } from 'antd';
import ReceiptHeader from '../ReceiptHeader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ViewReceipt.css';
// import EditableTable from './EditableTable';

const ViewReceipt = () => {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'THB',
    });
    interface receiptLineItemsProp {
        DepositeBy: string;
        DepositeByNo: string;
        BankCode: string;
        BankName: string;
        BankBranchCode: string;
        BankBranchName: string;
        DepositeRefNo: string;
        DepositeRefType: string;
        DepositeDate: string;
        DepositeAmount: number;
        BankFee: number;
        DepositeType: string;
        Status: string;
    }
    interface receiptProps {
        ReceiptNumber: string;
        PayeeType: string;
        PayeeCode: string;
        PayeeName: string;
        Remarks: string;
        ReceiptDate: string;
        PayeeAmount: string;
        ReceiptStatus: string;
        ReceiptDetail: receiptLineItemsProp[];
        Amount: number;
    }

    let tReceiptsList1: receiptProps = {
        ReceiptNumber: "",
        PayeeType: "",
        PayeeCode: "",
        PayeeName: "",
        Remarks: "",
        ReceiptDate: "",
        PayeeAmount: "",
        ReceiptStatus: "",
        ReceiptDetail: [],
        Amount: 0
    };

    const [tReceiptsList, setTReceiptsList] = useState<receiptProps>(tReceiptsList1);
    const [appliedPremiumList, setAppliedPremiumList] = useState<object[]>([]);
    const [currentTab, setCurrentTab] = useState<string>('Receipt');
    const [redirectFrom, setRedirectFrom] = useState<string>('');

    useEffect(() => {
        //@ts-ignore
        const receiptData = window.receiptData;
        setTReceiptsList(receiptData);
        const appliedPremiums = receiptData && receiptData.History.map(apData => apData.TranData);
        setAppliedPremiumList(appliedPremiums);
        //@ts-ignore
        setRedirectFrom(window.redirectFrom);
    }, []);

    const showTab = (tab: string) => {
        setCurrentTab(tab);
    }

    const backToView = () => {
        navigate('ModifyReceipt')
    }

    const goToEdit = () => {
        navigate('DepositeReceipt')
    }

    const calculateAmount = (data: any) => {
        const newData = { ...data };
        newData.Amount = 0;
        newData.ReceiptDetail.map((el: any) => {
            newData.Amount = newData.Amount + el.DepositeAmount;
        });
        return newData;
    }
    console.log('this is apdata', appliedPremiumList);
    return (
        <div id="view-receipt-container">
            <ReceiptHeader />
            <section>
                <div className="content-container">
                    <div className="content-box">
                        {/* <p className="title">Search Receipts</p> */}
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-12 receipt-details-p">
                                    <label className="receipt-title">Modify Receipt / </label>
                                    <label className="receipt-title receipt-number">{tReceiptsList && tReceiptsList.ReceiptNumber}</label>
                                </div>
                                <div className="col-md-12 receipt-details-p2">
                                    <label className={`receipt-tab ${(currentTab === 'Receipt') ? 'tab-active' : ''}`} onClick={() => showTab('Receipt')}>
                                        Receipt
                                    </label>
                                    <label className={`receipt-tab ${(currentTab === 'ReceiptDetails') ? 'tab-active' : ''}`} onClick={() => showTab('ReceiptDetails')}>
                                        Receipt Details
                                    </label>
                                    {
                                        redirectFrom === 'EnquireReceipt' && (
                                            <>
                                                <label className={`receipt-tab ${(currentTab === 'AppliedPremium') ? 'tab-active' : ''}`} onClick={() => showTab('AppliedPremium')}>
                                                    Applied Premium Details
                                                </label>
                                                <label className={`receipt-tab ${(currentTab === 'GLPosting') ? 'tab-active' : ''}`} onClick={() => showTab('GLPosting')}>
                                                    GL Posting details
                                                </label>
                                            </>
                                        )
                                    }
                                </div>
                                {
                                    (currentTab === 'Receipt') && tReceiptsList && (
                                        <div className="col-md-12 receipt-details-p3">
                                            <div className="col-md-12 receipt-details-line">
                                                <label className="col-md-2 title">Receipt Number</label>
                                                <label className="col-md-10 value">{tReceiptsList.ReceiptNumber || '-'}</label>
                                            </div>
                                            <div className="col-md-12 receipt-details-line even">
                                                <label className="col-md-2 title">Payee Type</label>
                                                <label className="col-md-10 value">{tReceiptsList.PayeeType || '-'}</label>
                                            </div>
                                            <div className="col-md-12 receipt-details-line">
                                                <label className="col-md-2 title">Payee Code</label>
                                                <label className="col-md-10 value">{tReceiptsList.PayeeCode || '-'}</label>
                                            </div>
                                            <div className="col-md-12 receipt-details-line even">
                                                <label className="col-md-2 title">Payee Name</label>
                                                <label className="col-md-10 value">{tReceiptsList.PayeeName || '-'}</label>
                                            </div>
                                            <div className="col-md-12 receipt-details-line">
                                                <label className="col-md-2 title">Remark</label>
                                                <label className="col-md-10 value">{tReceiptsList.Remarks || '-'}</label>
                                            </div>
                                            <div className="col-md-12 receipt-details-line even">
                                                <label className="col-md-2 title">Date</label>
                                                <label className="col-md-10 value">{tReceiptsList.ReceiptDate || '-'}</label>
                                            </div>
                                            <div className="col-md-12 receipt-details-line">
                                                <label className="col-md-2 title">Receipt Amount</label>
                                                <label className="col-md-10 value">{formatter.format(tReceiptsList.Amount) || '-'}</label>
                                            </div>
                                            <div className="col-md-12 receipt-details-line even">
                                                <label className="col-md-2 title">Receipt Status</label>
                                                <label className="col-md-10 value">{tReceiptsList.ReceiptStatus || '-'}</label>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    (currentTab === 'ReceiptDetails') && tReceiptsList && (
                                        <div className="col-md-12 mb-2 middle-table">
                                            <table className="table table-striped receipt-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Deposit By</th>
                                                        <th scope="col">Deposit By #</th>
                                                        <th scope="col">Bank Code</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Branch Code</th>
                                                        <th scope="col">Branch Name</th>
                                                        <th scope="col">Ref #</th>
                                                        <th scope="col">Ref Type</th>
                                                        <th scope="col">Deposit Date</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Bank Fee</th>
                                                        <th scope="col">Deposit Type</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tReceiptsList.ReceiptDetail.map((receipt, index) => (
                                                            <tr key={index}>
                                                                <td>{receipt.DepositeBy || '-'}</td>
                                                                <td>{receipt.DepositeByNo || '-'}</td>
                                                                <td>{receipt.BankCode || '-'}</td>
                                                                <td>{receipt.BankName || '-'}</td>
                                                                <td>{receipt.BankBranchCode || '-'}</td>
                                                                <td>{receipt.BankBranchName || '-'}</td>
                                                                <td>{receipt.DepositeRefNo || '-'}</td>
                                                                <td>{receipt.DepositeRefType || '-'}</td>
                                                                <td>{receipt.DepositeDate || '-'}</td>
                                                                <td>{receipt.DepositeAmount || '-'}</td>
                                                                <td>{receipt.BankFee || '-'}</td>
                                                                <td>{receipt.DepositeType || '-'}</td>
                                                                <td>{receipt.Status || '-'}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }

                                {
                                    (currentTab === 'AppliedPremium') && tReceiptsList && (
                                        <div className="col-md-12 mb-2 middle-table">
                                            <table className="table table-striped receipt-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Policy Number</th>
                                                        <th scope="col">Insured Name</th>
                                                        <th scope="col">Payee Code</th>
                                                        <th scope="col">Net Premium</th>
                                                        <th scope="col">Stamp Duty</th>
                                                        <th scope="col">Tax</th>
                                                        <th scope="col">Outstanding</th>
                                                        <th scope="col">Total Premium</th>
                                                        <th scope="col">Sattled Amount</th>
                                                        <th scope="col">Discount</th>
                                                        <th scope="col">Comm. Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        appliedPremiumList.map((receipt, index) => (
                                                            <tr key={index}>
                                                                <td>{receipt.ReceiptDetail[0].DepositeRefNo || '-'}</td>
                                                                <td>{'-'}</td>
                                                                <td>{receipt.PayeeCode || '-'}</td>
                                                                <td>{'-Net Prem-'}</td>
                                                                <td>{'-Stamp D-'}</td>
                                                                <td>{'-Tax-'}</td>
                                                                <td>{'-Outstanding-'}</td>
                                                                <td>{'-Total P-'}</td>
                                                                <td>{'-Sattled A-'}</td>
                                                                <td>{'-'}</td>
                                                                <td>{'-'}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                                {
                                    (currentTab === 'GLPosting') && tReceiptsList && (
                                        <div className="col-md-12 mb-2 middle-table">
                                            <table className="table table-striped receipt-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Ref #</th>
                                                        <th scope="col">Transaction Date</th>
                                                        <th scope="col">Account</th>
                                                        <th scope="col">Account Dec.</th>
                                                        <th scope="col">Sub Account #</th>
                                                        <th scope="col">Sub Account Dec.</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Dr/Cr</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tReceiptsList.ReceiptDetail.map((receipt, index) => (
                                                                receipt.GLtran.map((gl, index) => (
                                                                    <tr key={index}>
                                                                        <td>{receipt.DepositeRefNo || '-'}</td>
                                                                        <td>{receipt.DepositeDate || '-'}</td>
                                                                        <td>{'-'}</td>
                                                                        <td>{'-'}</td>
                                                                        <td>{'-'}</td>
                                                                        <td>{'-'}</td>
                                                                        <td>{gl.DbAmount || '-'}</td>
                                                                        <td>
                                                                        {
                                                                            (gl.DbAmount > 0) && "Dr"
                                                                        }
                                                                        {
                                                                            (gl.CbAmount > 0) && "Cr"
                                                                        }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    {
                        redirectFrom !== 'EnquireReceipt' && (
                            <button id="continue" onClick={goToEdit}>Modify Receipt</button>
                        )
                    }
                    {
                        redirectFrom === 'EnquireReceipt' && (
                            <button id="continue" onClick={() => window.print()}>Print</button>
                        )
                    }
                    <button id="back-button" className="btn btn-outline-dark" onClick={backToView}>Back</button>
                </div>
            </section>
        </div>
    )
}

export default ViewReceipt;
