import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
import './OwnerDetails.styles.scss';
import endPoint from 'config/urls';

import { useGlobalState, apiRequest } from 'store';
import { ReactComponent as BackArrow } from 'assets/images/backArrow.svg';
import { setOwnerFormData, setSparkToken } from 'store/actions/MotorRiskAction';

import { eventProps, createOwnerProps, ownerValidationProps, selectClientProps } from '../interfaces';
import { getToken, validateOwner, sampleOwner } from '../MotorController';
import { IsKeyCloakTokenNeeded } from 'config/env';
import Loader from '../../Components/Loader/Loader.componet';

import InputMask from "react-input-mask";

const OwnerDetails = () => {

    const [{ OwnerFormData, SparkData }] = useGlobalState('MotorRiskReducer');
    // console.log("this is from reducer ", OwnerFormData);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [ownerData, setOwnerData] = useState<createOwnerProps>(OwnerFormData);
    const [clientList, setClientList] = useState<any[]>([]);
    const [validationData, setValidationData] = useState<ownerValidationProps>(validateOwner);
    const [validationError, setValidationError] = useState<boolean>(false);
    const [showCorporate, setShowCorporate] = useState<boolean>(false);
    // const [clientTypeVal, setClientTypeVal] = useState<any>("Individual");
    const [clientNameVal, setClientNameVal] = useState<any>("Individual");


    useEffect(() => {
        setMaxDate();
        setMinDate();
    }, []);
    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        // console.log("this is name & value - ", name, value)
        const newOwnerData = { ...ownerData };
        newOwnerData[name] = value;

        setOwnerData(newOwnerData);
        apiRequest(setOwnerFormData(newOwnerData)); // set owner form data in reducer

        if (name === 'OwnerInput' && value !== '') {
            if (ownerData.OwnerSearchBy !== '') {
                clientSearch(ownerData.OwnerSearchBy, value);

            }
        }
        if (name === "OwnerInput" && value === "") {
            setOwnerData(sampleOwner);
            sampleOwner.OwnerSearchBy = ownerData.OwnerSearchBy;
            apiRequest(setOwnerFormData(sampleOwner)); // set owner form data in reducer
        }
        if (name === "OwnerType") {
            showHideCorporate(value);
        }
        if (name === "OwnerDOB") {
            validateDate(value);
        }
    }


    const setMaxDate = () => {
        const newDate = new Date();
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear() + 30;
        return `${year}-${(month < 9) ? '0' + month : month}-${day}`;
    }
    const setMinDate = () => {
        const newDate = new Date();
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear() - 60;
        return `${year}-${(month < 9) ? '0' + month : month}-${day}`;
    }

    const fetchTokenData = async () => {

        console.log("this is isNeeded", IsKeyCloakTokenNeeded);
        if (IsKeyCloakTokenNeeded) {
            let AuthorizationToken = getToken(SparkData);
            if (AuthorizationToken) {
                return `Bearer ${AuthorizationToken}`
            } else {
                const url = `${endPoint.keycloakURL}`;
                const tokenData = await fetch(url, {
                    method: "GET",
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded",
                    }
                }).then((response) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                    .then(([statusCode, data]) => {
                        if (statusCode === 200) {
                            apiRequest(setSparkToken({
                                access_token: data.access_token,
                                expires_in: data.expires_in,
                                timestamp: new Date()
                            }));
                            return data.access_token;
                        } else {
                            alert(`Error ${statusCode} - ${data.error}`)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                return `Bearer ${tokenData}`;
            }
        } else {
            return 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZ3JvdXBzIjpbImFkbWluIiwiaGVhbHRoIl0sImlhdCI6MTUxNjIzOTAyMn0.CMEQlrAI3_MbWZJ1RF0qtvoZi6ZOHKduY3zyIXn8N-o'
        }
    }
    const clientSearch = (clientSearchBy: string, clientSearchVal: string) => {
        let searchVal = clientSearchVal;
        if(clientSearchBy == "Name_GivenName"){
            if(searchVal.includes(" ")){
                searchVal = searchVal.replace(" ", "_")
            }else{
                searchVal = `${searchVal}_${searchVal}`;
              console.log("This is search value==",searchVal);
            }
          }
        // const url = `https://client.demo.sonic.dev.coherent.global/v1/client/search/${clientSearchBy}/${searchVal}?operation=or`;
        const url = `${endPoint.getClient}${clientSearchBy}/${searchVal}?operation=or`;
        console.log("this is client Url", url);
        setIsLoading(true);
        fetchTokenData().then((Token => {
            console.log("Token Token", Token);

            fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Token,
                }
            })
                .then((response) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    if (statusCode === 200) {
                        const ClientKeys = Object.keys(data.data);

                        const ClientList = ClientKeys.map(key => {
                            return data.data[key];
                        });
                        setClientList(ClientList);
                        console.log("this is Length",ClientList);

                    } else {
                        alert(`Error ${statusCode} - ${data.error}`)
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                });
        }));

    }

    const selectClient = (client: selectClientProps) => {
        let ClientData = client;
        const oneClientData = { ...ownerData };
        oneClientData.OwnerTitle = ClientData.Title;
        oneClientData.OwnerName = ClientData.Name;
        oneClientData.CorporateName = ClientData.CorporateName;
        oneClientData.OwnerSurname = ClientData.GivenName;
        oneClientData.OwnerDOB = ClientData.Dob.substring(0, 10);
        oneClientData.OwnerGender = ClientData.Gender;
        oneClientData.OwnerOccupation = ClientData.Occupation;
        oneClientData.OwnerType = ClientData.ClientType;
        if (ClientData.ClientType === "Corporate") {
            setShowCorporate(true);
            oneClientData.OwnerInput = ClientData.CorporateName;
        } else {
            setShowCorporate(false);
            oneClientData.OwnerInput = ClientData.Name + " " + ClientData.GivenName;
        }
        oneClientData.OwnerClientID = ClientData.ClientID;
        oneClientData.OwnerIDType = ClientData.ID[0].IDType;
        oneClientData.OwnerID = ClientData.ID[0].IDNumber;
        oneClientData.Nationality = ClientData.Nationality;
        oneClientData.ResidentialStatus = ClientData.ResidentialStatus || '';
        oneClientData.OwnerPhone = ClientData.ContactList[0].Phone;
        oneClientData.OwnerEmail = ClientData.ContactList[0].Email;
        oneClientData.OwnerFlat = ClientData.ContactList[0].Add.Add1;
        oneClientData.OwnerFloor = ClientData.ContactList[0].Add.Add2;
        oneClientData.OwnerBlock = ClientData.ContactList[0].Add.Add3;
        oneClientData.OwnerBuilding = ClientData.ContactList[0].Add.Add4;
        // oneClientData.OwnerStreet = ClientData.ContactList[0].Add.Add5;
        oneClientData.OwnerDistrict = ClientData.ContactList[0].Add.City;
        oneClientData.OwnerArea = ClientData.ContactList[0].Add.Pcode;
        // setClientTypeVal(oneClientData.OwnerType);
        setOwnerData(oneClientData);
        setClientList([]);
        apiRequest(setOwnerFormData(oneClientData)); // set agent form data in reducer
    }



    const goToVehicleDetails = () => {
        let isValid = true;
        const validate = { ...validationData }
        console.log("This is validate", validate);
        Object.keys(validate).forEach(fieldName => {
            if (validate[fieldName].isRequired && !ownerData[fieldName]) {
                isValid = false;
                validate[fieldName].isValid = false
                validate[fieldName].errMsg = '';
            } else if ('checkFor' in validate[fieldName]) {
                if ('emailFormat' in validate[fieldName].checkFor && !validate[fieldName].checkFor.emailFormat.test(ownerData[fieldName])) {
                    isValid = false;
                    validate[fieldName].isValid = false
                    validate[fieldName].errMsg = validate[fieldName].checkFor.emailErrMsg;
                } else if ('ageLimit' in validate[fieldName].checkFor && !validateDate(ownerData[fieldName])) {
                    isValid = false;
                    validate[fieldName].isValid = false
                    validate[fieldName].errMsg = validate[fieldName].ageErrorMsg;
                } else {
                    validate[fieldName].isValid = true;
                    validate[fieldName].errMsg = '';
                }
            } else {
                validate[fieldName].isValid = true;
            }

        });
        setValidationData(validate);

        if (!isValid) {
            return false;
        }

        navigate('/VehicleDetails');
    }

    const showHideCorporate = (value: string) => {
        const tempValidationData = { ...validationData };
        if (value === "Corporate") {
            setShowCorporate(true);
            tempValidationData.OwnerTitle.isRequired = false;
            tempValidationData.OwnerName.isRequired = false;
            tempValidationData.OwnerSurname.isRequired = false;
            tempValidationData.OwnerDOB.isRequired = false;
            tempValidationData.OwnerGender.isRequired = false;
            tempValidationData.CorporateName.isRequired = true;
        } else {
            setShowCorporate(false);
            tempValidationData.OwnerTitle.isRequired = true;
            tempValidationData.OwnerName.isRequired = true;
            tempValidationData.OwnerSurname.isRequired = true;
            tempValidationData.OwnerDOB.isRequired = true;
            tempValidationData.OwnerGender.isRequired = true;
            tempValidationData.CorporateName.isRequired = false;
        }

    }
    const validateDate = (value: string) => {
        let isValidDate = true;
        let inputDate = value;

        let minDate = setMinDate();
        let maxDate = setMaxDate();

        console.log("These are dates", inputDate, minDate, maxDate)
        const minDateJ = new Date(minDate);
        const maxDateJ = new Date(maxDate);
        const inputDateJ = new Date(inputDate);

        if (inputDateJ < minDateJ || inputDateJ > maxDateJ) {
            isValidDate = false;
        } else {
            isValidDate = true;
        }
        return isValidDate;
    }
    const goToAgentDetails = () => {
        navigate('/AgentDetails');
    }
    // console.log("this is client type", clientTypeVal);
    return (
        <div id="owner-details-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <div className='container'>
                <div className='d-flex justify-contents-start'>
                    <BackArrow className='center' onClick={goToAgentDetails} />
                    <h3 className='m-4'>Policy Owner Details</h3>
                </div>
                <div className='container'>
                    <div className='owner-container'>
                        <div className='color-style'>
                            &nbsp;
                        </div>
                        <hr className='mr-1 mt-0' />
                        <div className='container padding-style'>
                            <form>
                                <div className='row'>
                                    <div className="form-group col-3">
                                        <label>Search By </label>
                                        <select className="form-control" id="OwnerSearchBy" name='OwnerSearchBy'
                                            value={ownerData.OwnerSearchBy} onChange={(e) => handleUpdate(e)}>
                                            <option value="">Select</option>
                                            <option value="Name_GivenName">Name</option>
                                            <option value="idnumber">ID Number</option>
                                            <option value="Email">Email ID</option>
                                            <option value="phone">Contact Number</option>
                                            <option value="CorporateName">Company name</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-4">
                                        <label>&nbsp;</label>
                                        <input type="text" className="form-control" id="OwnerInput" name='OwnerInput' placeholder="Enter Owner Name"
                                            value={ownerData.OwnerInput} onChange={(e) => handleUpdate(e)} />

                                        {
                                            clientList.length > 0 && (
                                                <ul className="search-autocomplete" >
                                                    {clientList.map((client, index) => (
                                                        <li key={index} onClick={() => selectClient(client)}>
                                                            <p className='m-0'>
                                                                {
                                                                    client.ClientType === 'Individual'? `${client.Name} ${client.GivenName}`:`${client.CorporateName}`
                                                                }
                                                                <span className='float-right pb-1'>{client.ClientID}</span>
                                                            </p>
                                                        </li>
                                                    )
                                                    )}
                                                </ul>
                                            )
                                        }
                                    </div>
                                    <div className="form-group col-3">
                                        <label>Client ID</label>
                                        <input type="text" className="form-control" id="OwnerClientID" name='OwnerClientID' placeholder="-"
                                            value={ownerData.OwnerClientID} onChange={(e) => handleUpdate(e)} readOnly />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='separator'>Or Fill Below Details Manually</div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div>
                                        <input type='radio' className='mt-2'
                                            value="Individual"
                                            checked={ownerData.OwnerType === "Individual"}
                                            onChange={(e) => handleUpdate(e)}
                                            name="OwnerType"
                                        />
                                        <label className='ml-3'>Individual</label>
                                    </div>
                                    <div className='ml-4'>
                                        <input type='radio' className='mt-2'
                                            value="Corporate"
                                            checked={ownerData.OwnerType === "Corporate"}
                                            onChange={(e) => handleUpdate(e)}
                                            name="OwnerType"
                                        />
                                        <label className='ml-3'>Corporate</label>
                                    </div>

                                </div>
                                {!showCorporate ? (
                                    <div className='row'>

                                        <div className="form-group col-2">
                                            <label>Title
                                                {
                                                    validationData.OwnerTitle.isRequired && (
                                                        <span style={{ color: 'red' }}>*</span>
                                                    )
                                                }
                                            </label>
                                            {/* <input type="text" className="form-control" id="OwnerTitle" name='OwnerTitle' placeholder="Enter Owner Name"
        value={ownerData.OwnerTitle} onChange={(e) => handleUpdate(e)} /> */}
                                            <select className="form-control" id="OwnerTitle" name='OwnerTitle'
                                                value={ownerData.OwnerTitle} onChange={(e) => handleUpdate(e)}>
                                                <option value="">Select</option>
                                                <option value="Mr.">Mr.</option>
                                                <option value="Ms.">Ms.</option>
                                                <option value="Mrs.">Mrs.</option>
                                            </select>
                                            <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerTitle.isValid ? 'block' : 'none') }}>
                                                This field is mandatory !
                                            </div>
                                        </div>
                                        <div className="form-group col-4">
                                            <label>Name
                                                {
                                                    validationData.OwnerName.isRequired && (
                                                        <span style={{ color: 'red' }}>*</span>
                                                    )
                                                }
                                            </label>
                                            <input type="text" className="form-control" id="OwnerName" name='OwnerName' placeholder="Enter Owner Name"
                                                value={ownerData.OwnerName} onChange={(e) => handleUpdate(e)} />

                                            <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerName.isValid ? 'block' : 'none') }}>
                                                This field is mandatory !
                                            </div>
                                        </div>
                                        <div className="form-group col-3">
                                            <label>Surname
                                                {
                                                    validationData.OwnerSurname.isRequired && (
                                                        <span style={{ color: 'red' }}>*</span>
                                                    )
                                                }
                                            </label>
                                            <input type="text" className="form-control" id="OwnerSurname" name='OwnerSurname' placeholder="Enter Owner Name"
                                                value={ownerData.OwnerSurname} onChange={(e) => handleUpdate(e)} />
                                            <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerSurname.isValid ? 'block' : 'none') }}>
                                                This field is mandatory !
                                            </div>
                                        </div>
                                        <div className="form-group col-3">
                                            <label >DOB
                                                {
                                                    validationData.OwnerDOB.isRequired && (
                                                        <span style={{ color: 'red' }}>*</span>
                                                    )
                                                }
                                            </label>
                                            <input type="date" className="form-control" id="OwnerDOB" name='OwnerDOB'
                                                value={ownerData.OwnerDOB} onChange={(e) => handleUpdate(e)} min={setMinDate()} max={setMaxDate()} />
                                            <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerDOB.isValid ? 'block' : 'none') }}>
                                                {validationData.OwnerDOB.errMsg || 'This field is mandatory !'}
                                            </div>
                                        </div>
                                        <div className="form-group col-3">
                                            <label >Gender
                                                {
                                                    validationData.OwnerGender.isRequired && (
                                                        <span style={{ color: 'red' }}>*</span>
                                                    )
                                                }
                                            </label>
                                            <select className="form-control" id="OwnerGender" name='OwnerGender'
                                                value={ownerData.OwnerGender} onChange={(e) => handleUpdate(e)}>
                                                <option value="">Select</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerGender.isValid ? 'block' : 'none') }}>
                                                This field is mandatory !
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className="form-group col-4">
                                            <label>Corporate Name
                                                {
                                                    validationData.CorporateName.isRequired && (
                                                        <span style={{ color: 'red' }}>*</span>
                                                    )
                                                }
                                            </label>
                                            <input type="text" className="form-control" id="CorporateName" name='CorporateName' placeholder="Enter Owner Name"
                                                value={ownerData.CorporateName} onChange={(e) => handleUpdate(e)} />

                                            <div className="invalid-feedback error-msg" style={{ display: (!validationData.CorporateName.isValid ? 'block' : 'none') }}>
                                                This field is mandatory !
                                            </div>
                                        </div>

                                    </div>
                                )}

                                <div className='row'>
                                    <div className="form-group col-3">
                                        <label >Email
                                            {
                                                validationData.OwnerEmail.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <input type="email" className="form-control" id="OwnerEmail" name='OwnerEmail' placeholder="Enter Email Id"
                                            value={ownerData.OwnerEmail} onChange={(e) => handleUpdate(e)} />
                                        {/* <InputMask
                                            className="form-control"
                                            id="OwnerEmail" name='OwnerEmail'
                                            placeholder="Enter Email Id"
                                            value={ownerData.OwnerEmail}
                                            onChange={(e) => handleUpdate(e)}
                                            onBlur={validateEmail(ownerData.OwnerEmail)}
                                            type="email"
                                            mask=""
                                        /> */}
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerEmail.isValid ? 'block' : 'none') }}>
                                            {validationData.OwnerEmail.errMsg || 'This field is mandatory !'}
                                        </div>
                                        {
                                            validationError && (
                                                <div className="invalid-feedback error-msg" style={{ display: 'block' }}>
                                                    Please enter correct email format.
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div className="form-group col-3">
                                        <label >Contact Number
                                            {
                                                validationData.OwnerPhone.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        {/* <input type="text" className="form-control" id="OwnerPhone" name='OwnerPhone' placeholder="Enter Phone Number"
                                            value={ownerData.OwnerPhone} onChange={(e) => handleUpdate(e)} /> */}
                                        <InputMask
                                            className="form-control"
                                            id="OwnerPhone" name='OwnerPhone'
                                            placeholder="Enter Phone Number"
                                            value={ownerData.OwnerPhone}
                                            onChange={(e) => handleUpdate(e)}
                                            mask="9999 9999"
                                        />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerPhone.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <label >Occupation
                                            {
                                                validationData.OwnerOccupation.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <input type="text" className="form-control" id="OwnerOccupation" name='OwnerOccupation' placeholder="e.g. Doctor"
                                            value={ownerData.OwnerOccupation} onChange={(e) => handleUpdate(e)} />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerOccupation.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <label >Nationality
                                            {
                                                validationData.Nationality.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <select className="form-control" id="Nationality" name='Nationality'
                                            value={ownerData.Nationality} onChange={(e) => handleUpdate(e)}>
                                            <option value="">Select</option>
                                            <option value="China">China</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.Nationality.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="form-group col-3">
                                        <label >ID Type
                                            {
                                                validationData.OwnerIDType.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <select className="form-control" id="OwnerIDType" name='OwnerIDType'
                                            value={ownerData.OwnerIDType} onChange={(e) => handleUpdate(e)}>
                                            <option value="">Select</option>
                                            <option value="National ID">National ID</option>
                                            <option value="Passport">Passport</option>
                                        </select>
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerIDType.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <label >ID Number
                                            {
                                                validationData.OwnerID.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        {/* <input type="text"  id="OwnerID" name='OwnerID' placeholder="Enter ID Number"
                                            value={ownerData.OwnerID} onChange={(e) => handleUpdate(e)} /> */}
                                        <InputMask
                                            className="form-control"
                                            id="OwnerID" name='OwnerID'
                                            value={ownerData.OwnerID}
                                            placeholder="Enter ID Number"
                                            onChange={(e) => handleUpdate(e)}
                                            mask="99999999999"
                                        />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerID.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="form-group col-2">
                                        <label >Flat No.
                                            {
                                                validationData.OwnerFlat.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <input type="text" className="form-control" id="OwnerFlat" name='OwnerFlat' placeholder="Enter Address"
                                            value={ownerData.OwnerFlat} onChange={(e) => handleUpdate(e)} />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerFlat.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                    <div className="form-group col-2">
                                        <label >Floor No.
                                            {
                                                validationData.OwnerFloor.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <input type="text" className="form-control" id="OwnerFloor" name='OwnerFloor' placeholder="Enter Address"
                                            value={ownerData.OwnerFloor} onChange={(e) => handleUpdate(e)} />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerFloor.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                    <div className="form-group col-2">
                                        <label >Block No.
                                            {
                                                validationData.OwnerBlock.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <input type="text" className="form-control" id="OwnerBlock" name='OwnerBlock' placeholder="Enter Address"
                                            value={ownerData.OwnerBlock} onChange={(e) => handleUpdate(e)} />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerBlock.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <label >Building
                                            {
                                                validationData.OwnerBuilding.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <input type="text" className="form-control" id="OwnerBuilding" name='OwnerBuilding' placeholder="Enter Address"
                                            value={ownerData.OwnerBuilding} onChange={(e) => handleUpdate(e)} />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerBuilding.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <label >Street
                                            {
                                                validationData.OwnerStreet.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <input type="text" className="form-control" id="OwnerStreet" name='OwnerStreet' placeholder="Enter Address"
                                            value={ownerData.OwnerStreet} onChange={(e) => handleUpdate(e)} />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerStreet.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="form-group col-3">
                                        <label >District
                                            {
                                                validationData.OwnerDistrict.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <input type="text" className="form-control" id="OwnerDistrict" name='OwnerDistrict' placeholder="Enter Address"
                                            value={ownerData.OwnerDistrict} onChange={(e) => handleUpdate(e)} />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerDistrict.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                    <div className="form-group col-3">
                                        <label >Pcode
                                            {
                                                validationData.OwnerArea.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        {/* <input type="text" className="form-control" id="OwnerArea" name='OwnerArea' placeholder="Enter Address"
                                            value={ownerData.OwnerArea} onChange={(e) => handleUpdate(e)} /> */}
                                        <InputMask
                                            className="form-control"
                                            id="OwnerArea" name='OwnerArea'
                                            placeholder="Enter Pcode"
                                            value={ownerData.OwnerArea}
                                            onChange={(e) => handleUpdate(e)}
                                            mask="999-999"
                                        />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.OwnerArea.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                    <div className="form-group col-4">
                                        <label>Residential Status
                                            {
                                                validationData.ResidentialStatus.isRequired && (
                                                    <span style={{ color: 'red' }}>*</span>
                                                )
                                            }
                                        </label>
                                        <input type='text' className="form-control" id="ResidentialStatus" name='ResidentialStatus'
                                            value={ownerData.ResidentialStatus} onChange={(e) => handleUpdate(e)} />
                                        <div className="invalid-feedback error-msg" style={{ display: (!validationData.ResidentialStatus.isValid ? 'block' : 'none') }}>
                                            This field is mandatory !
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group mt-4'>
                                    <button type="button" className="btn next-style" onClick={goToVehicleDetails}>Next: Vehicle Details</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default OwnerDetails;