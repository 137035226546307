import React, { useState } from 'react';
import {
    eventProps, topupFormDataProps
} from '../interfaces';
import { sampleTopupData, getTopupPayload, getPaymentPayload } from '../ProductsController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TopupPay.styles.scss';


const TopupPay = ({ content }) => {
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [paymentFormData, setPaymentFormData] = useState<topupFormDataProps>(sampleTopupData);

    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const newAccData = { ...paymentFormData };
        newAccData[name] = value;
        setPaymentFormData(newAccData);
    }

    const SubmitAccount = () => {
        const topupFormData = (content.Module === 'Topup') ? getTopupPayload(paymentFormData) : getPaymentPayload(paymentFormData);
        const url = (content.Module === 'Topup') ? 'https://newbusiness.demo.sonic.dev.coherent.global/v1/ul/topup' : 'https://policyservicing.demo.sonic.dev.coherent.global/v1/applypremium';

        fetch(url, {
            method: "POST",
            body: JSON.stringify(topupFormData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZ3JvdXBzIjpbImFkbWluIiwiaGVhbHRoIl0sImlhdCI6MTUxNjIzOTAyMn0.CMEQlrAI3_MbWZJ1RF0qtvoZi6ZOHKduY3zyIXn8N-o'
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                if (statusCode === 200) {
                    setShowSuccessModal(true);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const closeModal = () => {
        setShowSuccessModal(false);
    }

    return (
        <div id="create-new-account-container">
            <div className="m-ap-wrap">
                <div className="m-ap-title">{content.PageTitle}</div>
                <div className={`m-ap-accordion m-ap-m-b-15 m-ap-active`}>
                    <div className="m-ap-ac-content pt-4">
                        <div className="m-ap-form">
                            <div className="m-ap-row">
                                <div className="m-ap-col-3 m-ap-input-f mb-4 ">
                                    <label className="m-ap-label">Policy Number</label>
                                    <input type="text" placeholder="eg. ILP-22-000085"
                                        name="PolicyId" id="PolicyId" value={paymentFormData.PolicyId}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>
                                <div className="m-ap-col-3 m-ap-input-f mb-4 ">
                                    <label className="m-ap-label">Amount</label>
                                    <input type="text" placeholder="E"
                                        name="Amount" id="Amount" value={paymentFormData.Amount}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Card Number</label>
                                    <input type="text" placeholder="Card Number"
                                        name="CardNumber" id="CardNumber" value={paymentFormData.CardNumber}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">Expiry</label>
                                    <select className='col-3' name="ExpMonth" id="ExpMonth" value={paymentFormData.ExpMonth} onChange={(e) => handleUpdate(e)} >
                                        <option value="">Month</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>

                                    <select className='col-3 ml-1' name="ExpYear" id="ExpYear" value={paymentFormData.ExpYear} onChange={(e) => handleUpdate(e)} >
                                        <option value="">Year</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>
                                        <option value="2031">2031</option>
                                        <option value="2032">2032</option>
                                    </select>
                                </div>

                                <div className="m-ap-col-3 m-ap-input-f mb-4">
                                    <label className="m-ap-label">CVV</label>
                                    <input className='col-6' type="text" placeholder="CVV"
                                        name="Cvv" id="Cvv" value={paymentFormData.Cvv}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div className="m-ap-bottom">
                <button id="create-submit" onClick={SubmitAccount}>{content.ButtonTitle}</button>
                <button id="cancel">Cancel</button>
            </div>

            {
                showSuccessModal && (
                    <div className="modal1" id="myModal">
                        <div className="modal-content1">
                            <div className="col-md-12 mb-2 download-receipt-title1">
                                <span>
                                    {content.SuccessPopupTitle}
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    {content.SuccessPopupMessage}
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt-close" onClick={closeModal}>Close</button>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                )
            }
        </div >
    )
}

export default TopupPay;