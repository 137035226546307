import React from 'react';
import styled from 'styled-components';

import { Container, Login, IconStyle } from 'Pages/Public/style';
import { ReactComponent as Logo } from 'assets/images/logo.svg';

const Icon = styled(Logo)`
  ${IconStyle}
`;

const PublicLayout = props => (
  <Container>
    <Icon>
      <Logo />
    </Icon>
    <Login>{props.children}</Login>
  </Container>
);

export default PublicLayout;
