import React, { useState, useEffect } from "react";
import map from "lodash/map";

import { TableContainer } from "Pages/Policy/style";
import { IGLEntry } from "store/actions/TransactionAction";

interface IProps {
  fetching: boolean;
  postings: Record<string, IGLEntry>;
}

const TransactionPosting: React.FC<IProps> = (props) => {
  const [postings, setPostings] = useState<Array<IGLEntry>>([]);

  useEffect(() => {
    if (props.postings) {
      setPostings(map(props.postings, (posting) => posting));
    } else {
      setPostings([]);
    }
  }, [props.postings]);

  const columns = [
    {
      title: "Ref",
      dataIndex: "Ref",
      key: "Ref",
    },
    {
      title: "Db Account",
      dataIndex: "Dbacc",
      key: "Dbacc",
    },
    {
      title: "Db Amount",
      dataIndex: "Db",
      key: "Db",
      render: (tValue: number) => new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(tValue || 0)
    },
    {
      title: "Cr Account",
      dataIndex: "Cracc",
      key: "Cracc",
    },
    {
      title: "Cr Amount",
      dataIndex: "Cr",
      key: "Cr",
      render: (tValue: number) => new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(tValue || 0)
    },
  ];

  return (
    <TableContainer
      columns={columns}
      rowKey="Ref"
      pagination={false}
      loading={props.fetching}
      dataSource={postings}
    />
  );
};

export default TransactionPosting;
