import React, { useState, useEffect } from "react";
import map from "lodash/map";
import JSONPretty from 'react-json-pretty';

import { TableContainer, ModalContainer } from "Pages/Policy/style";
import { InputField } from "common/Field";
import { isNull } from "util";

interface IParameter {
  param: string;
  value: any;
}

interface IProps {
  fetching: boolean;
  func: string;
  parameters: Record<string, any>;
}

interface IModalJson {
  title: string;
  data: any;
}

const TransactionParameter: React.FC<IProps> = (props) => {
  const [parameters, setParameters] = useState<Array<IParameter>>([]);
  const [isVisible, setIsVisisble] = useState(false);
  const [modalData, setModalData] = useState<IModalJson>({
    title: '',
    data: {}
  });

  useEffect(() => {
    // const funcs = [
    //   "init",
    //   "AddRisk",
    //   "InitiateClaim",
    //   "ApplyPremium",
    //   "Premium Receipt",
    //   "Allocate Premium"
    // ];
    // && funcs.includes(props.func)
    if (props.parameters) {
      setParameters(
        map(props.parameters, (value, param) => ({
          param,
          value,
        }))
      );
    } else {
      setParameters([]);
    }
  }, [props.parameters, props.func]);

  const handleEditModal = (title: string, data: any) => {
    setIsVisisble(true)
    setModalData({ title, data });
  }

  const columns = [
    {
      title: "Parameter",
      dataIndex: "param",
      key: "param",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value: string, record: any) => {
        if (typeof value === 'string') {
          return <InputField name={record.param} label={record.param} value={value} />
        } else if ( isNull(value))  {
          return '';
        } else if (typeof value === 'object') {
          return <i className="zmdi zmdi-edit zmdi-hc-2x" onClick={() => handleEditModal(record.param, value)} />
        }
      },
    },
  ];

  return (
    <>
      <TableContainer
        columns={columns}
        rowKey="param"
        pagination={false}
        loading={props.fetching}
        dataSource={parameters}
      />
      <ModalContainer onCancel={() => setIsVisisble(false)}
      maskClosable={false}
      width="50%"
      title={modalData.title}
      visible={isVisible}
      footer={null}>
        <JSONPretty id="json-pretty" data={modalData.data}/>
      </ModalContainer>
    </>
  );
};

export default TransactionParameter;
