import { getPolicy } from "store/actions/PolicyAction";
import { apiRequest } from "store";

const getPolicyCall = async (policyId: string) => {
    await apiRequest(getPolicy(policyId));
}

const PolicyService = {
    getPolicy: getPolicyCall
}

export default PolicyService;