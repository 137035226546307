import { createPolicyProps, topupFormDataProps, fundDataProps, loanFormProps } from './interfaces';


export const sampleAccount: createPolicyProps = {
    InsuredName: "Mr. Arvind Kushwaha",
    InsuredIC: "BZXPK7973D",
    InsuredDob: "1988-05-17",
    Gender: "M",
    Phone: "7276900528",
    Email: "arvind.kushwaha@coherent.global",
    PlanType: "",
    PolicyTerm: "5",
    PaymentFrequency: "4",
    StartDate: "2022-04-15",
    Add1: "A-1408, Mount Unique Residences",
    Add2: "Sus Road",
    Add3: "Baner",
    City: "Pune",
    PostCode: "45646",
    Premium: "3574",
    Opt1: "15",
    Opt2: "35",
    Opt3: "30",
    Opt4: "20",
}


export const showDate = (date: string) => {
    if (date) {
        return date.substring(0, 10);
    }
    return date;
}

export const getNewPolicyPayload = (formData: createPolicyProps) => {
    const payload = {
        "Header": {
            "Product": "ILP:ILP",
            "Term": parseInt(formData.PolicyTerm),
            "Channel": "Direct",
            "Agent": "ARN-000001",
            "AgentName": "agent2 swan",
            "Email": "",
            "StartDate": "",
            "EndDate": "",
            "PaymentMethod": "StatementOfAccount",
            "PaymentFrequency": parseInt(formData.PaymentFrequency),
            "InstallmentPremium": Number(formData.Premium),
            "PaymentReference": "",
            "PreferredLanguage": "English",
            "PromoCode": "",
            "ReferenceCode": "",
            "EmailPreferance": "e-policy",
            "Plan": "Plan1",
            "Mandate": {
                "MandateRefNo": "000546",
                "MandateStatus": "Pending",
                "CreditCard": {
                    "BankRefNum": "6867",
                    "CardNumber": "6989",
                    "CardType": "Master",
                    "ExpiryMonth": "12",
                    "ExpityYear": "23"
                }
            },
            "Owner": {
                "Occupation": "software",
                "Nationality": "Thailand",
                "Race": "Thailand",
                "Data": null,
                "Title": "",
                "Name": "",
                "GivenName": "",
                "Gender": "",
                "Dob": "",
                "Smoker": "S",
                "ID": [
                    {
                        "IDType": "",
                        "IDNumber": "",
                        "IssueDate": "",
                        "ExpiryDate": "",
                        "IssuingAgency": ""
                    }
                ],
                "Contact": {
                    "Email": "",
                    "Phone": "",
                    "FbId": "",
                    "Typ": "",
                    "Add": {
                        "Add1": "",
                        "Add2": "",
                        "Add3": "",
                        "Add4": "",
                        "City": "",
                        "Country": "",
                        "Pcode": ""
                    }
                }
            },
            "Data": []
        },
        "Risks": []
    };

    payload.Header.Email = formData.Email;

    payload.Header.PaymentFrequency = parseInt(formData.PaymentFrequency);
    // payload.Header.Plan = formData.PlanType;
    payload.Header.Owner.Name = formData.InsuredName;
    // payload.Header.Owner.GivenName = formData.Email;
    payload.Header.Owner.Gender = formData.Gender;
    payload.Header.Owner.Dob = formData.InsuredDob;

    payload.Header.Owner.Contact.Email = formData.Email;
    payload.Header.Owner.Contact.Phone = formData.Phone;
    payload.Header.Owner.Contact.Add.Add1 = formData.Add1;
    payload.Header.Owner.Contact.Add.Add2 = formData.Add2;
    payload.Header.Owner.Contact.Add.Add3 = formData.Add3;
    payload.Header.Owner.Contact.Add.City = formData.City;
    payload.Header.Owner.Contact.Add.Pcode = formData.PostCode;

    payload.Header.Email = formData.Email;

    const RiskData = {
        "RiskType": "UL",
        "RiskId": "1",
        "Riskclass": "UL",
        "CoverType": "ILP:ILP",
        "Risk_Comm": "",
        "RiskBody": {
            "RiskType": "UL",
            "Loans": {},
            "RiskId": "1",
            "Riskclass": "UL",
            "Plan": "Plan1",
            "CoverType": "ILP:ILP",
            "Sum_Insured": 0,
            "TermUnits": "years",
            "Term": parseInt(formData.PolicyTerm),
            "Region": "Asia",
            "Channel": "PRU",
            "Payment_Frequency": formData.PaymentFrequency,
            "BasePremium": Number(formData.Premium),
            "EventDate": (new Date()).toISOString(),
            "Risk_Comm": "",
            "Risk_End": "",
            "FundPriceUrl": "https://newbusiness.demo.sonic.dev.coherent.global/v1/ul/fundpriceurl",
            "FundAllocation": [
                {
                    "Fund": "A",
                    "Pct": Number(formData.Opt1)
                },
                {
                    "Fund": "B",
                    "Pct": Number(formData.Opt2)
                },
                {
                    "Fund": "C",
                    "Pct": Number(formData.Opt3)
                },
                {
                    "Fund": "D",
                    "Pct": Number(formData.Opt4)
                }
            ],
            "InsuredParty": {
                "Age": 23,
                "MortalityClass": "N",
                "Occupation": "Job",
                "Title": "",
                "GivenName": "",
                "Kinship": "Self",
                "Name": formData.InsuredName,
                "Dob": formData.InsuredDob,
                "Gender": formData.Gender,
                "Id": {
                    "IDType": "National ID",
                    "IDNumber": "4356789707958892",
                    "IssueDate": "2020-01-20",
                    "ExpiryDate": "2025-02-08",
                    "IssuingAgency": "abcd"
                },
                "Contact": {
                    "Email": formData.Email,
                    "Phone": formData.Phone,
                    "FbId": "",
                    "Add": {
                        "Add1": formData.Add1,
                        "Add2": formData.Add2,
                        "Add3": formData.Add3,
                        "Add4": "",
                        "City": formData.City,
                        "Country": "",
                        "Pcode": formData.PostCode
                    }
                },
                "Documents": [],
                "Data": []
            },
            "Beneficiaries": [
                {
                    "PersonalDets": {
                        "SurName": "",
                        "Name": formData.InsuredName,
                        "Dob": formData.InsuredDob,
                        "MaritalStatus": "Single",
                        "Gender": formData.Gender,
                        "Id": "",
                        "LegalRep": {
                            "RepresentativeId": "",
                            "RepDets": {
                                "GivenName": "",
                                "Name": "",
                                "Dob": "",
                                "MaritalStatus": "",
                                "Gender": "",
                                "Id": ""
                            },
                            "Relationship": ""
                        },
                        "Nationality": "",
                        "Occupation": "",
                        "Contact": {
                            "Email": formData.Email,
                            "Phone": formData.Phone,
                            "FbId": "",
                            "Add": {
                                "Add1": formData.Add1,
                                "Add2": formData.Add2,
                                "Add3": formData.Add3,
                                "Add4": "",
                                "City": formData.City,
                                "Country": "",
                                "Pcode": formData.PostCode
                            }
                        }
                    },
                    "Payee": {
                        "AccountName": "",
                        "Bank": "",
                        "Branch": "",
                        "Account": "",
                        "IBAN": "",
                        "Address": {
                            "Add1": "",
                            "Add2": "",
                            "Add3": "",
                            "Add4": "",
                            "City": "",
                            "Country": "",
                            "Pcode": ""
                        }
                    }
                }
            ],
            "Data": []
        },
        "Data": []
    };

    const selectedDate = new Date(formData.StartDate);
    payload.Header.StartDate = `${selectedDate.toISOString()}`;

    RiskData.Risk_Comm = `${selectedDate.toISOString()}`;
    RiskData.RiskBody.Risk_Comm = `${selectedDate.toISOString()}`;

    selectedDate.setFullYear(selectedDate.getFullYear() + 1);
    payload.Header.EndDate = `${selectedDate.toISOString()}`;
    RiskData.RiskBody.Risk_End = `${selectedDate.toISOString()}`;

    payload.Risks.push(RiskData);

    return payload;
}

export const getStatementPayload = (policyNumber: string) => {
    return {
        "PolicyID": policyNumber,
        "RiskID": "1",
        "TranDate": (new Date()).toISOString()
    }
}

export const sampleTopupData: topupFormDataProps = {
    PolicyId: "",
    Amount: "1000",
    CardNumber: "3456 3456 5465 7687",
    ExpMonth: "05",
    ExpYear: "2022",
    Cvv: "345"
}

export const getWithdrawPayload = (formData: fundDataProps[], policyId: string) => {
    const withdrawalData = formData.map(withdraw => {
        return {
            Account: withdraw.Id,
            Amount: Number(withdraw.Withdraw)
        }
    })
    return {
        "PolicyID": policyId,
        "RiskID": "1",
        "TranDate": (new Date()).toISOString(),
        "Withdrawals": withdrawalData
    }
}

export const getTopupPayload = (formData: topupFormDataProps) => {
    return {
        "PolicyID": formData.PolicyId,
        "RiskID": "1",
        "TranDate": (new Date()).toISOString(),
        "Amount": Number(formData.Amount)
    }
}

export const getPaymentPayload = (formData: topupFormDataProps) => {
    return {
        "PolicyID": formData.PolicyId,
        "ReceiptDate": (new Date()).toISOString().split('T')[0],
        "Amount": Number(formData.Amount),
        "Reference": "BILL 1"
    }
}

export const getFundSwitchPayload = (formData: fundDataProps[], policyId: string) => {
    const allocationData = formData.map(alloc => {
        return {
            Fund: alloc.Id,
            Pct: Number(alloc.AllocationPct)
        }
    })
    return {
        "PolicyID": policyId,
        "RiskID": "1",
        "TranDate": (new Date()).toISOString(),
        "ChangeAllocFlag": "Y",
        "NewAlloc": allocationData
    }
}

export const sampleLoanFormData: loanFormProps = {
    PolicyId: "",
    Amount: "1000",
    Months: "12"
}

export const getLoanPayload = (formData: loanFormProps) => {
    return {
        "PolicyID": formData.PolicyId,
        "RiskID": "1",
        "TranDate": (new Date()).toISOString(),
        "LoanDets": {
            "Principal":  Number(formData.Amount),
            "RepaymentMonths": parseInt(formData.Months)
        }
    }
}
